import { Button } from 'antd'
import React, { useState } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import InformativeModal from '../informativePopup/InformativeModal'
import { useSelector } from 'react-redux'
const InitialInformativeButton = () => {
    const { dictionary } = useSelector((state: any) => state.ui);

    const [visible, setVisible] = useState(false);
    return (
        <>
            <Button
                type='link'
                icon={<QuestionCircleOutlined style={{ fontSize: '20px' }} />}
                onClick={() => setVisible(true)}
                style={{ color: '#414193' }}
            />
            <InformativeModal title={dictionary['new-job-modal-informative-section-initial-text-1']}
                description={dictionary['new-job-modal-informative-section-initial-description-1']} setVisible={setVisible} visible={visible} />
        </>
    )
}

export default InitialInformativeButton