import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../config";
import { CollectionsName } from "../../../models/collections";
import { ICandidate } from "../../../models/candidate";
import { GetCandidateDocumentResponse, UpdateCandidateDocumentResponse } from "./type";
import { EErrorMessage } from "../error-types";

// READ CANDIDATE DOCUMENT
export const getCandidateDocument = async (idCandidate: string): Promise<GetCandidateDocumentResponse> => {
    try {
        const candidateDocRef = doc(db, CollectionsName.CANDIDATES, idCandidate);
        const candidateDocSnap = await getDoc(candidateDocRef);

        if (candidateDocSnap.exists()) {
            return {
                success: true,
                data: candidateDocSnap.data() as ICandidate,
            };
        } else {
            return {
                success: false,
                error: EErrorMessage.NOT_DOCUMENT_DATA_FOUND,
            };
        }

    } catch (error) {
        return {
            success: false,
            error: error instanceof Error ? error.message : EErrorMessage.GET_DOCUMENT_ERROR,
        };
    }
};

// Update
export const updateCandidateDocument = async (idCandidate: string, values: Partial<ICandidate>): Promise<UpdateCandidateDocumentResponse> => {
    try {
        const candidateDocumentRef = doc(db, CollectionsName.CANDIDATES, idCandidate);

        await updateDoc(candidateDocumentRef, values);

        return {
            success: true,
            data: values
        }
    } catch (error) {
        return {
            success: false,
            error: error instanceof Error ? error.message : 'Unknouwn error ocurred.'
        }
    }
}