import React, { useEffect, useRef, useState } from 'react';
import { List, Button, Typography, Space, Row, Col, InputNumber, AutoComplete } from 'antd';
import { DeleteOutlined, PlusOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import { assets } from '../../../../../../assets/assets';
import { collectionGroup, getDocs, query } from 'firebase/firestore';
import { db } from '../../../../../../firebase/config';
import { useSelector } from 'react-redux';
import { getTranslation } from '../../../../../../hooks/hooks';
import './SkillList.css';
import './SkillAutocomplete.css';

const { Text } = Typography;

interface SkillItem {
    key: string;
    predeterminated: string;
    rating: number;
    nameSkill: string;
    index: number;
}

const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

interface Props {
    skill: SkillItem[];
    setSkill: (value: SkillItem[]) => void;
}

const SkillList: React.FC<Props> = ({ skill, setSkill }) => {
    const [newSkill, setNewSkill] = useState<string>('');
    const [newRating, setNewRating] = useState<number>(1);
    const [skillOptionList, setSkillOptionList] = useState<Array<{ value: string, label: string, predeterminated: string }>>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editingKey, setEditingKey] = useState<string | null>(null);
    const selectRef = useRef<any>(null);
    const { dictionary, language }: { dictionary: { [key: string]: string }, language: string } = useSelector((state: any) => state.ui);

    useEffect(() => {
        const skillWithKeys = skill.map(item => ({
            ...item,
            key: item.key || crypto.randomUUID(),
        }));
        setSkill(skillWithKeys);
    }, [setSkill, skill]);

    useEffect(() => {
        const getSkillOptions = async () => {
            try {
                const skillTitlesQuery = query(collectionGroup(db, "skillTitles"));
                const response = await getDocs(skillTitlesQuery);
                const skillList: any[] = [];

                response.docs.forEach((doc) => {
                    const data = doc.data();
                    if (data.nameSkill.length > 0 && data.verified) {
                        skillList.push(data.nameSkill);
                    }
                });

                const uniqueSkills = skillList.filter((item, index) => skillList.indexOf(item) === index);

                const skillListSelector = uniqueSkills.map(skillName => {
                    const translation = dictionary[skillName];
                    return {
                        value: translation !== undefined ? getTranslation(skillName, dictionary) : skillName,
                        label: translation !== undefined ? getTranslation(skillName, dictionary) : skillName,
                        predeterminated: skillName
                    };
                });

                setSkillOptionList(skillListSelector.sort((a, b) => a.value.localeCompare(b.value)));
            } catch (error) {
                console.error("Error al obtener las habilidades:", error);
            }
        };

        getSkillOptions();
    }, [dictionary, language]);

    const addSkill = () => {
        if (!newSkill.trim()) return;

        const selected = skillOptionList.find(s => s.value === newSkill);

        if (isEditing && editingKey) {
            const updatedSkills = skill.map(item => {
                if (item.key === editingKey) {
                    return {
                        ...item,
                        nameSkill: newSkill,
                        predeterminated: selected?.predeterminated || '',
                        rating: newRating,
                    };
                }
                return item;
            });

            setSkill(updatedSkills);
            setIsEditing(false);
            setEditingKey(null);
        } else {
            const newItem: SkillItem = {
                key: crypto.randomUUID(),
                predeterminated: selected?.predeterminated || '',
                rating: newRating,
                nameSkill: newSkill,
                index: skill.length + 1,
            };
            setSkill([...skill, newItem]);
        }

        setNewSkill('');
        setNewRating(1);
    };

    const deleteSkill = (key: string) => {
        setSkill(skill.filter(item => item.key !== key));

        if (editingKey === key) {
            cancelEdit();
        }
    };

    const editSkill = (item: SkillItem) => {
        setIsEditing(true);
        setEditingKey(item.key);
        setNewSkill(item.nameSkill);
        setNewRating(item.rating);
        if (selectRef.current) {
            selectRef.current.focus();
        }
    };

    const cancelEdit = () => {
        setIsEditing(false);
        setEditingKey(null);
        setNewSkill('');
        setNewRating(1);
    };

    const renderRating = (rating: number) => {
        const fullIcons = Array(rating).fill(<img src={assets.iconSkill} alt='iconSkill' style={{ width: 'auto', height: 20 }} />);
        const emptyIcons = Array(5 - rating).fill(<img src={assets.iconSkillNull} alt='iconSkillNull' style={{ width: 'auto', height: 20 }} />);
        return [...fullIcons, ...emptyIcons];
    };

    return (
        <div style={{ width: '100%' }}>
            <Row gutter={[16, 16]} style={{ marginBottom: '20px', alignItems: 'center' }} align="middle">
                <Col xs={24} sm={24} md={24}
                    lg={isEditing ? 15 : 17}
                    xl={isEditing ? 15 : 17}
                >
                    <AutoComplete
                        ref={selectRef}
                        className='autocomplete-job-skill-section label-skill'
                        placeholder={dictionary["creation-modal-page-three-placeholder-skill"]}
                        options={skillOptionList}
                        value={newSkill}
                        variant='borderless'
                        filterOption={true}
                        onChange={(value) => setNewSkill(value)}
                        onSelect={(value) => setNewSkill(value)}
                    />
                </Col>

                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={2}
                    xl={2}
                >
                    <InputNumber
                        className='input-skill-number-section'
                        variant='borderless'
                        min={1}
                        max={5}
                        value={newRating}
                        onChange={(value) => setNewRating(value || 1)}
                    />
                </Col>

                <Col xs={24} sm={24} md={24} lg={3} xl={4}>
                    <Button
                        icon={isEditing ? <EditOutlined /> : <PlusOutlined />}
                        className='button-purple-dark'
                        onClick={addSkill}
                        style={{ width: '100%' }}
                    >
                        {isEditing
                            ? capitalizeFirstLetter(dictionary['creation-modal-update-button']) || 'Guardar'
                            : dictionary['creation-modal-page-three-button-add-skill']}
                    </Button>
                </Col>

                {isEditing && (
                    <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                        <Button
                            onClick={cancelEdit}
                            className='button-red'
                            icon={<CloseOutlined />}
                            style={{ width: '100%' }}
                        >
                            {capitalizeFirstLetter(dictionary['editing-modal-confirm-change-button-cancel'] || 'Cancelar')}
                        </Button>
                    </Col>
                )}
            </Row>


            <List
                dataSource={skill}
                renderItem={item => (
                    <List.Item style={{ width: '100%' }}>
                        <Row style={{ width: '100%', padding: '0px 20px' }} justify="space-between" align="middle">
                            <Col>
                                <Text className='label-skill'>{item.nameSkill}</Text>
                            </Col>
                            <Col>
                                <Space style={{ display: 'flex', gap: 20 }}>
                                    <div style={{ display: 'flex', gap: 10 }}>
                                        {renderRating(item.rating)}
                                    </div>
                                    <Button
                                        icon={<EditOutlined />}
                                        onClick={() => editSkill(item)}
                                        type="default"
                                    />
                                    <Button
                                        icon={<DeleteOutlined />}
                                        onClick={() => deleteSkill(item.key)}
                                        danger
                                    />
                                </Space>
                            </Col>
                        </Row>
                    </List.Item>
                )}
                style={{ background: '#fff', borderRadius: '8px' }}
            />
        </div>
    );
};

export default SkillList;
