import { Col, Row, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React from 'react'
import { useSelector } from 'react-redux';


const { Text } = Typography;
// Component
interface Props {
}
const Questions: React.FC<Props> = () => {
    // States
    const { dictionary } = useSelector((state: any) => state.ui);
    return (
        <Content className="contenedor" style={{ height: '100%', width: '100%', overflowY: 'auto' }}>
            <Row style={{ width: '100%' }}>
                <Text className="font-project" style={{ fontSize: 40, fontWeight: 'bold' }}>FAQs</Text>
            </Row>
            <Row style={{ width: '100%', height: '100%', marginTop: 40 }}>
                {/*--------------------------------------------------------------QUESTION 1----------------------------------------------*/}
                <Row style={{ width: '100%', marginBottom: 40 }}>
                    <Row style={{ width: '100%', marginBottom: 20 }}>
                        <Col xs={2} sm={2} md={2} lg={2} xl={{ span: 2 }} xxl={2} >
                            <div style={{ width: '100%', height: 40, backgroundColor: '#6052A3', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Text className="font-project" style={{ fontSize: 30, fontWeight: 'bold', color: 'white' }}>Q</Text>
                            </div>
                        </Col>
                        <Col style={{ marginLeft: 10 }} xxl={{ span: 21, offset: 1 }}>
                            <Text className="font-project" style={{ fontSize: 18, fontWeight: 'bold' }}>
                                {dictionary["questionsView-question-title-1"]}
                            </Text>
                        </Col>
                    </Row>

                    <Row style={{ width: '90%', marginBottom: 20 }}>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-1-1"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-1-2"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-1-3"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-1-4"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-1-5"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-1-6"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-1-7"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-1-8"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-1-9"]}</Text></Col>
                        </Row>
                    </Row>
                </Row>
                {/*--------------------------------------------------------------QUESTION 2----------------------------------------------*/}
                <Row style={{ width: '100%', marginBottom: 40 }}>
                    <Row style={{ width: '100%', marginBottom: 20 }}>
                        <Col xs={2} sm={2} md={2} lg={2} xl={{ span: 2 }} xxl={2} >
                            <div style={{ width: '100%', height: 40, backgroundColor: '#6052A3', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Text className="font-project" style={{ fontSize: 30, fontWeight: 'bold', color: 'white' }}>Q</Text>
                            </div>
                        </Col>
                        <Col xxl={{ span: 21, offset: 1 }} style={{ marginLeft: 10 }}>
                            <Text className="font-project" style={{ fontSize: 18, fontWeight: 'bold' }}>
                                {dictionary["questionsView-question-title-2"]}
                            </Text>
                        </Col>
                    </Row>

                    <Row style={{ width: '100%', marginBottom: 20 }}>

                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-2-1"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-2-2"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-2-3"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-2-4"]}</Text></Col>
                        </Row>
                    </Row>
                </Row>


                {/*--------------------------------------------------------------QUESTION 3----------------------------------------------*/}
                <Row style={{ width: '100%', marginBottom: 40 }}>
                    <Row style={{ width: '100%', marginBottom: 20 }}>
                        <Col xs={2} sm={2} md={2} lg={2} xl={{ span: 2 }} xxl={2} >
                            <div style={{ width: '100%', height: 40, backgroundColor: '#6052A3', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Text className="font-project" style={{ fontSize: 30, fontWeight: 'bold', color: 'white' }}>Q</Text>
                            </div>
                        </Col>
                        <Col xxl={{ span: 21, offset: 1 }} style={{ marginLeft: 10 }}>
                            <Text className="font-project" style={{ fontSize: 18, fontWeight: 'bold' }}>
                                {dictionary["questionsView-question-title-3"]}
                            </Text>
                        </Col>
                    </Row>

                    <Row style={{ width: '100%', marginBottom: 20 }}>

                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-3-1"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-3-2"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-3-3"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-3-4"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-3-5"]}</Text></Col>
                        </Row>
                    </Row>
                </Row>
                {/*--------------------------------------------------------------QUESTION 4----------------------------------------------*/}
                <Row style={{ width: '100%', marginBottom: 40 }}>
                    <Row style={{ width: '100%', marginBottom: 20 }}>
                        <Col xs={2} sm={2} md={2} lg={2} xl={{ span: 2 }} xxl={2} >
                            <div style={{ width: '100%', height: 40, backgroundColor: '#6052A3', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Text className="font-project" style={{ fontSize: 30, fontWeight: 'bold', color: 'white' }}>Q</Text>
                            </div>
                        </Col>
                        <Col xxl={{ span: 21, offset: 1 }} style={{ marginLeft: 10 }}>
                            <Text className="font-project" style={{ fontSize: 18, fontWeight: 'bold' }}>
                                {dictionary["questionsView-question-title-4"]}
                            </Text>
                        </Col>
                    </Row>

                    <Row style={{ width: '100%', marginBottom: 20 }}>

                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-4-1"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-4-2"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-4-3"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-4-4"]}</Text></Col>
                        </Row>

                    </Row>
                </Row>
                {/*--------------------------------------------------------------QUESTION 5----------------------------------------------*/}
                <Row style={{ width: '100%', marginBottom: 40 }}>
                    <Row style={{ width: '100%', marginBottom: 20 }}>
                        <Col xs={2} sm={2} md={2} lg={2} xl={{ span: 2 }} xxl={2} >
                            <div style={{ width: '100%', height: 40, backgroundColor: '#6052A3', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Text className="font-project" style={{ fontSize: 30, fontWeight: 'bold', color: 'white' }}>Q</Text>
                            </div>
                        </Col>
                        <Col xxl={{ span: 21, offset: 1 }} style={{ marginLeft: 10 }}>
                            <Text className="font-project" style={{ fontSize: 18, fontWeight: 'bold' }}>
                                {dictionary["questionsView-question-title-5"]}
                            </Text>
                        </Col>
                    </Row>

                    <Row style={{ width: '100%', marginBottom: 20 }}>

                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-5-1"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-5-2"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-5-3"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-5-4"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-5-5"]}</Text></Col>
                        </Row>
                        <Row style={{ width: "95%", marginLeft: "5%" }}>
                            <Col xxl={{ span: 22, offset: 1, }}><Text className="font-project" style={{ fontSize: 15, fontWeight: 'lighter' }}>{dictionary["questionView-answer-title-5-6"]}</Text></Col>
                        </Row>

                    </Row>
                </Row>



            </Row>
        </Content>
    )
}

export default Questions