import axios from "axios"
import { EEmail, EEmailLanguage } from "../models/emails";
import routes from "../api";
const baseURL = routes.mode === "dev" ? routes.dev_route : routes.qa_route
export const fetchSendNotificationLike = async (id: string) => {
    try {

    } catch (error) {
        return {

        }
    }
}

export const fetchSendEmail = async (email: string, typeEmail: EEmail, language: EEmailLanguage, context: any, token: string) => {
    try {
        const configSendEmail = {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json', // Asegura que el contenido se envíe como JSON
            },
        };

        const body = {
            email: email.trim(),
            typeEmail,
            language: language.trim(),
            context
        };

        await axios.post(`${baseURL}/email/sendEmailNotification`, body, configSendEmail);
        console.log("Email enviado correctamente", email)
    } catch (error) {
        console.log("Error al enviar el email", error)
    }
}

export const sendEmail = async (email: string, typeEmail: EEmail, language: EEmailLanguage, context: any, token: string) => {
    try {
        const config = {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json', // Asegura que el contenido se envíe como JSON
            },
        }

        const body = {
            email: email.trim(),
            typeEmail,
            language: language.trim(),
            context
        };

        const res = await axios.post(`${baseURL}/email/sendEmailNotification`, body, config);

        if (!res.data.success) {
            const errorMessage = res.data.error instanceof Error ? res.data.error : 'Unknown error occurred'
            throw new Error(errorMessage);
        }

        return {
            success: true,
            data: null,
        }
    } catch (error) {
        return {
            success: false,
            error: error instanceof Error ? error.message : 'Unknown error occurred',
        };
    }
}
