import { Col, Input, Radio, Row, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';
import './SalarySection.css'

interface ISalarySectionProps {
    salary: number;
    setSalary: (salary: string) => void;
    setMaxSalary: (salary: string) => void;
    salaryMax: number;
    checkAnnual: boolean;
    setCheckAnnual: (checkAnnual: boolean) => void;
    checkHourly: boolean;
    setCheckHourly: (checkHourly: boolean) => void;
}
const SalarySection: React.FC<ISalarySectionProps> = ({ salary, setSalary, salaryMax, setMaxSalary, checkAnnual, setCheckAnnual, checkHourly, setCheckHourly }) => {
    const { dictionary } = useSelector((state: any) => state.ui);

    const onChangeAnnual = () => {
        setCheckAnnual(!checkAnnual);
        checkHourly === true && setCheckHourly(false)
    };

    const onChangeHourly = () => {
        setCheckHourly(!checkHourly);
        checkAnnual === true && setCheckAnnual(false);
    };
    return (
        <Row className='row-section-salary-job'>

            {/* Title Salary */}
            <Col className='col-label-section-salary-job'
                xs={{ span: 23 }} md={6} lg={5} xl={4}
            >
                <Typography.Text className='title'>
                    {dictionary["creation-modal-page-one-field-salary"]}{" "}*
                </Typography.Text>
            </Col>

            {/* Input salary */}
            <Col
                className='col-input'
                xs={{ span: 24 }} md={6} lg={6} xl={3}
            >
                <Input
                    value={salary}
                    maxLength={8}
                    size="large"
                    placeholder={dictionary["creation-modal-page-one-placeholder-salary"]}
                    allowClear
                    variant='borderless'
                    className='input-job-salary-section'
                    onChange={(e) => setSalary(e.target.value)}
                />
            </Col>

            {/* Title max salary */}
            <Col
                className='col-label-section-max-salary-job'
                xs={{ span: 24 }} md={{ span: 6, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 4, offset: 1 }}
            >
                <Typography.Text className='label-salary-section'>
                    {dictionary["new-job-modal-text-title2"]}{" "}*
                </Typography.Text>
            </Col>

            {/* Input max salary */}
            <Col
                className='col-input'
                xs={{ span: 24 }}
                md={5} lg={{ span: 6, offset: 1 }} xl={{ span: 3, offset: 0 }}
            >
                <Input
                    value={salaryMax}
                    maxLength={8}
                    size="large"
                    placeholder={dictionary["creation-modal-page-one-placeholder-salary"]}
                    allowClear
                    variant='borderless'
                    className='input-job-salary-section'
                    onChange={(e) => setMaxSalary(e.target.value)}
                />
            </Col>

            {/* CheckBox annual */}

            <Col
                className='col-checkbox'
                xs={24}
                sm={{ span: 11, offset: 1 }}
                md={{ span: 5, offset: 6 }}
                lg={{ span: 5, offset: 5 }}
                xl={{ span: 3, offset: 1 }}
            >
                <Radio
                    className="custom-radio-purple label-salary-section"
                    checked={checkAnnual}
                    onChange={onChangeAnnual}
                >
                    {dictionary["creation-modal-page-one-checkbox-annual"]}
                </Radio>
            </Col>

            {/* CheckBox Hourly */}
            <Col
                className='col-checkbox'
                xs={24}
                sm={12}
                md={{ span: 5, offset: 2 }}
                lg={{ span: 5, offset: 2 }}
                xl={{ span: 3, offset: 0 }}
            >
                <Radio
                    className="custom-radio-purple label-salary-section"
                    checked={checkHourly}
                    onChange={onChangeHourly}
                >
                    {dictionary["creation-modal-page-one-checkbox-hourly"]}
                </Radio>
            </Col>
        </Row>
    )
}

export default SalarySection