import React from 'react';
import { Card, Row, Col, Skeleton } from 'antd';
import { Content } from 'antd/es/layout/layout';

const LoadingCard = () => (
    <Content
        style={{
            alignContent: 'center',
            height: '100%',
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            padding: '10px'
        }}
    >
        <Row style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card style={{ width: '100%', height: '100%', boxShadow: "7px 7px 15px #00000016", borderRadius: '8px' }}>
                <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
                    <Col span={12}>
                        <Skeleton loading={true} active avatar paragraph={{ rows: 13 }} />

                    </Col>
                    <Col span={12}>
                        <Skeleton loading={true} active avatar paragraph={{ rows: 13 }} />

                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Skeleton loading={true} active avatar paragraph={{ rows: 6 }} />
                    </Col>
                </Row>
            </Card>
        </Row>
    </Content>
);

export default LoadingCard;
