import { Col, List, Row, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';
import { Content } from 'antd/lib/layout/layout';
import icoEducation from "../../image/ico-education.png";

// Component 
const { Text } = Typography;

// Interface
interface Props {
    education: Array<any>
}
const EducationSectionCandidateCard: React.FC<Props> = ({ education }) => {

    // States
    const { dictionary } = useSelector((state: any) => state.ui);
    return (
        <>
            <Row style={{ height: '100%', width: '100%' }}>
                <Row style={{ height: '20%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10 }}>
                    <Col xs={6} sm={6} md={4} lg={3} xl={4} xxl={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img
                            src={icoEducation}
                            style={{ height: 40, width: 40 }}
                            alt={icoEducation}
                        />
                    </Col>

                    <Col xs={18} sm={18} md={17} lg={20} xxl={20} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Text style={{ color: "#129BD4", fontSize: 24 }} className="font-project">
                            {dictionary['sourcing-card-title-education']}
                        </Text>
                    </Col>
                </Row>

                <Row style={{ height: '80%', width: '100%' }}>
                    {
                        education.length > 0 && (
                            <List
                                dataSource={education}
                                style={{ height: '100%', width: '100%' }}
                                renderItem={(education) => (
                                    <Content style={{ height: '100%', width: '100%', marginTop: '3%' }}>
                                        <Row style={{ width: '100%' }}>
                                            <Col xs={{ span: 9, offset: 2 }} sm={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} lg={{ span: 4, offset: 2 }} xl={{ span: 5 }} xxl={{ span: 5, offset: 2 }}>
                                                <Row style={{ width: '100%' }}>
                                                    <Col span={23}>
                                                        <Text style={{ fontSize: 18 }} className="font-project">{education.degree}</Text>
                                                    </Col>

                                                    <Col span={1} style={{ textAlign: 'center', alignItems: 'center' }}>
                                                        <Text style={{ fontSize: 18, textAlign: 'center' }} className="font-project">|</Text>
                                                    </Col>
                                                </Row>

                                            </Col>

                                            <Col xs={{ span: 12, offset: 1 }} sm={{ span: 11, offset: 1 }} md={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 5, offset: 1 }} xxl={{ span: 6, offset: 1 }}>
                                                <Row style={{ width: '100%' }}>
                                                    <Col span={23} style={{ textAlign: 'start', alignItems: 'center' }}>
                                                        <Text className="font-project" style={{ fontSize: 18 }}>{education.fieldStudy}</Text>
                                                    </Col>
                                                    <Col span={1} style={{ textAlign: 'center', alignItems: 'center' }}>
                                                        <Text style={{ fontSize: 18, textAlign: 'center' }} className="font-project">|</Text>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs={{ span: 9, offset: 2 }} sm={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} lg={{ span: 6, offset: 1 }} xl={{ span: 5, offset: 1 }} xxl={{ span: 5, offset: 1 }} style={{ textAlign: 'center' }}>
                                                <Row style={{ width: '100%' }}>
                                                    <Col span={23} style={{ textAlign: 'start', alignItems: 'center' }}>
                                                        <Text style={{ fontSize: 18, textAlign: 'center' }} className="font-project"> {education.institution}</Text>
                                                    </Col>

                                                    <Col span={1} style={{ textAlign: 'center', alignItems: 'center' }}>
                                                        <Text style={{ fontSize: 18, textAlign: 'center' }} className="font-project">|</Text>
                                                    </Col>
                                                </Row>

                                            </Col>

                                            <Col xs={{ span: 13 }} sm={{ span: 11, offset: 1 }} md={{ span: 11, offset: 1 }} lg={{ span: 2, offset: 1 }} xl={{ span: 3, offset: 1 }} xxl={{ span: 2, offset: 1 }}>
                                                <Row style={{ width: '100%' }}>

                                                </Row>
                                            </Col>
                                        </Row>
                                    </Content>
                                )}
                            />
                        )
                    }
                </Row>
            </Row>


        </>
    )
}

export default EducationSectionCandidateCard