import './RegisterPage.css'
import React from 'react'
import { useSelector } from 'react-redux';
import { StateGeneralRedux } from '../../types/redux';
import { Col, Row, Typography } from 'antd';
import { ToastContainer } from 'react-toastify';
import RegisterCard from '../../components/auth/register/registerCard/RegisterCard';
import { assets } from '../../assets/assets';
import { useNavigate } from 'react-router-dom';


// Components
const RegisterPage: React.FC = () => {

    // States
    const { dictionary } = useSelector((state: StateGeneralRedux) => state.ui);

    const navigate = useNavigate();
    return (
        <Row className='row-content-register-page'>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover />
            <Col className='left-section-register-page' xs={24} sm={24} md={24} lg={24} xl={11} xxl={14}>
                <div className="logo-image-register-page">
                    <img src={assets.irgtIcon} alt="" />
                </div>

                <h2 className='title-register-page'>{dictionary['register-page-welcome-message']}</h2>
                <p className='description-register-page'> {dictionary['register-page-secondary-message']}</p>
            </Col>

            <Col xs={20} sm={{ span: 18 }} md={18} lg={10} xl={13} xxl={10} className='right-section-register-page'>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '20px', boxSizing: 'border-box', marginBottom: '20px' }}>
                    <Typography.Text style={{ color: '#5A5AD1' }}>{dictionary['register-card-already-have-account']}</Typography.Text>

                    <Typography.Text onClick={() => navigate('/login')} style={{ color: '#5A5AD1', marginLeft: '5px', marginRight: '120px', textDecoration: 'underline', cursor: 'pointer' }}>
                        {dictionary['register-card-sign-in-here']}
                    </Typography.Text>
                </div>

                <RegisterCard />


                <p className='font-project' style={{ fontSize: '16px' }}>
                    {dictionary['register-card-for-more-info']}
                    <a
                        href="https://gocareerswipe.com/"
                        style={{ marginLeft: '2px', fontSize: '16px' }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        gocareerswipe.com
                    </a>
                </p>
            </Col>
        </Row>
    )
}

// Register
export default RegisterPage;