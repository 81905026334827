import irgtIcon from './logo/inrgt-logo@3x.png';
import iconBetterSalary from "./dashboard/iconSalaryWithoutText.png";
import iconRemote from "./dashboard/iconRemoteWithoutText.png";
import iconLocation from "./dashboard/iconOtherLocationWithoutText.png";
import iconLevel from "./dashboard/iconLevelWithoutText.png";
import iconCalendar from './dashboard/iconCalendar.png'

// Sidebar Icons
import iconDot from './sidebar/list-dot.png'
import iconDashboard from './sidebar/dashboard-icon.png'
import iconSourcing from './sidebar/search-icon.png'
import iconJob from './sidebar/job-icon.png'
import iconAddJob from './sidebar/add-job-icon.png';

// Navbar Icons
import iconLanguageFr from './navbar/fr-icon.png';
import iconLanguageEn from './navbar/en-icon.png';
import iconLanguageEs from './navbar/es-icon.png';
import iconSettings from './navbar/setting-icon.png';
import iconUser from './navbar/user-icon.png';
import iconMessage from './navbar/chat-icon.png';
import iconLogout from './navbar/logout-icon.png';

// Logo
import iconLogoPopUp from './logo/logo-cs-popup.png'

import iconSkillNull from './general/skill-0-icon.png'
import iconSkill from './general/skill-1-icon.png';

export const assets = {
    irgtIcon,
    iconBetterSalary,
    iconRemote,
    iconLocation,
    iconLevel,
    iconCalendar,
    iconAddJob,
    iconDot,
    iconDashboard,
    iconSourcing,
    iconJob,
    iconLanguageFr,
    iconLanguageEn,
    iconLanguageEs,
    iconSettings,
    iconUser,
    iconMessage,
    iconLogout,
    iconLogoPopUp,
    iconSkill,
    iconSkillNull
}