import { Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { formatNumber } from '../../../../../../hooks/hooks';
import './TotalCompensationSection.css'

interface ITotalCompensationSectionProps {
    salary: string;
    salaryMax: string;
    totalSalary: string;
    valueHourForWeek: number;
    checkMin: boolean;
    checkMiddle: boolean;
    checkMax: boolean;
    checkHourly: boolean;
    checkAnnual: boolean;
    checkPercentage: boolean;
    checkAmount: boolean;
    checkBonnus: boolean;
}

const TotalCompensationSection: React.FC<ITotalCompensationSectionProps> = ({ checkAmount, checkAnnual,
    checkBonnus, checkHourly, checkMax, checkMiddle, checkMin, checkPercentage, salary, salaryMax, totalSalary, valueHourForWeek
}) => {
    const { dictionary } = useSelector((state: any) => state.ui);
    const [result, setResult] = useState<any>(0);

    const labelTotalCompensation = `${dictionary["creation-modal-preview-field-total-compensation"]}: $ ${formatNumber(result)}`;

    useEffect(() => {
        if (salary.trim() === "" || salaryMax.trim() === "" || parseFloat(salaryMax.replace(/,/g, "")) <= parseFloat(salary.replace(/,/g, ""))) {
            return setResult(0);;
        }

        const numMin = parseFloat(salary.replace(/,/g, ""));
        const numMax = parseFloat(salaryMax.replace(/,/g, ""));
        const numMid = (numMin + numMax) / 2;

        let numero1: number =
            checkMin === true
                ? numMin
                : checkMax === true
                    ? numMax
                    : checkMiddle === true
                        ? numMid
                        : 0;
        let numero2: number = parseFloat(totalSalary.replace(/,/g, ""));

        var numeroResult: number = 0;

        if (checkHourly === true && checkAmount === false && checkPercentage === false) {
            numeroResult = numero1 * valueHourForWeek * 50 + 0;
            setResult(numeroResult);
        } else {
            if (checkHourly === true) {
                if (checkAmount === true) {
                    numeroResult =
                        numero1 * valueHourForWeek * 50 +
                        (checkBonnus === true ? numero2 : 0);
                    setResult(numeroResult);
                } else {
                    if (checkPercentage === true) {
                        numeroResult = numero1 * valueHourForWeek * 50;
                        numeroResult =
                            numeroResult +
                            (checkBonnus === true ? (numero2 * numeroResult) / 100 : 0);
                        setResult(numeroResult);
                    }
                }
            }
        }

        if (checkAnnual === true && checkAmount === false && checkPercentage === false) {
            numeroResult = numero1;
            setResult(numeroResult);
        } else {
            if (checkAnnual === true) {
                if (checkAmount === true) {
                    numeroResult = numero1 + (checkBonnus === true ? numero2 : 0);
                    setResult(numeroResult);
                } else {
                    if (checkPercentage === true) {
                        numeroResult = numero1;
                        numeroResult =
                            numero1 +
                            (checkBonnus === true ? (numero2 * numeroResult) / 100 : 0);

                        setResult(numeroResult);
                    }
                }
            }
        }
    }, [checkHourly, checkAnnual, checkPercentage, checkAmount, salary, totalSalary, valueHourForWeek, salaryMax, checkMax, checkMiddle, checkMin, checkBonnus,]);



    return (
        <Row className='row-section-total-compensation-job'>
            <Typography.Text className='title-total-compensation-job'>
                {labelTotalCompensation.toUpperCase()}
            </Typography.Text>
        </Row>
    )
}

export default TotalCompensationSection