import { Button, Col, Row } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';
import './FooterAddJobModal.css'

interface IFooterAddJobModal {
    steps: "Initial" | "First Step" | "Second Step" | "Third Step" | "Final Step";
    handleOnBackStep: (steps: "Initial" | "First Step" | "Second Step" | "Third Step" | "Final Step") => void;
    data: any;
    handleOnUpdatePositionData: (idPosition: string) => void;
    nextStep: boolean;
    handleOnSaveJob: (status: string) => void;
    handleOnNextStep: (steps: "Initial" | "First Step" | "Second Step" | "Third Step" | "Final Step") => void;
}
const FooterAddJobModal: React.FC<IFooterAddJobModal> = ({ data, handleOnBackStep, steps, handleOnUpdatePositionData, nextStep, handleOnSaveJob, handleOnNextStep }) => {
    const { dictionary } = useSelector((state: any) => state.ui);
    return (
        <Row className='row-container-footer-add-job-modal'>
            <Col className='col-buttons-add-job-modal' xs={24} md={8}>
                {
                    steps === "Initial" ? null
                        : steps === "First Step" && data !== undefined
                            ? (
                                <div style={{ width: 100 }} />
                            ) : (
                                <Button className='previous-button-footer-add-job-modal' onClick={() => handleOnBackStep(steps)}>
                                    {dictionary["creation-modal-button-previous"]}
                                </Button>
                            )
                }
            </Col>

            <Col className='col-buttons-add-job-modal' xs={24} md={8}>
                {steps !== "Initial" && data !== undefined && (
                    <Button className='save-button-footer-add-job-modal' onClick={() => handleOnUpdatePositionData(data?.idPosition)}>
                        {dictionary["editing-modal-button-save-changes"]}
                    </Button>
                )}
            </Col>

            <Col className='col-buttons-add-job-modal' xs={24} md={8}>
                {steps !== "Initial" && (
                    <Button
                        disabled={nextStep}
                        onClick={() =>
                            steps === "Final Step"
                                ? handleOnSaveJob('open')
                                : handleOnNextStep(steps)
                        }
                        className={`next-button-footer-add-job-modal ${nextStep ? "next-button-footer-add-job-modal-inactive" : "next-button-footer-add-job-modal-active"}`}
                    >
                        {steps === "First Step" || steps === "Second Step"
                            ? dictionary["creation-modal-button-next"]
                            : steps === "Third Step"
                                ? dictionary["creation-modal-button-next"]
                                : steps === "Final Step" && data === undefined
                                    ? dictionary["creation-modal-preview-button-publish"]
                                    : null}
                    </Button>
                )}
            </Col>
        </Row>
    )
}

export default FooterAddJobModal