import React from 'react';
import { Button, ButtonProps } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import './CloseButton.css';

interface CloseButtonProps extends ButtonProps {
    iconSize?: number;
    iconColor?: string;
}

const CloseButton: React.FC<CloseButtonProps> = ({
    iconSize = 30,
    iconColor = 'red',
    ...props
}) => {
    return (
        <Button
            {...props}
            className='close-button'
            icon={
                <CloseCircleOutlined
                    style={{ fontSize: 30, color: 'red' }}
                />
            }
        />
    );
};

export default CloseButton;
