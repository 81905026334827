import { Col, List, Row, Typography } from 'antd'
import React from 'react'
import icoWork from "../../image/ico-work.png";
import { useSelector } from 'react-redux';

import { Content } from 'antd/lib/layout/layout';

// Component 
const { Text } = Typography;

// Interface
interface Props {
    experience: Array<any>
}
const ExperienceSectionCandidateCard: React.FC<Props> = ({ experience }) => {

    // States
    const { dictionary } = useSelector((state: any) => state.ui);
    return (
        <>
            <Row style={{ height: '100%', width: '100%' }}>
                <Row style={{ height: '20%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10 }}>
                    <Row style={{ height: '90%', width: '100%' }}>
                        <Col xs={6} sm={6} md={4} lg={3} xl={4} xxl={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                src={icoWork}
                                style={{ height: 40, width: 40 }}
                                alt={icoWork}
                            />
                        </Col>

                        <Col xs={10} sm={7} md={6} lg={4} xl={6} xxl={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Text style={{ color: "#129BD4", fontSize: 24 }} className="font-project">
                                {dictionary['sourcing-card-title-experience']}
                            </Text>
                        </Col>

                    </Row>
                </Row>

                <Row style={{ height: '80%', width: '100%' }}>
                    <Row style={{ overflowY: "auto", height: "100%", width: '100%' }}>
                        {
                            experience.length > 0 && (
                                <List
                                    style={{ height: '100%', width: '100%' }}
                                    dataSource={experience}
                                    renderItem={(experience) => (
                                        <Content style={{ height: '100%', width: '100%', paddingLeft: '8%', paddingRight: '2%', marginTop: '3%' }}>
                                            <Row style={{ width: '100%', height: '100%' }}>
                                                <Col xs={24} sm={16} md={{ span: 16 }} lg={19} xl={18} xxl={18}>
                                                    <Text style={{ fontSize: 18, fontWeight: 'bold' }} className="font-project">
                                                        {experience.title}  |  {experience.company}
                                                    </Text>
                                                </Col>

                                                <Col xs={24} sm={8} md={{ span: 6, offset: 1 }} lg={{ span: 4, offset: 1 }} xl={{ span: 6, offset: 0 }} xxl={{ span: 6, offset: 0 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Text style={{ fontWeight: "bold", fontSize: 18, }} className="font-project">
                                                        {experience.dateStart} -{" "} {experience.actualPosition ? "PRESENT" : experience.dateEnd}
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row style={{ marginTop: '1%' }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xxl={22}>
                                                    <Text className="font-project" style={{ fontSize: 16 }}>
                                                        {experience.description}
                                                    </Text>
                                                </Col>
                                            </Row>
                                        </Content>
                                    )}
                                />
                            )
                        }
                    </Row>
                </Row>
            </Row>
        </>
    )
}

export default ExperienceSectionCandidateCard