import React from 'react'
import { IPosition } from '../../models/hiring'
import './PreviewJob.css'
import TitleSection from './titleSection/TitleSection';
import PreviewCarousel from './previewCarousel/PreviesCarousel';

interface IPreviewJobProps {
    position: IPosition;
}
const PreviewJob: React.FC<IPreviewJobProps> = ({ position }) => {
    return (
        <div className='container-preview-job '>
            <TitleSection title={position.title} />

            <div className='body-preview-job'>
                <PreviewCarousel position={position} />
            </div>

        </div>
    )
}

export default PreviewJob