import { Input, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import './ResponsibilitiesSection.css'

interface IResponsibilitiesSectionProps {
    responsibilitiesText: string;
    setResponsibilitiesText: (value: string) => void;
    onBlur: () => void;
}

const ResponsibilitiesSection: React.FC<IResponsibilitiesSectionProps> = ({ responsibilitiesText, setResponsibilitiesText, onBlur }) => {
    const { dictionary } = useSelector((state: any) => state.ui);
    return (
        <div className='div-section-responsibilities-job'>
            <Typography.Text className='label-responsibilities'>
                {dictionary["creation-modal-page-two-field-responsibilities"]}{' '}*
            </Typography.Text>

            <div className='div-input-responsibilities'>
                <Input.TextArea
                    rows={8}
                    value={responsibilitiesText}
                    onChange={(e) => setResponsibilitiesText(e.target.value)}
                    showCount
                    onBlur={onBlur}
                    placeholder={dictionary["creation-modal-page-two-placeholder-job-summary"]}
                    className='input-responsibilities-section'
                    allowClear
                    variant='borderless'
                    count={{
                        show: true,
                        max: 2500,
                    }}
                />
            </div>
        </div>
    );
};

export default ResponsibilitiesSection;
