//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------

// Dependencies
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import { useSelector } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/config";

const { Text } = Typography;

// Props
interface CounterDownProps {
  seconds: number;
  handleOnLogout: () => void;
}

// Helper
const formatTime = (time: number): string => {
  let minutes: number | string = Math.floor(time / 60);
  let seconds: number | string = Math.floor(time % 60);

  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;

  return `${minutes}:${seconds}`;
};

// Component
const CounterDown: React.FC<CounterDownProps> = ({ seconds, handleOnLogout }) => {
  const { dictionary } = useSelector((state: any) => state.ui);
  const [countDown, setCountDown] = useState<number>(seconds);
  const timerId = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();

  const LogOut = useCallback(async () => {
    try {
      await signOut(auth);
      handleOnLogout();
      navigate("/login");
    } catch (error) {
      alert(`Error in function LogOut: ${error}`);
    }
  }, [handleOnLogout, navigate]);

  useEffect(() => {
    timerId.current = setInterval(() => {
      setCountDown((prev) => {
        const nextValue = prev - 1;
        localStorage.setItem("seconds", nextValue.toString());
        return nextValue;
      });
    }, 1000);

    return () => {
      if (timerId.current) clearInterval(timerId.current);
    };
  }, []);

  useEffect(() => {
    if (countDown <= 0) {
      if (timerId.current) clearInterval(timerId.current);
      LogOut();
    }
  }, [countDown, LogOut]);

  return (
    <Text style={{ fontSize: 18, fontFamily: "Mulish" }}>
      {dictionary["change-password-text-counter"]}:{formatTime(countDown)}
    </Text>
  );
};

export default CounterDown;
