import React, { useCallback } from 'react'
import './WorkCondition.css'
import { Typography } from 'antd'
import { useSelector } from 'react-redux';
import JobTypeCheckBox from './JobTypeCheckBox';

interface IWorkConditionProps {
    type: Array<string>;
    setType: (typeList: Array<string>) => void;
}
const WorkCondition: React.FC<IWorkConditionProps> = ({ setType, type }) => {

    const { dictionary } = useSelector((state: any) => state.ui);

    const saveJobType = useCallback((typeList: Array<string>) => {
        setType(typeList);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <div className='div-work-condition-title'>
                <Typography.Text className='label-bonus-section'>
                    {dictionary["creation-modal-page-one-field-job-type"]}{" "}*
                </Typography.Text>
            </div>
            <div className='div-work-condition-section'>
                <JobTypeCheckBox dataJobType={type} saveJobType={saveJobType} />
            </div>
        </>
    )
}

export default WorkCondition