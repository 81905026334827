import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../config";
import { CollectionsName } from "../../../models/collections";
import { CreateNotesPreCandidateDocumentResponse, CreateRecommendationDocumentResponse, GetPreCandidateDocumentResponse, INotes } from "./type";
import { JobRecommendationPrecandidate } from "../../../types/position";
import { EErrorMessage } from "../error-types";
import { PreCandidateInterface } from "../../../models/preCandidate.interface";

export const createRecommendationDocument = async (idPreCandidate: string, idPosition: string, data: JobRecommendationPrecandidate): Promise<CreateRecommendationDocumentResponse> => {
    try {
        await setDoc(doc(db, CollectionsName.PRECANDIDATES, idPreCandidate, CollectionsName.RECOMMENDATION, idPosition), data);

        return {
            success: true,
            data: data,
        };
    } catch (error) {
        return {
            success: false,
            error: error instanceof Error ? error.message : 'Unknown error occurred',
        };
    }
}

export const getPreCandidateDocument = async (idPreCandidate: string): Promise<GetPreCandidateDocumentResponse> => {
    try {
        const preCandidateDocument = await getDoc(doc(db, CollectionsName.PRECANDIDATES, idPreCandidate));

        if (!preCandidateDocument.exists()) {
            return {
                success: false,
                error: EErrorMessage.NOT_DOCUMENT_DATA_FOUND,
            }
        } else {
            const preCandidateDocumentData = preCandidateDocument.data() as PreCandidateInterface;
            return {
                success: true,
                data: preCandidateDocumentData,
            }
        }
    } catch (error) {
        return {
            success: false,
            error: error instanceof Error ? error.message : EErrorMessage.GET_DOCUMENT_ERROR,
        };
    }
}

export const createNotesPreCandidateDocument = async (idPreCandidate: string, note: INotes): Promise<CreateNotesPreCandidateDocumentResponse> => {
    try {
        const noteDocumentRef = doc(db, CollectionsName.PRECANDIDATES, idPreCandidate, CollectionsName.NOTESPRECANDIDATE, note.idNote);

        await setDoc(noteDocumentRef, note);

        return {
            success: true,
            data: note,
        }
    } catch (error) {
        return {
            success: false,
            error: error instanceof Error ? error.message : 'Unknown error occurred',
        };
    }
}