import React from 'react'
import { IPosition } from '../../../../models/hiring'
import './SectionOneCarousel.css'
interface ISectionOneCarouselProps {
    position: IPosition;
}
const SectionOneCarousel: React.FC<ISectionOneCarouselProps> = ({ position }) => {
    return (
        <div className='div-section-one-carousel'>


        </div>
    )
}

export default SectionOneCarousel