//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Col, Input, Row, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { getTranslation } from "../../../../hooks/hooks";
import '../index.css'
import './ContactInformationSection.css'

// Interface
interface Props {
  avatarPersonal: string;
  setAvatarPersonal: (value: any) => void;
  nameForm: string;
  setNameForm: (value: string) => void;
  setLastNameForm: (value: string) => void;
  lastNameForm: string;
  phoneNumberForm: string;
  setPhoneNumberForm: (value: string) => void;
  emailForm: string;
  titleForm: string;
  setTitleForm: (value: string) => void;
}

// Component Ant Design
const { Text } = Typography;

// Component
const ContactInformationSection: React.FC<Props> = ({ avatarPersonal, setAvatarPersonal, emailForm, lastNameForm, nameForm, phoneNumberForm, setLastNameForm, setNameForm, setPhoneNumberForm, setTitleForm, titleForm, }) => {
  const { dictionary } = useSelector((state: any) => state.ui);

  return (
    <Row style={{ width: '100%', height: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Row className="row-container-inputs">
        <Col xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }} lg={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 18, offset: 3 }}>
          <Row className="row-input-group">
            <Col xs={24} style={{ marginBottom: '10px' }}>
              <Text style={{ fontSize: 25, color: "#129bd4", fontFamily: "Mulish", fontWeight: 'bold' }}
              >
                {dictionary["profile-title-contact-information"]}
              </Text>
            </Col>
            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 0 }} xl={{ span: 7, offset: 0 }} xxl={{ span: 7, offset: 0 }} className="container-text-input">
              <Input
                className="input-fields"
                placeholder={dictionary["first-login-modal-placeholder-field-name"]}
                value={nameForm}
                onChange={(e) => setNameForm(e.target.value)}
              />

              <Text className="text-field-name-input">
                {dictionary["first-login-modal-view1-text-title2"]}<Text style={{ color: 'red' }}>*</Text>
              </Text>
            </Col>

            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 1 }} md={{ span: 11, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 1 }} className="container-text-input">
              <Input
                className="input-fields"
                placeholder={
                  dictionary["first-login-modal-placeholder-field-lastName"]
                }
                value={lastNameForm}
                onChange={(e) => setLastNameForm(e.target.value)}
              />

              <Text className="text-field-name-input">
                {dictionary["first-login-modal-view1-text-title3"]}<Text style={{ color: 'red' }}>*</Text>
              </Text>
            </Col>

            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 1 }} className="container-text-input">
              <Input
                className="input-fields"
                placeholder={
                  dictionary["first-login-modal-placeholder-field-phone"]
                }
                value={phoneNumberForm}
                onChange={(e) => setPhoneNumberForm(e.target.value)}
              />

              <Text className="text-field-name-input">
                {dictionary["first-login-modal-view1-text-title4"]}<Text style={{ color: 'red' }}>*</Text>
              </Text>
            </Col>

            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 1 }} md={{ span: 11, offset: 1 }} lg={{ span: 7, offset: 0 }} xl={{ span: 7, offset: 0 }} xxl={{ span: 7, offset: 0 }} className="container-text-input">
              <Input
                className="input-fields"
                placeholder={getTranslation("profile-field-title", dictionary
                )}
                value={titleForm}
                onChange={(e) => setTitleForm(e.target.value)}
              />

              <Text className="text-field-name-input">
                {dictionary["first-login-modal-view1-text-title6"]}*
              </Text>
            </Col>

            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 1 }} className="container-text-input">
              <Input
                style={{ backgroundColor: "white" }}
                className="input-fields"
                placeholder="Email"
                value={emailForm}
                disabled
              />

              <Text className="text-field-name-input">
                {dictionary["first-login-modal-view1-text-title5"]}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <Col xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }} lg={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 18, offset: 3 }}>
          <Text style={{ fontFamily: "Mulish", fontSize: 14, color: 'red' }}>{dictionary["register-modal-text-bottom-mandatory"]}</Text>
        </Col>
      </Row>
    </Row>

  );
};

export default ContactInformationSection;
