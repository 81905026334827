import React from 'react'
import './Header.css'
import CloseButton from '../../../buttons/closeButton/CloseButton';
import { assets } from '../../../../assets/assets';

interface IHeaderProps {
    setVisible: (value: boolean) => void;
}
const Header: React.FC<IHeaderProps> = ({ setVisible }) => {
    return (
        <div className='header-preview-job-modal'>
            <div className='div-header-title'>
                <img
                    src={assets.iconLogoPopUp}
                    style={{ height: 30, width: 'auto' }}
                    alt='icon-dot'
                />

                <div className='div-title-add-job-modal'>

                </div>
            </div>

            <div className='div-close-button-add-job-modal'>
                <CloseButton onClick={() => {
                    setVisible(false);
                }} />
            </div>
        </div>
    )
}

export default Header