import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Timestamp } from "firebase/firestore";
import { Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

import CandidateCard from "../../common/candidateCard/CandidateCard";
import PreCandidateCard from "../../common/PreCandidateCard/PreCandidateCard";
import LoadingCard from "./LoadingCard";
import SourcingLoading from "./SourcingLoading";

import { Job } from "../../types/position";
import { getRecommendationCandidates, getRecommendationPrecandidates } from "../../firebase/services/hiringsCollect/hirings";
import { EErrorMessage } from "../../firebase/services/error-types";

const { Text } = Typography;

interface Props {
  position: Job;
}

export interface ICandidateList {
  id: string;
  type: string;
  like: string | boolean;
  promote: string;
  "insertion date": Timestamp;
  skills: string[];
}

const CandidateList: React.FC<Props> = ({ position }) => {
  // Store
  const user = useSelector((state: any) => state.auth.user);
  const { dictionary } = useSelector((state: any) => state.ui);

  // States
  const [list, setList] = useState<ICandidateList[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalCount = list.length;

  const getDataCandidateList = useCallback(async (idHiring, idPosition) => {
    try {
      setLoading(true);

      const [getRecommendationPrecandidatesResponse, getRecommendationCandidatesResponse] = await Promise.all([
        getRecommendationPrecandidates(idHiring, idPosition),
        getRecommendationCandidates(idHiring, idPosition),
      ]);

      if (!getRecommendationPrecandidatesResponse.success) {
        if (getRecommendationPrecandidatesResponse.error !== EErrorMessage.NOT_COLLECT_DATA_FOUND) {
          throw new Error(getRecommendationPrecandidatesResponse.error);
        }
      }

      if (!getRecommendationCandidatesResponse.success) {
        if (getRecommendationCandidatesResponse.error !== EErrorMessage.NOT_COLLECT_DATA_FOUND) {
          throw new Error(getRecommendationCandidatesResponse.error);
        }
      }

      const preCandidates = (getRecommendationPrecandidatesResponse.data || []).filter(preCandidate => preCandidate.hiringLike === null).map(preCandidate => ({ ...preCandidate, type: "PreCandidate" }));

      const candidates = (getRecommendationCandidatesResponse.data || []).filter(candidate => candidate.like === "").map(candidate => ({ ...candidate, type: "Candidate" }));

      // Combinar ambas listas
      const combinedList: any = [...candidates, ...preCandidates].sort((a, b) => new Date((b as any)["insertion date"]).getTime() - new Date((a as any)["insertion date"]).getTime());

      setList(combinedList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching candidate lists:", error);
      setLoading(false);
    }
  }, []);

  // Navegación manual
  const next = () => currentIndex < totalCount - 1 && setCurrentIndex((prev) => prev + 1)
  const prev = () => currentIndex > 0 && setCurrentIndex((prev) => prev - 1)

  const updateListAndIndex = (idToRemove: string) => {
    setList(prevList => {
      const newList = prevList.filter(item => item.id !== idToRemove);
      setCurrentIndex(prevIndex => (prevIndex >= newList.length ? Math.max(0, newList.length - 1) : prevIndex));
      return newList;
    });
  };

  const saveLocalPrecandidate = (idPrecandidate: string) => {
    updateListAndIndex(idPrecandidate);
  };

  const saveLocalCandidate = (idCandidate: string) => {
    updateListAndIndex(idCandidate);
  };

  useEffect(() => {
    getDataCandidateList(user?.idHiring, position?.idPosition);
    return () => {
      setLoading(false);
      setCurrentIndex(0);
      setList([]);
    };
  }, [getDataCandidateList, user?.idHiring, position?.idPosition]);

  return (
    <Content style={{ height: "100%", width: "100%", position: "relative" }}>
      {loading ? (
        <LoadingCard />
      ) : totalCount > 0 ? (
        <>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "100%" }}>
            <div style={{ position: "relative", width: "100%", height: "100%", overflow: "hidden" }}>
              {list.map((item, index) => (
                <div
                  key={item.id}
                  style={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100%",
                    transform: `translateX(${(index - currentIndex) * 100}%)`,
                    opacity: index === currentIndex ? 1 : 0.5,
                    transition: "transform 0.5s cubic-bezier(0.25, 1, 0.5, 1), opacity 0.3s ease-in-out",
                  }}
                >
                  {item.type === "PreCandidate" ? (
                    <PreCandidateCard
                      idPrecandidate={item.id}
                      user={user}
                      next={next}
                      prev={prev}
                      position={position}
                      saveLocalPrecandidate={saveLocalPrecandidate}
                      currentIndex={currentIndex}
                      total={totalCount}
                    />
                  ) : (
                    <CandidateCard
                      position={position}
                      key={item.id}
                      next={next}
                      prev={prev}
                      idCandidate={item.id}
                      user={user}
                      saveLocalCandidate={saveLocalCandidate}
                      currentIndex={currentIndex}
                      total={totalCount}
                    />
                  )}
                </div>

              ))}
            </div>
          </div>

          {/* Indicador de posición */}
          {totalCount > 1 && (
            <div
              style={{
                width: 60,
                height: 35,
                position: "absolute",
                top: "3%",
                right: "3%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #00000040",
                borderRadius: 16,
                backgroundColor: "#6052A3",
                boxShadow: "0px 0px 10px #00000016",
              }}
            >
              <Text style={{ textAlign: "center", fontSize: 23, color: "white" }}>
                {currentIndex + 1}/{totalCount}
              </Text>
            </div>
          )}
        </>
      ) : (
        <SourcingLoading dictionary={dictionary} />
      )}
    </Content>
  );
};

export default CandidateList;
