import { AutoComplete, Col, Row, Typography } from 'antd';
import { collectionGroup, getDocs, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../../../../../../firebase/config';
import { useSelector } from 'react-redux';
import './JobTitleSection.css'

interface IJobTitleSectionProps {
    titleInput: string;
    setTitle: (title: string) => void;
}
const JobTitleSection: React.FC<IJobTitleSectionProps> = ({ setTitle, titleInput }) => {


    // Store states
    const { dictionary, language } = useSelector((state: any) => state.ui);
    // States
    const [titleList, setTitleList] = useState<Array<{ value: string; label: string; predeterminated: string }>>([]);
    // Effects
    useEffect(() => {
        const fetchJobTitles = async () => {
            try {
                const jobTitleQuery = query(collectionGroup(db, "jobTitle"));
                const response = await getDocs(jobTitleQuery);
                const skillList: any = [];

                response.docs.forEach((each) => {
                    const data = each.data();
                    if (data.title && data.title.length > 0 && data.verified) {
                        skillList.push(data.title);
                    }
                });

                const uniqueSkills = skillList.filter(
                    (item: any, index: any) => skillList.indexOf(item) === index
                );
                const orderList = uniqueSkills.sort();

                const skillListSelector = orderList.map((skillName: any) => {
                    return {
                        value: dictionary[skillName] ?? skillName,
                        label: dictionary[skillName] ?? skillName,
                        predeterminated: skillName,
                    };
                });

                const uniqueValues = Array.from(
                    new Set(skillListSelector.map((item: any) => item.value))
                ).map((value) => {
                    return skillListSelector.find((item: any) => item.value === value);
                });

                setTitleList(
                    language === "English"
                        ? uniqueValues.sort((a, b) => a.value.localeCompare(b.value))
                        : uniqueValues
                );
            } catch (error) {
                console.error("Error fetching job titles:", error);
            }
        };

        fetchJobTitles();
    }, [dictionary, language]);
    return (
        <Row className='row-section-title-job'>
            <Col
                className='col-label-section-update-job'
                xs={24}
                sm={24}
                md={6}
                lg={4}
            >
                <Typography.Text className='title'>
                    {dictionary["creation-modal-page-one-field-job-title"]}{" "}*
                </Typography.Text>
            </Col>

            <Col xs={24} sm={24} md={17} lg={20} className='col-select'>
                <AutoComplete
                    allowClear
                    className='autocomplete-job-title-section'
                    filterOption={true}
                    onSelect={(value: string) => setTitle(value)}
                    onChange={(value) => {
                        const safeValue = value || "";
                        setTitle(safeValue.length <= 75 ? safeValue : titleInput);
                    }}
                    options={titleList}
                    placeholder={dictionary["creation-modal-page-one-placeholder-job-title"]}
                    value={titleInput}
                    variant='borderless'
                />
                <div className="char-counter">{`${titleInput.length}/75`}</div>
            </Col>
        </Row>
    )
}

export default JobTitleSection