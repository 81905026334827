import { Modal } from 'antd'
import React from 'react'
import { IPosition } from '../../../../models/hiring';
import FooterPreviewJobModal from './FooterPreviewJobModal';
import Header from './Header';
import './PreviewJobModal.css'
import PreviewJob from '../../../previewJob/PreviewJob';
interface IPreviewJobProps {
    position: IPosition;
    visible: boolean;
    setVisible: (value: boolean) => void;
    handleOnCloneJob: () => void;
}
const PreviewJobModal: React.FC<IPreviewJobProps> = ({ position, setVisible, visible, handleOnCloneJob }) => {
    return (
        <Modal
            open={visible}
            onCancel={() => setVisible(false)}
            destroyOnClose={true}
            centered
            closable={false}
            width='40vw'
            footer={
                <FooterPreviewJobModal handleOnCloneJob={handleOnCloneJob} />
            }
        >
            <div style={{ height: "80vh", width: "100%" }}>
                <Header setVisible={setVisible} />

                <div className='div-preview-job-modal-body'>
                    <PreviewJob position={position} />
                </div>
            </div>
        </Modal>
    )
}

export default PreviewJobModal