import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { CollectionsName } from "../../../models/collections";

import { db } from "../../config";
import { GetPositionCollectResponse, IRecommendationPrecandidate, UpdateRecommendationPrecandidateDocumentResponse } from "./type";
import { EErrorMessage } from "../error-types";
import { IPosition } from "../../../models/hiring";

export const updateRecommendationPrecandidateDocument = async (idHiring: string, idPosition: string, idPreCandidate: string, data: Partial<IRecommendationPrecandidate>): Promise<UpdateRecommendationPrecandidateDocumentResponse> => {
    try {
        await updateDoc(doc(db, CollectionsName.HIRINGS, idHiring, CollectionsName.POSITIONS, idPosition, CollectionsName.RECOMMENDATIONPRECANDIDATES, idPreCandidate), data);

        return {
            success: true,
            data: data,
        }
    } catch (error) {
        return {
            success: false,
            error: error instanceof Error ? error.message : 'Unknown error occurred',
        };
    }
}

export const getRecommendationCandidates = async (idHiring: string, idPosition: string) => {
    try {
        const positions = await getDocs(collection(db, CollectionsName.HIRINGS, idHiring, CollectionsName.POSITIONS, idPosition, CollectionsName.RECOMMENDATIONCANDIDATES));

        const data = positions.docs.map(doc => doc.data());

        if (data.length === 0) {
            return {
                success: false,
                error: EErrorMessage.NOT_COLLECT_DATA_FOUND
            }
        } else {
            return {
                success: true,
                data: data
            }
        }
    } catch (error) {
        return {
            success: false,
            error: error instanceof Error ? error.message : 'Unknown error occurred',
        };
    }
}

export const getRecommendationPrecandidates = async (idHiring: string, idPosition: string) => {
    try {
        const positions = await getDocs(collection(db, CollectionsName.HIRINGS, idHiring, CollectionsName.POSITIONS, idPosition, CollectionsName.RECOMMENDATIONPRECANDIDATES));

        const data = positions.docs.map(doc => doc.data());

        if (data.length === 0) {
            return {
                success: false,
                error: EErrorMessage.NOT_COLLECT_DATA_FOUND
            }
        } else {
            return {
                success: true,
                data: data
            }
        }
    } catch (error) {
        return {
            success: false,
            error: error instanceof Error ? error.message : 'Unknown error occurred',
        };
    }
}

export const getPositionCollect = async (idHiring: string): Promise<GetPositionCollectResponse> => {
    try {

        const positionCollectRef = collection(db, CollectionsName.HIRINGS, idHiring, CollectionsName.POSITIONS);

        const positionCollectsnap = await getDocs(positionCollectRef);

        const position = positionCollectsnap.docs.map(doc => doc.data() as IPosition);

        return {
            success: true,
            data: position,
        };
    } catch (error) {
        return {
            success: false,
            error: error instanceof Error ? error.message : 'Unknown error occurred',
        };
    }
}