//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Ant Design
import { Content } from "antd/lib/layout/layout";



// Firebase Tools
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase/config";

// Components


// Typescript
import { IPosition } from "../../models/hiring";

// Styles
import "./Menu.css";
import DashboardSidebarButton from "./buttons/dashboardSidebarButton/DashboardSidebarButton";
import SourcingTitle from "./texts/sourcingTitle/SourcingTitle";
import SourcingButton from "./buttons/sourcingButton/SourcingButton";
import Jobtitle from "./texts/jobTitle/Jobtitle";
import JobButton from "./buttons/jobButton/JobButton";
import AddJobButton from "./buttons/addJobButton/AddJobButton";

// Component
const Menu: React.FC = () => {

  // States
  const { user } = useSelector((state: any) => state.auth);
  const [positionList, setPositionList] = useState<IPosition[]>([]);
  const [positionSelected, setPositionSelected] = useState(0);

  // Handlers
  useEffect(() => {
    if (!user?.idHiring) return;

    const recommCandidateRef = collection(db, "hirings", user.idHiring, "positions");
    const recommCandidateQuery = query(recommCandidateRef, orderBy("title", "asc"));

    const unsubscribe = onSnapshot(recommCandidateQuery, (querySnapshot) => {
      const docs = querySnapshot.docs
        .map((doc) => doc.data() as IPosition)
        .filter((d: any) => d.status === "open" || d.status === "market");

      setPositionList(docs);
    });

    return () => unsubscribe();
  }, [user?.idHiring]);

  return (
    <Content className="contenedor container-menu-sidebar-web">
      <DashboardSidebarButton />

      <SourcingTitle />

      {positionList.map((position: IPosition, positionIndex: number) => (
        <SourcingButton
          key={position.idPosition}
          setPositionSelected={setPositionSelected}
          position={position}
          positionIndex={positionIndex}
          positionSelected={positionSelected}
        />
      ))}

      <Jobtitle />

      {positionList.map((position: IPosition, positionIndex: number) => (
        <JobButton
          key={positionIndex + 'jobButton'}
          position={position}
          positionIndex={positionIndex}
        />
      ))}

      <AddJobButton />
    </Content>
  );
};

// Export
export default Menu;
