import React, { useEffect, useState } from 'react'
import JobSummarySection from './jobSummarySection/JobSummarySection'

import ResponsibilitiesSection from './responsibilitiesSection/ResponsibilitiesSection'

import './SecondStep.css'

interface ISecondstepProps {
    description: string;
    setDescription: (description: string) => void;
    responsibilities: Array<{ index: number, value: string }>;
    setResponsibilities: (value: Array<{ index: number, value: string }>) => void;
}

const SecondStep: React.FC<ISecondstepProps> = ({ description, responsibilities, setDescription, setResponsibilities }) => {
    // States
    const [responsibilitiesText, setResponsibilitiesText] = useState<string>('');

    // Inicializa el texto desde la lista al montar
    useEffect(() => {
        const text = responsibilities.map(item => item.value).join('\n');
        setResponsibilitiesText(text);
    }, [responsibilities]);

    // Cuando se pierde el foco, parseamos y seteamos
    const handleResponsibilitiesBlur = () => {
        const lines = responsibilitiesText
            .split('\n')
            .map(line => line.trim())
            .filter(line => line.length > 0);

        const parsedResponsibilities = lines.map((line, index) => ({
            index,
            value: line
        }));

        setResponsibilities(parsedResponsibilities);
    };

    return (
        <div className="container-second-step-creation-modal">
            <JobSummarySection
                description={description}
                setDescription={setDescription}
            />

            <ResponsibilitiesSection
                responsibilitiesText={responsibilitiesText}
                setResponsibilitiesText={setResponsibilitiesText}
                onBlur={handleResponsibilitiesBlur}
            />
        </div>
    )
}

export default SecondStep
