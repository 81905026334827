//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import React, { useEffect, useState } from 'react'
import { Checkbox, Row } from 'antd';
import { useSelector } from 'react-redux';
//Interface
interface Props {
    saveWorkplace: (arrayString: Array<string>) => void
    dataWorkplace: Array<string>
}

//Component
const WorkplaceCheckBox: React.FC<Props> = ({ saveWorkplace, dataWorkplace }) => {

    // States
    const [statusOnSite, setStatusOnsite] = useState<boolean>(false)
    const [statusRemote, setStatusRemote] = useState<boolean>(false)
    const [statusHybrid, setStatusHybrid] = useState<boolean>(false);

    const { dictionary } = useSelector((state: any) => state.ui);


    useEffect(() => {
        const arrayString = []
        if (statusOnSite === true) {
            arrayString.push("On-Site")
        }
        if (statusRemote === true) {
            arrayString.push("Remote")
        }
        if (statusHybrid === true) {
            arrayString.push("Hybrid")
        }
        saveWorkplace(arrayString);
    }, [statusOnSite, statusRemote, statusHybrid, saveWorkplace])

    useEffect(() => {
        if (dataWorkplace === undefined) {
            return
        }
        dataWorkplace.forEach((element: string) => {
            if (element === "On-Site") {
                setStatusOnsite(true)
            }
            if (element === "Remote") {
                setStatusRemote(true)
            }
            if (element === "Hybrid") {
                setStatusHybrid(true)
            }
        });
    }, [dataWorkplace])

    return (

        <Row style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <Checkbox
                className='custom-checkbox'
                checked={statusOnSite}

                onChange={() => setStatusOnsite(!statusOnSite)}
            >
                {dictionary['creation-modal-page-one-checkbox-on-site']}
            </Checkbox>

            <Checkbox
                className='custom-checkbox'
                checked={statusRemote}
                onChange={() => setStatusRemote(!statusRemote)}
            >
                {dictionary['creation-modal-page-one-checkbox-remote']}
            </Checkbox>

            <Checkbox
                className='custom-checkbox'
                checked={statusHybrid}
                onChange={() => setStatusHybrid(!statusHybrid)}
            >
                {dictionary['creation-modal-page-one-checkbox-hybrid']}
            </Checkbox>


        </Row>


    )
}

//Export
export default WorkplaceCheckBox;