import React, { useState } from 'react'
import { Button } from 'antd'
import { useSelector } from 'react-redux';
import PreviewJobModal from '../../modals/PreviewJobModal';

interface IPreviewJobButtonProps {
    positionSelected: any;
    valueHourForWeek: number;
    handleOnCloneJob: (value: any) => void;
}
const PreviewJobButton: React.FC<IPreviewJobButtonProps> = ({ handleOnCloneJob, positionSelected, valueHourForWeek }) => {

    const [visible, setVisible] = useState(false);
    const { dictionary } = useSelector((state: any) => state.ui);

    return (
        <>
            <Button
                className="button-orange-v1"
                onClick={() => setVisible(true)}
            >
                {dictionary["creation-modal-initial-page-button-preview"]}

            </Button>



            <PreviewJobModal
                handleOnCloneJob={() => { handleOnCloneJob(positionSelected); setVisible(false) }}
                position={positionSelected}
                setVisible={setVisible}
                visible={visible}

            />
        </>
    )
}

export default PreviewJobButton