import { Col, Row, Select, Typography } from 'antd';
import React from 'react'
import { useSelector } from 'react-redux';
import './UpdateSectionJob.css'
import { DownOutlined } from '@ant-design/icons';
interface IUpdateSectionJob {
    updatePositionStatus: (status: "open" | "close") => void;
}
const UpdateSectionJob: React.FC<IUpdateSectionJob> = ({ updatePositionStatus }) => {
    const { dictionary } = useSelector((state: any) => state.ui);
    return (
        <Row className='row-section-update-job'>
            <Col
                className='col-label-section-update-job'
                xs={24}
                sm={6}
                md={5}
                lg={4}
            >
                <Typography.Text className='title'>
                    {dictionary["editing-modal-status"]}
                </Typography.Text>
            </Col>

            <Col xs={24} sm={18} md={18} lg={20} className='col-select'>
                <Select
                    className='select-section-update-job'
                    defaultValue="open"
                    onSelect={(value: any) => {
                        updatePositionStatus(value);
                    }}
                    variant='borderless'
                    suffixIcon={<DownOutlined className='icon-select-button' />}
                    options={[
                        {
                            value: "open",
                            label: dictionary["editing-modal-status-open"],
                        },
                        {
                            value: "close",
                            label: dictionary["editing-modal-status-closed"],
                        },
                    ]}
                />
            </Col>
        </Row>
    )
}

export default UpdateSectionJob