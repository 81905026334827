import React, { useEffect, useState } from 'react'
import './SourcingButton.css'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../../../firebase/config';
import { CollectionsName } from '../../../../models/collections';
import { Col, Row, Tooltip, Typography } from 'antd';
import { assets } from '../../../../assets/assets';
import { IPosition } from '../../../../models/hiring';
import { clearFilterJob, clearMessageList, clearPositionList, clearPositionSelect, clearUserChatSelected } from '../../../../store/chat';
import { Dispatch } from 'redux';
import { clearPositionSideBarSelected, getPositionSideBarSelected } from '../../../../store/ui';
import { clearUserSelected } from '../../../../store/job';

// Interface
interface Props {

    positionSelected: number;
    positionIndex: number;
    position: IPosition;
    setPositionSelected: (positionIndex: any) => void;
}

const SourcingButton: React.FC<Props> = ({ positionSelected, positionIndex, position, setPositionSelected }) => {

    // States
    const user = useSelector((state: any) => state.auth.user);
    const [counterRecommendation, setCounterRecommendation] = useState<number>(0);
    const [counterPreRecommendation, setCounterPreRecommendation] = useState<number>(0);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();
    const totalRecommendations = counterRecommendation + counterPreRecommendation;

    const startResetDataMessage = () => {
        dispatch(clearPositionSelect());
        dispatch(clearPositionList());
        dispatch(clearMessageList());
        dispatch(clearUserChatSelected());
        dispatch(clearFilterJob());
    }

    const startResetDataJobList = () => {
        dispatch(clearPositionSideBarSelected());
        dispatch(clearUserSelected());
    }

    const handleOnSelectPosition = () => {
        location.pathname === "/messages" && startResetDataMessage();
        location.pathname === "/jobList" && startResetDataJobList();

        setPositionSelected(positionIndex);
        dispatch(getPositionSideBarSelected(position));
        location.pathname !== "/recommendation" && navigate("/recommendation");
    }

    // Effects
    useEffect(() => {
        if (!user?.idHiring || !position.idPosition) {
            return;
        }

        setCounterRecommendation(0); // Reiniciar el contador
        setCounterPreRecommendation(0); // Reiniciar el contador de pre-recomendaciones

        // Referencia y consulta para recommendationCandidates
        const recommCandidateRef = collection(db, 'hirings', user.idHiring, 'positions', position.idPosition, 'recommendationCandidates');
        const recommendationCandidatesQuery = query(recommCandidateRef, where('like', '==', ''));

        // Referencia y consulta para recommendationPreCandidates
        const recommPreCandidateRef = collection(db, CollectionsName.HIRINGS, user.idHiring, CollectionsName.POSITIONS, position.idPosition, CollectionsName.RECOMMENDATIONPRECANDIDATES);
        const recommendationPreCandidatesQuery = query(recommPreCandidateRef, where('hiringLike', '==', null));

        // Observador para recommendationCandidates
        const unsubscribeRecommendations = onSnapshot(recommendationCandidatesQuery, (snapshot) => {
            const recommendationCount = snapshot.docs.length;
            setCounterRecommendation(recommendationCount);
        });

        // Observador para recommendationPreCandidates
        const unsubscribePreRecommendations = onSnapshot(recommendationPreCandidatesQuery, (snapshot) => {
            const preRecommendationCount = snapshot.docs.length;
            setCounterPreRecommendation(preRecommendationCount);
        });

        return () => {
            unsubscribeRecommendations();
            unsubscribePreRecommendations();
        };
    }, [position.idPosition, user?.idHiring]);


    return (
        <Row
            onClick={handleOnSelectPosition}
            className={`row-container-button-sourcing-sidebar 
                ${location.pathname === "/recommendation" && positionIndex === positionSelected
                    ?
                    "selected-sourcing" :
                    "default-sourcing"}`}
            key={positionIndex + 4}
        >

            <Col span={3} offset={2} className='col-image-sourcing' >
                <img src={assets.iconDot} style={{ height: 13, width: 13, }} alt="icon-dot" />
            </Col>

            <Col span={14}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title={position.title}>
                        <Typography.Text
                            className={`font-project text-sourcing-button ${location.pathname === "/recommendation" && positionIndex === positionSelected ? "text-selected-sourcing" : "text-default-sourcing"}`}
                            key={positionIndex + 5}
                        >
                            {position.title}
                        </Typography.Text>
                    </Tooltip>
                </div>
            </Col>

            <Col span={5} className='col-counter-sourcing-button'>
                {totalRecommendations > 0 && (
                    <div className='div-counter-sourcing-button'>
                        <Typography.Text className="font-project text-counter-sourcing-button" >
                            {totalRecommendations}
                        </Typography.Text>
                    </div>
                )}
            </Col>
        </Row>
    )
}

export default SourcingButton