import React, { useEffect, useState } from 'react'
import RequirementSection from './requirementsSection/RequirementSection';
import SkillSection from './skillSection/SkillSection';
import './ThridStep.css'

interface IThridstepProps {
    requirements: Array<any>;
    setRequirements: (value: Array<any>) => void;
    skills: Array<any>;
    setSkills: (value: Array<any>) => void;
}

const ThridStep: React.FC<IThridstepProps> = ({ requirements, setRequirements, setSkills, skills }) => {

    const [requirementsText, setRequirementsText] = useState<string>('');
    useEffect(() => {
        const text = requirements.map(item => item.value).join('\n');
        setRequirementsText(text);
    }, [requirements]);

    // Cuando se pierde el foco, parseamos y seteamos
    const handleRequirementsBlur = () => {
        const lines = requirementsText.split('\n').map(line => line.trim()).filter(line => line.length > 0);

        const parsedRequirements = lines.map((line, index) => ({ index, value: line }));

        setRequirements(parsedRequirements);
    };

    return (
        <div className="container-thrid-step-creation-modal">
            <RequirementSection
                requirementText={requirementsText}
                setRequirementText={setRequirementsText}
                onBlur={handleRequirementsBlur}
            />

            <SkillSection setSkills={setSkills} skills={skills} />
        </div>
    )
}

export default ThridStep
