import React, { Dispatch, Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../firebase/config";
import { Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";

import RecommendationScreen from "../pages/RecommendationScreen";
import SupportScreen from "../pages/SupportScreen";
import PeerRecommendationScreen from "../pages/PeerRecommendationScreen";
import JobsListScreen from "../pages/JobsListScreen";
import ProfileScreen from "../pages/ProfileScreen";
import ChatScreen from "../pages/ChatScreen";
import WelcomeModal from "../common/modals/welcomeModal/WelcomeModal";
import CreateJobModal from "../common/modals/createJobModal/CreateJobModal";
import UpdateUserInfoModal from "../common/modals/updateProfileModal/UpdateUserInfoModal";
import "./scroll.css"; // Ruta relativa al archivo CSS
import LogoutInactiveModal from "../common/modals/LogoutInactiveModal";
import { logoutFirebase } from "../firebase/providers";
import { logout } from "../store/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { StateGeneralRedux } from "../types/redux";
import User from "../types/user";
import ChangePasswordPage from "../pages/changePasswordPage/ChangePasswordPage";
import NavBar from "../common/navBar/NavBar";
import './JobManagerRoutes.css';
import LoadingPage from "../common/LoadingPage";
import Menu from "../common/SideBar/Menu";

const HomePage = React.lazy(() => import("../pages/homePage/HomePage"));
// Component
const JobManagerRoutes = () => {
  // States
  const dispatch: Dispatch<any> = useDispatch();
  const { user } = useSelector((state: StateGeneralRedux) => state.auth);

  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);
  const [visibleWelcomeModal, setVisibleWelcomeModal] = useState<boolean>(false);
  const [visibleCreateJobModal, setVisibleCreateJobModal] = useState<boolean>(false);
  const [visibleUpdateProfileModal, setVisibleUpdateProfileModal] = useState<boolean>(false);
  const [visibleLogoutInactiveModal, setVisibleLogoutInactiveModal] = useState<boolean>(false);
  const [timeInSeconds, setTimeInSeconds] = useState<number>(0);

  const saveTimeInSeconds = () => {
    const currentTime = new Date();
    const seconds =
      currentTime.getHours() * 3600 +
      currentTime.getMinutes() * 60 +
      currentTime.getSeconds();
    localStorage.setItem("timeInSeconds", seconds.toString());
    setTimeInSeconds(seconds);
  };

  const saveTimeInSecondsActually = () => {
    const currentTime = new Date();
    const seconds =
      currentTime.getHours() * 3600 +
      currentTime.getMinutes() * 60 +
      currentTime.getSeconds();
    return seconds;
  };

  // Effects
  useEffect(() => {
    // Valida si user es null y detiene la ejecución del efecto si lo es
    if (!user) return;

    const unsubscribe = onSnapshot(doc(db, "hirings", user.idHiring), (hiringDocSnap) => {
      if (hiringDocSnap.exists()) {
        const hiringData = hiringDocSnap.data() as User;
        if (!hiringData.firstLogin) {
          setIsFirstLogin(false);
          setVisibleUpdateProfileModal(false);
        } else {
          setIsFirstLogin(true);
          setVisibleUpdateProfileModal(true);
        }
      }
    });

    // Función de limpieza que se llama cuando el componente se desmonta o cuando `user` cambia
    return () => unsubscribe();
  }, [user]);

  // Effects
  useEffect(() => {
    let inactivityTimer: any;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(handleInactive, 20 * 60 * 1000); // 15 minutes
    };

    const handleInactive = () => {
      !visibleLogoutInactiveModal && saveTimeInSeconds();

      setVisibleLogoutInactiveModal(true);
      clearTimeout(inactivityTimer);
    };

    const handleUserActivity = () => {
      if (!visibleLogoutInactiveModal) {
        localStorage.setItem("timeInSeconds", "0");
        resetTimer();
      }
    };

    // Agregar eventos relevantes
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);
    document.addEventListener("scroll", handleUserActivity);

    // Establecer el temporizador inicial
    resetTimer();

    // Limpiar los eventos al desmontar el componente
    return () => {
      clearTimeout(inactivityTimer);
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      document.removeEventListener("scroll", handleUserActivity);
    };
  }, [dispatch, visibleLogoutInactiveModal]);

  useEffect(() => {
    // Get the value from localStorage when the component mounts
    const storedTimeInSeconds: any = localStorage.getItem("timeInSeconds");

    // Convert the stored value to a number and update the state
    const valueStorageUserInactive = parseInt(storedTimeInSeconds, 10) || 0;
    if (valueStorageUserInactive > 0) {
      const valueActuallyUserTime = saveTimeInSecondsActually();

      const result = valueActuallyUserTime - valueStorageUserInactive;
      if (result > 180) {
        localStorage.setItem("timeInSeconds", "0");
        dispatch(logout());
        logoutFirebase();
      }
    }
  }, [dispatch]);

  return (
    <>
      {user?.forceChangePassword ? (
        <Routes>
          <Route
            path="/"
            element={<ChangePasswordPage setChangePassword={setChangePassword} />}
          />
        </Routes>
      ) : (
        <Content style={{ height: "100vh", width: "100vw" }}>
          {visibleLogoutInactiveModal && (
            <LogoutInactiveModal
              visible={visibleLogoutInactiveModal}
              setVisible={(value: boolean) =>
                setVisibleLogoutInactiveModal(value)
              }
              timeInactiveUser={timeInSeconds}
            />
          )}
          <NavBar />
          {isFirstLogin ? (
            <UpdateUserInfoModal
              modalUser={visibleUpdateProfileModal}
              setModalUser={setVisibleUpdateProfileModal}
              setVisibleWelcomeModal={setVisibleWelcomeModal}
            />
          ) : (
            <Row className="container-general-route">
              <WelcomeModal visibleModal={visibleWelcomeModal} setVisibleModal={setVisibleWelcomeModal} setVisibleCreateJobModal={setVisibleCreateJobModal} />
              {user && (<CreateJobModal closeModal={() => setVisibleCreateJobModal(false)} user={user} visibleModal={visibleCreateJobModal} />)}
              <Col xs={{ span: 7 }} sm={{ span: 6 }} lg={{ span: 3 }}>
                <Menu />
              </Col>

              <Col
                style={{ height: "100%", width: "100%", }}
                xs={17}
                sm={18}
                md={18}
                lg={21}
                xl={21}
                xxl={21}
              >
                <Suspense fallback={<LoadingPage />}>
                  <Routes>
                    <Route path="/dash" element={<HomePage changePassword={changePassword} />} />
                    <Route path="/recommendation" element={<RecommendationScreen />} />
                    <Route path="/support" element={<SupportScreen />} />
                    <Route path="/profile" element={<ProfileScreen />} />
                    <Route path="/jobList" element={<JobsListScreen />} />
                    <Route path="/peerRecommendation" element={<PeerRecommendationScreen />} />
                    <Route path="/messages" element={<ChatScreen />} />
                    <Route path="/" element={<Navigate to="/dash" />} />
                  </Routes>
                </Suspense>

              </Col>
            </Row>
          )}
        </Content>
      )}
    </>
  );
};

export default JobManagerRoutes;
