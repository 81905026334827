//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import CandidateTab from "./candidateTab/CandidateTab";
import JobDescriptionTab from "./jobDescriptionTab/JobDescriptionTab";
import { useSelector } from "react-redux";
import { Content } from "antd/lib/layout/layout";
import { StateGeneralRedux } from "../../types/redux";

// Component
const JobPosition: React.FC = () => {
  // States
  const [statusTab, setStatusTab] = useState(true);
  const { Text } = Typography;

  const { position, dictionary } = useSelector((state: StateGeneralRedux) => state.ui);

  return (
    <Content style={{ height: "100%", width: "100%" }}>
      <Row style={{ width: "100%" }}>
        <Col
          xs={24}
          sm={24}
          md={7}
          lg={{ span: 5 }}
          xl={{ span: 4 }}
          xxl={{ span: 3 }}
          style={{ paddingLeft: 4, paddingRight: 4, paddingTop: 4 }}
        >
          <Button
            onClick={() => !statusTab && setStatusTab(true)}
            style={{
              backgroundColor: statusTab ? "#24cece" : "#afafaf",
              boxShadow: statusTab ? "1px 1px 5px #00000060" : "none",
              width: "100%",
              borderRadius: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "none",
              outline: "none",
            }}
          >
            <Text
              style={{
                color: !statusTab ? "#4e4e4eb5" : "black",
                fontSize: "1rem",
                fontFamily: "Mulish",
              }}
            >
              {dictionary["job-tab-title-candidate"]}
            </Text>
          </Button>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={7}
          lg={{ span: 5 }}
          xl={{ span: 4 }}
          xxl={{ span: 3 }}
          style={{ paddingLeft: 4, paddingRight: 4, paddingTop: 4 }}
        >
          <Button
            onClick={() => statusTab && setStatusTab(false)}
            style={{
              backgroundColor: !statusTab ? "#24cece" : "#afafaf",
              boxShadow: !statusTab ? "1px 1px 5px #00000060" : "none",
              width: "100%",
              borderRadius: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "none",
              outline: "none",
            }}
          >
            <Text
              style={{
                color: statusTab ? "#4e4e4eb5" : "black",
                fontSize: "1rem",
                fontFamily: "Mulish",
              }}
            >
              {dictionary["job-tab-title-description"]}
            </Text>
          </Button>
        </Col>
      </Row>

      <Row style={{ height: "95%", width: "100%" }}>
        {statusTab ? (
          <CandidateTab />
        ) : (
          <JobDescriptionTab jobPositionData={position} />
        )}
      </Row>
    </Content>
  );
};
// Export
export default JobPosition;
