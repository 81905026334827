//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React from "react";
import UpdateSectionJob from "./updateSectionJob/UpdateSectionJob";
import JobTitleSection from "./jobTitleSection/JobTitleSection";
import LocationSection from "./locationSection/LocationSection";
import WorkplaceSection from "./workplaceSection/WorkplaceSection";
import SalarySection from "./salarySection/SalarySection";
import BonusSection from "./bonusSection/BonusSection";
import SalaryDisplaySection from "./salaryDisplaySection/SalaryDisplaySection";
import TotalCompensationSection from "./totalCompensationSection/TotalCompensationSection";
import OtherDetailCompensationSection from "./otherDetailCompensationSection/OtherDetailCompensationSection";
import WorkCondition from "./workCondition/WorkCondition";

import { formatNumber } from "../../../../../hooks/hooks";

import './FirstStepCreationJob.css'
//Interface
interface Props {
  titleInput: string;
  setTitle: (title: string) => void;
  type: Array<string>;
  setType: (typeList: Array<string>) => void;
  location: any;
  setLocation: (location: any) => void;
  workplace: Array<string>;
  setWorkplace: (workplace: Array<string>) => void;
  setSalary: (salary: string) => void;
  setPlaces: (place: any) => void;
  salary: string;
  totalSalary: string;
  salaryMax: string;
  setSalaryMax: (salaryMax: string) => void;
  setTotalSalary: (value: string) => void;
  checkHourly: boolean;
  setCheckHourly: (value: boolean) => void;
  checkAnnual: boolean;
  setCheckAnnual: (value: boolean) => void;
  checkAmount: boolean;
  setCheckAmount: (value: boolean) => void;
  checkMin: boolean;
  setCheckMin: (value: boolean) => void;
  checkMax: boolean;
  setCheckMax: (value: boolean) => void;
  checkMiddle: boolean;
  setCheckMiddle: (value: boolean) => void;
  checkBonnus: boolean;
  setCheckBonnus: (value: boolean) => void;
  checkPercentage: boolean;
  setCheckPercentage: (value: boolean) => void;
  update: boolean;
  updatePositionStatus: (status: "open" | "close") => void;
  otherDetailsCompensation: string;
  setOtherDetailsCompensation: (value: string) => void;
  valueHourForWeek: number;
  setValueHourForWeek: (value: any) => void;
}

// Component
const FirstStepCreationJob: React.FC<Props> = ({
  titleInput,
  setTitle,
  setType,
  type,
  location,
  setPlaces,
  setLocation,
  workplace,
  setWorkplace,
  setSalary,
  salary,
  checkHourly,
  setCheckHourly,
  checkMin,
  setCheckMin,
  checkMax,
  setCheckMax,
  checkMiddle,
  setCheckMiddle,
  checkBonnus,
  setCheckBonnus,
  setSalaryMax,
  salaryMax,
  checkAmount,
  setCheckAmount,
  checkAnnual,
  setCheckAnnual,
  checkPercentage,
  setCheckPercentage,
  totalSalary,
  setTotalSalary,
  update,
  updatePositionStatus,
  otherDetailsCompensation,
  setOtherDetailsCompensation,
  setValueHourForWeek,
  valueHourForWeek,
}) => {
  return (
    <div className="container-first-step-creation-modal">
      {update && <UpdateSectionJob updatePositionStatus={updatePositionStatus} />}

      <JobTitleSection setTitle={setTitle} titleInput={titleInput} />

      <LocationSection location={location} setLocation={setLocation} setPlaces={setPlaces} />

      <WorkplaceSection setWorkplace={setWorkplace} workplace={workplace} />

      <SalarySection
        salary={formatNumber(salary)}
        setSalary={setSalary}
        checkAnnual={checkAnnual}
        checkHourly={checkHourly}
        salaryMax={formatNumber(salaryMax)}
        setMaxSalary={setSalaryMax}
        setCheckHourly={setCheckHourly}
        setCheckAnnual={setCheckAnnual}
      />

      <BonusSection
        bonus={formatNumber(totalSalary)}
        setTotalSalary={setTotalSalary}
        checkAmount={checkAmount}
        checkPercentage={checkPercentage}
        setCheckAmount={setCheckAmount}
        setCheckPercentage={setCheckPercentage}
        setValueHourForWeek={setValueHourForWeek}
        valueHourForWeek={valueHourForWeek}
        checkHourly={checkHourly}
      />

      <SalaryDisplaySection
        checkBonnus={checkBonnus}
        checkMax={checkMax}
        checkMiddle={checkMiddle}
        checkMin={checkMin}
        setCheckBonnus={setCheckBonnus}
        setCheckMax={setCheckMax}
        setCheckMiddle={setCheckMiddle}
        setCheckMin={setCheckMin}
      />

      <TotalCompensationSection
        checkAmount={checkAmount}
        checkAnnual={checkAnnual}
        checkHourly={checkHourly}
        checkBonnus={checkBonnus}
        checkMax={checkMax}
        checkMiddle={checkMiddle}
        checkMin={checkMin}
        checkPercentage={checkPercentage}
        salary={salary}
        salaryMax={salaryMax}
        totalSalary={totalSalary}
        valueHourForWeek={valueHourForWeek}
      />

      <OtherDetailCompensationSection otherDetailsCompensation={otherDetailsCompensation} setOtherDetailsCompensation={setOtherDetailsCompensation} />

      <WorkCondition setType={setType} type={type} />
    </div>
  );
};

// Exports
export default FirstStepCreationJob;
