import { Col, Input, InputNumber, Radio, Row, Typography } from 'antd'
import React from 'react'
import './BonusSection.css'
import { useSelector } from 'react-redux';


interface IBonusSectionProps {
    bonus: number;
    setTotalSalary: (salary: string) => void;
    checkPercentage: boolean;
    setCheckPercentage: (checkPercentage: boolean) => void;
    checkAmount: boolean;
    setCheckAmount: (checkAmount: boolean) => void;
    valueHourForWeek: number;
    setValueHourForWeek: (value: any) => void;
    checkHourly: boolean;

}
const BonusSection: React.FC<IBonusSectionProps> = ({ bonus, setTotalSalary, checkAmount, checkPercentage,
    setCheckAmount, setCheckPercentage, setValueHourForWeek, valueHourForWeek, checkHourly
}) => {
    const { dictionary } = useSelector((state: any) => state.ui);

    const onChangeAmount = () => {
        setCheckAmount(!checkAmount);
        checkPercentage === true && setCheckPercentage(false);
    };

    const onChangePercentage = () => {
        setCheckPercentage(!checkPercentage);
        checkAmount === true && setCheckAmount(false);
    };
    return (
        <Row className='row-section-bonus-job '>
            {/* Title Salary */}
            <Col className='col-label-section-bonus-job'
                xs={{ span: 23 }} md={6} lg={5} xl={4}
            >
                <Typography.Text className='label-bonus-section'>
                    {dictionary["creation-modal-page-one-field-bonus"]}{" "}*
                </Typography.Text>
            </Col>

            {/* Input salary */}
            <Col
                className='col-input'
                xs={{ span: 23, offset: 1 }} sm={{ offset: 0 }} md={7} lg={9} xl={3}
            >
                <Input
                    className='input-job-bonus-section'
                    value={bonus}
                    maxLength={8}
                    size="large"
                    placeholder={
                        dictionary["creation-modal-page-one-placeholder-bonus"]
                    }
                    allowClear
                    variant='borderless'
                    onChange={(e) => setTotalSalary(e.target.value)}
                />
            </Col>

            <Col
                className='col-checkbox'
                xs={{ span: 23, offset: 1 }}
                sm={{ span: 11, offset: 1 }}
                md={{ span: 6, offset: 6 }}
                lg={{ span: 6, offset: 5 }}
                xl={{ span: 4, offset: 1 }}
            >
                <Radio
                    className="custom-radio-purple label-salary-section"
                    checked={checkPercentage}
                    onChange={onChangePercentage}
                >
                    {dictionary["creation-modal-page-one-checkbox-percentage"]}
                </Radio>
            </Col>

            <Col
                className='col-checkbox'
                xs={{ span: 23, offset: 1 }}
                sm={{ span: 11, offset: 1 }}
                md={{ span: 5, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 4, offset: 0 }}
            >
                <Radio
                    className="custom-radio-purple label-salary-section"
                    checked={checkAmount}
                    onChange={onChangeAmount}
                >
                    {dictionary["creation-modal-page-one-checkbox-ammount"]}
                </Radio>
            </Col>

            {
                checkHourly && (
                    <>


                        <Col
                            className='col-checkbox'
                            xs={{ span: 23, offset: 1 }}
                            sm={{ span: 11, offset: 1 }}
                            md={{ span: 9, offset: 6 }}
                            lg={{ span: 6, offset: 5 }}
                            xl={{ span: 4, offset: 0 }}
                        >
                            <Typography.Text className='label-bonus-section'>
                                {dictionary["creation-modal-page-one-placeholder-other-details-hour-for-week"]}{" "}*
                            </Typography.Text>
                        </Col>

                        <Col
                            className='col-checkbox'
                            xs={{ span: 23, offset: 1 }}
                            sm={{ span: 10, offset: 1 }}
                            md={{ span: 5, offset: 1 }}
                            lg={{ span: 5, offset: 1 }}
                            xl={{ span: 2, offset: 0 }}
                        >
                            <InputNumber
                                value={valueHourForWeek}
                                variant='borderless'
                                min={1}
                                max={45}
                                className='input-job-bonus-section'
                                onChange={(e) => setValueHourForWeek(e)}
                            />
                        </Col>
                    </>
                )
            }
        </Row>
    )
}

export default BonusSection