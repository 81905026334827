import React from 'react';
import { Typography } from 'antd';
import workIcon from '../../assets/svg/working-team.svg'
import './SourcingLoading.css';
interface SourcingLoadingProps {
    dictionary: { [key: string]: string };
}

const SourcingLoading: React.FC<SourcingLoadingProps> = ({ dictionary }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', boxSizing: 'border-box', padding: '10px' }}>
            <div className='data-not-found-card'>
                <img src={workIcon} alt="Download for iOS" className='icon-work-ant' />

                <Typography.Text className='font-project color-text'>
                    {dictionary['sourcing-loading-text']}
                </Typography.Text>
            </div>

        </div>
    );
};

export default SourcingLoading;
