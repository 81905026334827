//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import { Button, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import ChangeLanguajeButton from "./buttons/changeLanguajeButton/ChangeLanguajeButton";
import SettingsButton from "./buttons/settingsButton/SettingsButton";
import MessageButton from "./buttons/messageButton/MessageButton";
import LogoutButton from "./buttons/logoutButton/LogoutButton";
import iconIRGTLogoWhite from "../../image/navbar/logo-CS-horizontal_white-TM.png";
import { assets } from "../../assets/assets";

//Components
const NavBar: React.FC = () => {
  //States
  const navigate = useNavigate();

  return (
    <Menu
      style={{ backgroundColor: "#6052A3", height: "60px", width: "100%" }}
      mode="horizontal"
    >

      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ height: '100%', flexDirection: 'row', display: 'flex', alignItems: 'center', boxSizing: 'border-box', paddingLeft: '20px' }}>
          <img
            alt="icon Irgt"
            src={iconIRGTLogoWhite}
            style={{ height: "30px", width: "auto", cursor: "pointer" }}
            onClick={() => navigate("/dash")}
          />

        </div>

        <div style={{ height: '100%', display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', boxSizing: 'border-box', paddingRight: '20px' }}>
          <ChangeLanguajeButton />

          <SettingsButton />

          <Button
            onClick={() => navigate("/profile")}
            style={{
              borderColor: "transparent",
              backgroundColor: "transparent",
            }}
            icon={
              <img
                src={assets.iconUser}
                style={{ height: 25, width: "auto" }}
                alt={assets.iconUser}
              />
            }
          />

          <MessageButton />

          <LogoutButton />
        </div>
      </div>
    </Menu>
  );
};

//Export
export default NavBar;
