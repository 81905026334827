//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Button, Col, Row, Select, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { db } from "../../../../../firebase/config";
import { Job } from '../../../../../types/position';
import { collection, getDocs } from 'firebase/firestore';
import './index.css'
import { IPosition } from '../../../../../models/hiring';
import FloatingPositionCard from './FloatingPositionCard';
//Interface
interface Props {
    handleOnNextStep: (actualStep: 'Initial' | 'First Step' | 'Second Step' | 'Third Step') => void;
    setTitle: (title: string) => void;
    setType: (typeList: Array<string>) => void;
    setLocation: (location: string) => void;
    setSalary: (salary: string) => void;
    setSalaryMax: (salaryMax: string) => void;
    setTotalSalary: (salary: string) => void;
    setWorkplace: (workplace: Array<string>) => void;
    setDescription: (description: string) => void;
    setResponsibilities: (value: Array<any>) => void;
    setRequirements: (value: Array<any>) => void;
    setSkills: (value: Array<any>) => void;
    checkHourly: boolean;
    setCheckHourly: (value: boolean) => void;
    checkAnnual: boolean;
    setCheckAnnual: (value: boolean) => void;
    checkAmount: boolean;
    setCheckAmount: (value: boolean) => void;
    checkPercentage: boolean;
    setCheckPercentage: (value: boolean) => void;
    setOtherDetailsCompensation: (value: string) => void;
    otherDetailsCompensation: string;
    setValueHourForWeek: (value: number) => void;
    valueHourForWeek: number;
}

// Component
const { Text } = Typography;
const InitialStepCreationJob: React.FC<Props> = (
    {
        handleOnNextStep, setTitle,
        setType,
        setLocation,
        setSalary,
        setSalaryMax,
        setWorkplace,
        setDescription,
        setResponsibilities,
        setRequirements,
        setSkills,
        setCheckHourly,
        setCheckAmount,
        setCheckAnnual,
        setCheckPercentage,
        setTotalSalary,
        otherDetailsCompensation,
        setOtherDetailsCompensation,
        valueHourForWeek,
        setValueHourForWeek
    }) => {

    //States
    const user = useSelector((state: any) => state.auth.user);
    const { dictionary } = useSelector((state: any) => state.ui);
    const [positionList, setPositionList] = useState<Array<Job>>([]);
    const [positionSelectList, setPositionSelectList] = useState<Array<any>>([]);
    const [positionSelected, setPositionSelected] = useState<IPosition>({} as IPosition);
    const [defaultValueSelect, setDefaultValueSelect] = useState<string>(dictionary['creation-modal-initial-page-button-select-job'])
    const [positionSelectedTemplate, setPositionSelectedTemplate] = useState<any>({});


    const getPositionList = useCallback(async (idHiring) => {
        try {
            const positionList: any[] = [];
            const positionListSelected: any[] = [];

            const positionsCollectionRef = collection(db, 'hirings', idHiring, 'positions');
            const positionsCollect = await getDocs(positionsCollectionRef);

            positionsCollect.forEach((each) => {
                const idPosition = each.data().idPosition?.trim();
                const title = each.data().title?.trim();

                positionList.push({
                    value: idPosition,
                    label: title
                });

                positionListSelected.push({ ...each.data(), idPosition, title });
            });

            // Ordenar la lista alfabéticamente por 'label' (título)
            positionList.sort((a, b) => a.label.localeCompare(b.label));
            positionListSelected.sort((a, b) => a.title.localeCompare(b.title));

            setPositionList(positionList);
            setPositionSelectList(positionListSelected);

        } catch (error) {
            console.error("Error in getPositionList:", error);
        }
    }, []);

    const handleOnSelectJob = (idHiring: string, positionList: Array<any>) => {
        const searchPosition = positionList.find((position: any) => position.idPosition === idHiring);
        setDefaultValueSelect(searchPosition.title)
        searchPosition !== undefined && setPositionSelected(searchPosition);
        positionSelectedTemplate && setPositionSelectedTemplate({});
    };


    const handleOnCloneJob = (position: Job) => {
        setTitle(position?.title ? position?.title : "");
        setOtherDetailsCompensation(position?.otherDetailsCompensation ? position?.otherDetailsCompensation : "");
        setLocation(position.location);
        setType(position.jobType);
        setWorkplace(position.workplace);
        setDescription(position.description);
        setResponsibilities(position.responsibilities);
        setRequirements(position.technicalRequirements);
        setSkills(position.skills);
        setValueHourForWeek(position.hourForWeek ? position.hourForWeek : 40);
        if (position.salary !== undefined && position.salary !== "") {
            setSalary(position.salary);
            if (position.hourly !== undefined) {
                setCheckHourly(position.hourly);
            }
            if (position.annual !== undefined) {
                setCheckAnnual(position.annual);
            }
        }
        if (position.salaryMax !== undefined && position.salaryMax.length > 0 && position.salary !== '0') {
            setSalaryMax(position.salaryMax)
        } else {
            setSalaryMax("")
        }
        if (position.totalSalary !== undefined && position.totalSalary.length > 0 && position.totalSalary !== '0') {
            setTotalSalary(position?.totalSalary);
            if (position.percentage !== undefined) {
                setCheckPercentage(position.percentage);
            }
            if (position.amount !== undefined) {

                setCheckAmount(position.amount);
            }
        } else {
            setTotalSalary("");
            setCheckAmount(false);
            setCheckPercentage(false);
        }

        handleOnNextStep('Initial');
    }

    useEffect(() => {
        getPositionList(user?.idHiring);
    }, [getPositionList, user?.idHiring]);

    useEffect(() => {
        setPositionSelected({} as IPosition);

    }, []);
    return (
        <Content className='div-content-initial-step-add-job-modal'>
            <Row className='row-title-initial-step-add-job-modal'>
                <Col xs={24} lg={12} className='col-text-initial-step-add-job-modal'>
                    <Text className='font-project title-go-initial-step-add-job-modal'>{dictionary['creation-modal-title'].toUpperCase()}</Text>
                </Col>
                <Col xs={24} lg={12} className='col-go-button-initial-step-add-job-modal'>
                    <Button className='button-go-initial-step' onClick={() => handleOnNextStep('Initial')} >
                        {dictionary['creation-modal-initial-page-button-go'].toUpperCase()}
                    </Button>
                </Col>
            </Row>

            <div className="row-wrapper-select-job">
                <Row className='row-select-job-initial-step-add-job-modal'>
                    <Col xs={24} lg={9} className='col-select-job-text-initial-step-add-job-modal'>
                        <Text className='title-select-job-initial-step-add-job-modal'>
                            {dictionary['creation-modal-initial-page-title-2']}
                        </Text>
                    </Col>

                    <Col xs={24} lg={15} className='col-select-job-initial-step-add-job-modal'>
                        <Select
                            placeholder={dictionary['creation-modal-initial-page-button-select-job']}
                            optionFilterProp="children"
                            onChange={(value) => handleOnSelectJob(value, positionSelectList)}
                            variant="borderless"
                            options={positionList}
                            value={defaultValueSelect}
                            defaultValue={defaultValueSelect}
                            className='creation-modal-initial-page-button-select-job '
                        />
                    </Col>
                </Row>

                {
                    positionSelected.idPosition &&
                    <FloatingPositionCard
                        position={positionSelected}
                        handleOnCloneJob={handleOnCloneJob}
                        valueHourForWeek={valueHourForWeek}
                    />


                }
            </div>
        </Content>
    )
}

// Exports
export default InitialStepCreationJob;