//Dependencies
import { Button, Col, Row, Tag, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PositionList from "./PositionList";
import { db } from "../../../../firebase/config";
import iconAddPlus from "../../../../image/plusAdd.png";
import iconMinus from "../../../../image/minus.png";
import { getTranslation } from "../../../../hooks/hooks";
import { startGetLatestStatusCandidateAllJobs } from "../../../../firebase/chat/hooks";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getJobsCandidateById } from "../../../../firebase/endpoints/messages";
const { Text } = Typography;

// Interface
interface Props {
  isChatScreen: boolean;
  showHeaderLarge?: boolean;
  setShowHeaderLarge: (value: boolean) => void;
}

// Component
const HeaderChat: React.FC<Props> = ({
  isChatScreen,
  showHeaderLarge,
  setShowHeaderLarge,
}) => {
  // States
  const filterJob = useSelector((state: any) => state.chat.filterJob);
  const { positionSelected, userChatSelected: candidateChatSelected } =
    useSelector((state: any) => state.chat);
  const candidateJobSelected = useSelector(
    (state: any) => state.jobList.userSelected
  );
  const { user, token } = useSelector((state: any) => state.auth);
  const { position: positionNavigationSelected, dictionary } = useSelector(
    (state: any) => state.ui
  );
  const [status, setStatus] = useState<any>(
    getTranslation("message-header-chat-text-loading", dictionary)
  );
  const [positionList, setPositionList] = useState<Array<any>>([]);
  const [loadingPositionList, setLoadingPositionList] =
    useState<boolean>(false);

  // Handlers
  const getJobCandidate = useCallback(
    async (candidateId: string, hiringId: string, token: string) => {
      try {
        setLoadingPositionList(true);
        setStatus(
          getTranslation("message-header-chat-text-loading", dictionary)
        );
        /*         const responseJobPerCandidate =
                  await startGetJobPerCandidateByIdCandidateWithIdHiring(
                    candidateId,
                    hiringId,
                    token
                  ); */

        const responseJobPerCandidate = await getJobsCandidateById(hiringId, candidateId)
        setPositionList(responseJobPerCandidate);

        if (responseJobPerCandidate !== null) {
          const responseLatestStatus =
            await startGetLatestStatusCandidateAllJobs(
              responseJobPerCandidate,
              candidateId,
              hiringId
            );

          if (responseLatestStatus !== null) {
            if (responseLatestStatus === "waiting for reply") {
              setStatus(dictionary["job-status-title-waiting-for-reply"]);
            } else if (responseLatestStatus === "interested") {
              setStatus(dictionary["job-status-title-interested"]);
            } else if (responseLatestStatus === "interview") {
              setStatus(dictionary["job-status-title-interview"]);
            } else if (responseLatestStatus === "offer") {
              setStatus(dictionary["job-status-title-offer"]);
            } else if (responseLatestStatus === "meeting") {
              setStatus(dictionary["job-status-title-meeting"]);
            } else if (responseLatestStatus === "hired") {
              setStatus(dictionary["job-status-title-hired"]);
            } else if (responseLatestStatus === "") {
              setStatus(dictionary["message-header-chat-text-no-messages"]);
            }
          }
        }
        setLoadingPositionList(false);
      } catch (error) {
        console.log(error);
        setLoadingPositionList(false);
      }
    },
    [dictionary]
  );


  const getPositionListByCandidateId = useCallback(
    async (idCandidate, idHiring) => {
      try {
        setPositionList([]);
        setLoadingPositionList(true);

        // Reference to the "positions" subcollection
        const positionsRef = collection(db, `hirings/${idHiring}/positions`);
        const qPositions = query(positionsRef, where("status", "==", "open"));
        const positionsCollect = await getDocs(qPositions);

        const list = [];
        for (const position of positionsCollect.docs) {
          const idPosition = position.id;
          const positionData = position.data();

          // Reference to the candidate's recommendation document
          const recommendationDocRef = doc(
            db,
            `candidates/${idCandidate}/recommendation`,
            idPosition
          );
          const recommendationDocument = await getDoc(recommendationDocRef);

          if (
            recommendationDocument.exists() &&
            recommendationDocument.data()?.like
          ) {
            // Reference to the recommendationCandidates document
            const recommendationCandidateDocRef = doc(
              db,
              `hirings/${idHiring}/positions/${idPosition}/recommendationCandidates`,
              idCandidate
            );
            const recommendationCandidateDocument = await getDoc(
              recommendationCandidateDocRef
            );

            const recommendationCandidateData =
              recommendationCandidateDocument.data();
            if (recommendationCandidateData?.status !== "declined") {
              list.push(positionData);
            }
          }
        }

        setPositionList(list);
        setLoadingPositionList(false);
      } catch (e) {
        console.error("Error in getPositionListByCandidateId:", e);
        setLoadingPositionList(false);
      }
    },
    []
  );

  useEffect(() => {
    const fetchCandidateData = async () => {
      if (isChatScreen) {
        if (filterJob === "Inbox") {
          getJobCandidate(candidateChatSelected.id, user?.idHiring, token); // Assuming getJobCandidate is defined
        } else if (
          filterJob === "MessageByJob" &&
          positionSelected.idPosition &&
          candidateChatSelected?.id
        ) {
          const recomCandidateDocRef = doc(
            db,
            `hirings/${user?.idHiring}/positions/${positionSelected.idPosition}/recommendationCandidates`,
            candidateChatSelected.id
          );
          const recomCandidateDocument = await getDoc(recomCandidateDocRef);

          if (recomCandidateDocument.exists()) {
            const candidateData = recomCandidateDocument.data();
            console.log(candidateData.status)
            switch (candidateData.status) {
              case "Waiting for reply":
                setStatus(
                  getTranslation(
                    "job-status-title-waiting-for-reply",
                    dictionary
                  )
                );
                break;
              case "interested":
                setStatus(
                  getTranslation("job-status-title-interested", dictionary)
                );
                break;

              case "meeting":
                setStatus(
                  getTranslation("job-status-title-meeting", dictionary)
                );
                break;

              case "interview":
                setStatus(
                  getTranslation("job-status-title-interview", dictionary)
                );
                break;

              case "offer":
                setStatus(
                  getTranslation("job-status-title-offer", dictionary)
                );
                break;

              case "hired":
                setStatus(
                  getTranslation("job-status-title-hired", dictionary)
                );
                break;
              // Add other cases as per your requirement
            }
            setPositionList([positionSelected]); // Assuming setPositionList is a state setter
          }
        }
      }
    };

    fetchCandidateData();
  }, [
    isChatScreen,
    user?.idHiring,
    filterJob,
    candidateChatSelected,
    positionSelected.idPosition,
    positionSelected,
    dictionary,
    getJobCandidate,
    token,
  ]);

  return (
    <>
      {/* vista del chat */}
      {isChatScreen ? (
        <Row
          className="contenedor"
          style={{
            maxHeight: 150,
            borderRadius: 10,
            padding: 10,
            width: "100%",
            marginBottom: "1%",
            border: "1px solid #e0e0e0",
            boxShadow: "2px 2px 5px #00000020",
            overflow: "auto",
            backgroundColor: 'white'
          }}
        >
          <Row style={{ width: "100%" }}>
            <Col
              sm={24}
              md={24}
              lg={10}
              xl={9}
              xxl={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Text style={{ fontSize: 20 }}>
                {dictionary["job-candidate-header-message-status-text"]}
              </Text>
            </Col>

            <Col
              sm={24}
              md={24}
              lg={14}
              xl={15}
              xxl={16}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                style={{
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#24CECE",
                  padding: 3,
                  paddingLeft: 15,
                  paddingRight: 15,
                  marginRight: 3,
                }}
              >
                <Text
                  style={{ fontSize: 20, color: "black", fontFamily: "Mulish" }}
                >
                  {status.substring(0, 1).toUpperCase()}
                  {status.substring(1)}
                </Text>
              </div>
            </Col>
          </Row>

          <Row style={{ minHeight: "50%", width: "100%", marginTop: 10 }}>
            <Col
              sm={24}
              md={24}
              lg={10}
              xl={9}
              xxl={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Text style={{ fontSize: 20, fontFamily: "Mulish" }}>
                {dictionary["job-candidate-header-message-text"]}
              </Text>
            </Col>

            <Col
              sm={24}
              md={24}
              lg={14}
              xl={15}
              xxl={16}
              style={{ display: "flex" }}
            >
              {status === "No Messages" ? (
                <div
                  style={{
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#24CECE",
                    padding: 3,
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginRight: 3,
                  }}
                >
                  <Text
                    style={{ fontSize: 20, color: "black", fontFamily: "Mulish" }}
                  >
                    {status.substring(0, 1).toUpperCase()}
                    {status.substring(1)}
                  </Text>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <PositionList
                    status={status}
                    positionList={positionList}
                    loading={loadingPositionList}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Row>
      ) : (
        <Row
          style={{
            height: "100%",
            width: "100%",
            border: "1px solid #e0e0e0",
            boxShadow: "2px 2px 5px #00000020",
            borderRadius: 10,
            display: "flex",
            flexDirection: 'row',
            alignItems: "center",
            paddingLeft: 10, paddingTop: 10,
            paddingBottom: 10
          }}
        >
          {/* vista del chat en el job */}
          <Col
            xs={{ span: 24 }}
            sm={{ span: 8 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xxl={{ span: 8 }}
            style={{ display: "flex", alignItems: showHeaderLarge ? "flex-start" : 'center', height: '100%', paddingTop: showHeaderLarge ? 5 : 0 }}
          >
            <Text style={{ fontSize: 17, fontFamily: "Mulish" }}>
              {getTranslation("job-candidate-header-message-text", dictionary)}
            </Text>
          </Col>

          <Col
            xs={{ span: 20 }}
            sm={{ span: 14 }}
            md={{ span: 14 }}
            lg={{ span: 14 }}
            xl={{ span: 14 }}
            xxl={{ span: 14 }}
          >
            {showHeaderLarge ? (
              <Row style={{ width: "100%", display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                {loadingPositionList ? (
                  <Tag
                    style={{
                      borderRadius: 10,
                      backgroundColor: "#24CECE",
                      padding: '3px 16px',
                      margin: '2px', // Añade un pequeño margen para separación visual
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 17,
                        color: "black",
                        fontFamily: "Mulish",
                      }}
                    >
                      Loading positions...
                    </Text>
                  </Tag>
                ) : (
                  positionList.map((position, index) => (
                    <div
                      key={index + "positionListTag"}
                      style={{
                        display: 'flex', // Asegura que el contenido interno use flexbox
                        alignItems: 'center', // Centra el contenido verticalmente
                        borderRadius: 10,
                        backgroundColor: position.idPosition === positionNavigationSelected.idPosition ? "#24cece" : "#00000023",
                        marginTop: '2px', // Ajusta como necesario
                        marginLeft: index > 0 ? '2px' : '0', // Agrega separación entre elementos
                        padding: '10px',
                        flex: '0 1 auto', // Permite que el elemento se ajuste pero no crezca
                      }}
                    >
                      <Text
                        key={index + "positionListText"}
                        style={{
                          fontSize: 17,
                          color: "black",
                          fontFamily: "Mulish",
                        }}
                      >
                        {position?.title}
                      </Text>
                    </div>
                  ))
                )}
              </Row>
            ) : (
              <Row style={{ width: "100%" }}>
                <div
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#24CECE",
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingBottom: 3,
                    paddingTop: 3
                  }}
                >
                  <Text
                    style={{ fontSize: 17, color: "black", fontFamily: "Mulish" }}
                  >
                    {positionNavigationSelected?.title}
                  </Text>
                </div>
              </Row>
            )}
          </Col>

          <Col
            xs={{ span: 4 }}
            sm={{ span: 2 }}
            md={{ span: 2 }}
            lg={{ span: 2 }}
            xl={{ span: 2 }}
            xxl={{ span: 2 }}
          >
            <Button
              onClick={() => {
                if (showHeaderLarge) {
                  setShowHeaderLarge(false);
                } else {
                  getPositionListByCandidateId(
                    candidateJobSelected?.id,
                    user?.idHiring
                  );
                  setShowHeaderLarge(true);
                }
              }}
              style={{ border: "transparent", height: "100%" }}
              icon={
                showHeaderLarge ? (
                  <img alt="icon plus" src={iconMinus} style={{ height: 30 }} />
                ) : (
                  <img
                    alt="icon plus"
                    src={iconAddPlus}
                    style={{ height: 30 }}
                  />
                )
              }
            />
          </Col>
        </Row>
      )}
    </>
  );
};

//Export
export default HeaderChat;
