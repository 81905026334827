//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
// Importaciones de React y Hooks
import React, { useEffect, useState } from "react"; // Importa React junto con los hooks useEffect y useState.
import { useSelector } from "react-redux"; // Importa el hook useSelector de Redux para acceder al estado global.

// Importaciones de Firebase
import { Timestamp, doc, getDoc, onSnapshot } from "firebase/firestore"; // Importa funciones específicas de Firestore para trabajar con documentos y tiempo real.
import { db } from "../../../firebase/config"; // Importa la configuración de la base de datos de Firebase.

// Importaciones de Ant Design UI
import { Button, Col, List, Row, Typography } from "antd"; // Importa componentes de Ant Design para la interfaz de usuario.
import { Content } from "antd/lib/layout/layout"; // Importa el componente Content de Ant Design para la disposición del layout.

// Importaciones de Estilos CSS
import "./JobPositionDetailStyle.css"; // Importa estilos CSS específicos para este componente.

// Importaciones de Imágenes
import iconArrowRight from "../../../image/ico-rating-0-green.png"; // Importa una imagen específica.
import iconRemote from "../../../image/btn-remote-0-3x-green.png"; // Importa otra imagen.
import iconHybrid from "../../../image/btn-hybrid-0-3x-green.png"; // Y otra más.
import IcoEdit from "../../../image/ico-edits-3x.png"; // Importa una imagen de un icono de edición.
import iconOnSite from "../../../image/btn-onsite-0-3x-green.png"; // Importa imagen para el icono de sitio.

// Importaciones de Componentes y Modal Personalizados
import IconRatingSkill from "../../../common/iconRating"; // Importa un componente de calificación de habilidades.
import CreateJobModal from "../../../common/modals/createJobModal/CreateJobModal"; // Importa un modal personalizado para crear trabajos.

// Importaciones de Tipos y Utilidades
import { Job } from "../../../types/position"; // Importa un tipo de dato específico para la posición.
import { formatNumber } from "../../../hooks/hooks"; // Importa una función de utilidad para formatear números.

// Interface
interface Props {
  jobPositionData: Job;
}

// Component
const { Text } = Typography;
const JobDescriptionCard: React.FC<Props> = ({ jobPositionData }) => {
  // States
  const user = useSelector((state: any) => state.auth.user);
  const [jobData, setJobData] = useState({});
  const [visibleModal, setVisibleModal] = useState<boolean>(false);

  const [title, setTitle] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [workplace, setWorkplace] = useState<Array<any>>([]);
  const [summary, setSummary] = useState<string>("");
  const [salary, setSalary] = useState<string>("");
  const [salaryMax, setSalaryMax] = useState<string>("");
  const [totalSalary, setTotalSalary] = useState<string>("");
  const [responsibilities, setResponsibilities] = useState<Array<any>>([]);
  const [requirements, setRequirements] = useState<Array<any>>([]);
  const [skills, setSkills] = useState<Array<any>>([]);
  const [workCondition, setWorkCondition] = useState<Array<any>>([]);
  const [percenOrAmount, setPercenOrAmount] = useState<boolean>(false);
  const [annualOrHourly, setAnnualOrHourly] = useState<boolean>(false); // Annual => false | Hourly => true
  const [otherDetailsCompensation, setOtherDetailsCompensation] = useState('')
  const { dictionary } = useSelector((state: any) => state.ui);

  //Handlers
  const showModal = () => {
    setVisibleModal(true);
  };

  const closeModal = () => {
    setVisibleModal(false);
  };

  const handleOnUpdatePosition = async (
    idHiring: string,
    idPosition: string
  ) => {
    try {
      // Reference to the specific document in the "positions" subcollection
      const positionDocRef = doc(db, `hirings/${idHiring}/positions`, idPosition);

      // Get the document
      const positionDocument = await getDoc(positionDocRef);

      if (positionDocument.exists()) {
        const positionData = positionDocument.data();

        setJobData({
          status: positionData.status,
          title: positionData.title,
          description: positionData.description,
          company: positionData.company,
          name: positionData.name,
          lastName: positionData.lastName,
          idPosition: positionData.idPosition,
          salary: positionData.salary,
          salaryMax: positionData.salaryMax,
          checkMin: positionData.checkMin,
          checkMiddle: positionData.checkMiddle,
          checkMax: positionData.checkMax,
          totalSalary: positionData.totalSalary,
          responsibilities: positionData.responsibilities,
          technicalRequirements: positionData.technicalRequirements,
          skills: positionData.skills,
          location: positionData.location,
          jobType: positionData.jobType,
          workplace: positionData.workplace,
          annual: positionData.annual,
          hourly: positionData.hourly,
          percentage: positionData.percentage,
          amount: positionData.amount,
          otherDetailsCompensation: positionData.otherDetailsCompensation
            ? positionData.otherDetailsCompensation
            : "",
          hourForWeek: positionData.hourForWeek ? positionData.hourForWeek : 40,
        });
      }

      showModal();
    } catch (error) {
      console.error("Error fetching position data: ", error);
      // Handle the error appropriately
    }
  };

  // Este hook useEffect se ejecuta cuando el componente se monta y también cada vez que cambian los valores de jobPositionData.idPosition o user.idHiring.
  useEffect(
    () => {
      // Referencia a un documento específico en Firestore.
      // Esto establece un camino a un documento en la colección 'hirings', específicamente en la subcolección 'positions' de un hiring particular.
      const positionDocRef = doc(
        db,
        `hirings/${user.idHiring}/positions`,
        jobPositionData.idPosition
      );

      // Se suscribe a los cambios en el documento referenciado.
      // Cada vez que hay un cambio en el documento, se ejecuta la función callback.
      const unsubscribe = onSnapshot(positionDocRef, (positionSnapshot) => {
        // Obtiene los datos del documento.
        const position = positionSnapshot.data();

        // Si hay datos en el documento, actualiza el estado del componente con estos datos.
        if (position) {
          setTitle(position.title || "");
          setLocation(position.location || "");
          setWorkplace(position.workplace || []);
          setSalary(position.salary || "");
          setSalaryMax(position.salaryMax || "");
          setTotalSalary(position.totalSalary || "");
          setResponsibilities(position.responsibilities || []);
          setRequirements(position.technicalRequirements || []);
          setSkills(position.skills || []);
          setSummary(position.description || "");
          setWorkCondition(position.jobType || []);
          setAnnualOrHourly(
            position.annual !== undefined ? !position.annual : true
          );
          setPercenOrAmount(position.percentage || false);
          setOtherDetailsCompensation(position.otherDetailsCompensation ? position.otherDetailsCompensation : '')
        }
      });

      // Esta función se ejecuta cuando el componente se desmonta o antes de que el efecto se ejecute de nuevo.
      // Sirve para cancelar la suscripción a los cambios en el documento para evitar pérdidas de memoria.
      return () => unsubscribe();
    },
    // Lista de dependencias del hook useEffect. El efecto se ejecutará de nuevo si cambian estos valores.
    [jobPositionData.idPosition, user.idHiring]
  );

  return (
    <Content
      className="contenedor"
      style={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        position: "relative",

      }}
    >
      <Button
        style={{
          display: "flex",
          padding: 0,
          background: "#6153D300",
          width: "auto",
          borderColor: "transparent",
          boxShadow: "none",
          position: "absolute", // Posicionamiento absoluto
          top: 0, // Alineado a la parte superior
          right: 20,
        }}
        type="primary"
        shape="circle"
        icon={<img src={IcoEdit} style={{ height: 25 }} alt={iconArrowRight} />}
        onClick={() =>
          handleOnUpdatePosition(user?.idHiring, jobPositionData.idPosition)
        }
      />
      <Row style={{ width: "100%", alignItems: "center", marginTop: 20 }}>
        <Col
          xs={{ span: 21 }}
          lg={{ span: 22 }}
          xl={{ span: 22 }}
          xxl={{ span: 22 }}
        >
          <Row style={{ width: "100%" }}>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              lg={{ span: 10, offset: 1 }}
              xl={{ span: 7, offset: 1 }}
              xxl={{ span: 7, offset: 1 }}
            >
              <Row
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingRight: 10,
                  }}
                >
                  <Text className={"textTitle"}>
                    {dictionary["job-description-title"]}
                  </Text>
                </Col>

                <Col style={{ alignItems: "center", display: "flex" }}>
                  <Text className={"textContents"}>{title}</Text>
                </Col>
              </Row>
            </Col>

            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 7, offset: 0 }}
              xxl={{ span: 7 }}
            >
              <Row
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingRight: 10,
                  }}
                >
                  <Text className={"textTitle"}>
                    {dictionary["job-description-location"]}
                  </Text>
                </Col>

                <Col style={{ alignItems: "center" }}>
                  <Text className={"textContents"}>{location}</Text>
                </Col>
              </Row>
            </Col>

            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              lg={{ span: 10, offset: 1 }}
              xl={{ span: 6, offset: 0 }}
              xxl={{ span: 7, offset: 0 }}
            >
              <Row>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    paddingRight: 10,
                  }}
                >
                  <Text className={"textTitle"}>
                    {dictionary["job-description-status"]}
                  </Text>
                </Col>

                <Col
                  lg={{ span: 15 }}
                  xl={{ span: 18 }}
                  xxl={{ span: 16 }}
                  style={{ alignItems: "center", display: "flex" }}
                >
                  <Text className={"textContents"}>
                    {dictionary["editing-modal-status-open"]}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ width: "100%", marginTop: 10 }}>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 7, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 5, offset: 1 }}
              xxl={{ span: 5, offset: 1 }}
            >
              <Row style={{ width: "100%" }}>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingRight: 10,
                  }}
                >
                  <Text className={"textTitle"}>
                    {dictionary["new-job-modal-text-title1"]}
                  </Text>
                </Col>

                <Col style={{ alignItems: "center", display: "flex" }}>
                  <Text className={"textContents"}>
                    $ {formatNumber(salary)} /{" "}
                    {annualOrHourly ? "Hourly" : "Annual"}
                  </Text>
                </Col>
              </Row>
            </Col>

            {salaryMax === undefined ||
              salaryMax === "" ||
              salaryMax === null ? null : (
              <Col
                xs={{ span: 23, offset: 1 }}
                sm={{ span: 23, offset: 1 }}
                lg={{ span: 11, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                xxl={{ span: 5, offset: 1 }}
              >
                <Row style={{ width: "100%" }}>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: 10,
                    }}
                  >
                    <Text className={"textTitle"}>
                      {dictionary["new-job-modal-text-title2"]}
                    </Text>
                  </Col>

                  <Col style={{ alignItems: "center", display: "flex" }}>
                    <Text className={"textContents"}>
                      $ {formatNumber(salaryMax)} /{" "}
                      {annualOrHourly ? "Hourly" : "Annual"}
                    </Text>
                  </Col>
                </Row>
              </Col>
            )}

            <Col
              xs={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 5, offset: 1 }}
              xxl={{ span: 5, offset: 1 }}
            >
              <Row style={{ width: "100%" }}>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingRight: 10,
                  }}
                >
                  <Text className={"textTitle"}>
                    {dictionary["job-description-bonus"]}
                  </Text>
                </Col>

                <Col style={{ alignItems: "center", display: "flex" }}>
                  {totalSalary !== "" ? (
                    percenOrAmount ? (
                      <Text className={"textContents"}>
                        % {formatNumber(totalSalary)}
                      </Text>
                    ) : (
                      <Text className={"textContents"}>
                        $ {formatNumber(totalSalary)}
                      </Text>
                    )
                  ) : (
                    <Text className={"textContents"}>N/A</Text>
                  )}
                </Col>
              </Row>
            </Col>

            <Col
              xs={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 5, offset: 1 }}
              xxl={{ span: 5, offset: 1 }}
            >
              <Row style={{ width: "100%" }}>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingRight: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      color: "#129BD4",
                      fontFamily: "Mulish",
                    }}
                  >
                    {dictionary["job-description-date-opened"]}
                  </Text>
                </Col>

                <Col style={{ alignItems: "center", display: "flex" }}>
                  <Text className={"textContents"}>
                    {new Timestamp(
                      jobPositionData?.createAt?.seconds,
                      jobPositionData?.createAt?.nanoseconds
                    )
                      .toDate()
                      .getDate() +
                      "-" +
                      (new Timestamp(
                        jobPositionData?.createAt?.seconds,
                        jobPositionData?.createAt?.nanoseconds
                      )
                        .toDate()
                        .getMonth() +
                        1) +
                      "-" +
                      new Timestamp(
                        jobPositionData?.createAt?.seconds,
                        jobPositionData?.createAt?.nanoseconds
                      )
                        .toDate()
                        .getFullYear()}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ width: "100%", marginTop: 10 }}>
        <Col
          xs={{ span: 23, offset: 1 }}
          lg={{ span: 23, offset: 1 }}
          xl={{ span: 23, offset: 1 }}
          xxl={{ span: 23, offset: 1 }}
        >
          <Row style={{ width: "100%" }}>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
              xxl={{ span: 24 }}
              style={{ alignItems: "center" }}
            >
              <Text
                style={{ fontSize: 18, color: "#129BD4", fontFamily: "Mulish" }}
              >
                {dictionary["job-description-workplace"]}
              </Text>
            </Col>
          </Row>

          <Row style={{ width: "100%", marginTop: 10 }}>
            {workplace.map((item: any, index) => (
              <Col
                key={index + "jobDescriptionCardWorkplaceRow"}
                xs={7}
                sm={8}
                md={4}
                lg={3}
                xxl={2}
              >
                <div
                  key={index + "jobDescriptionCardWorkplaceDiv"}
                  style={{
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={
                      item === "Remote"
                        ? iconRemote
                        : item === "Hybrid"
                          ? iconHybrid
                          : iconOnSite
                    }
                    style={{ height: "30px", width: "60px" }}
                    alt={iconArrowRight}
                  />

                  <Text className={"textContents"}>
                    {item === "Remote"
                      ? dictionary["creation-modal-preview-field-remote"]
                      : item === "Hybrid"
                        ? dictionary["creation-modal-preview-field-hybrid"]
                        : dictionary["creation-modal-preview-field-on-site"]}
                  </Text>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row style={{ width: "100%", marginTop: 15 }}>
        <Col
          xs={{ span: 23, offset: 1 }}
          lg={{ span: 23, offset: 1 }}
          xl={{ span: 23, offset: 1 }}
          xxl={{ span: 23, offset: 1 }}
        >
          <Row style={{ width: "100%" }}>
            <Col
              xl={{ span: 24 }}
              xxl={{ span: 24 }}
              style={{ alignItems: "center" }}
            >
              <Text className={"textTitle"}>
                {dictionary["job-description-summary"]}
              </Text>
            </Col>
          </Row>

          <Row style={{ width: "95%", marginTop: 10 }}>
            <Text className={"textContents"}>{summary}</Text>
          </Row>
        </Col>
      </Row>


      <Row style={{ width: "100%", marginTop: 15 }}>
        <Col
          xs={{ span: 23, offset: 1 }}
          lg={{ span: 23, offset: 1 }}
          xl={{ span: 23, offset: 1 }}
          xxl={{ span: 23, offset: 1 }}
        >
          <Row style={{ width: "100%" }}>
            <Col
              xl={{ span: 24 }}
              xxl={{ span: 24 }}
              style={{ alignItems: "center" }}
            >
              <Text className={"textTitle"}>
                {dictionary["creation-modal-page-one-field-other-details-compensation"]}
              </Text>
            </Col>
          </Row>

          <Row style={{ width: "95%", marginTop: 10 }}>
            <Text className={"textContents"}>{otherDetailsCompensation}</Text>
          </Row>
        </Col>
      </Row>

      <Row style={{ width: "100%", marginTop: 10 }}>
        <Col
          xs={{ span: 23, offset: 1 }}
          sm={{ span: 23, offset: 1 }}
          md={{ span: 23, offset: 1 }}
          lg={{ span: 23, offset: 1 }}
          xl={{ span: 23, offset: 1 }}
          xxl={{ span: 23, offset: 1 }}
        >
          <Row style={{ width: "100%" }}>
            <Col
              xl={{ span: 24 }}
              xxl={{ span: 24 }}
              style={{ alignItems: "center" }}
            >
              <Text className={"textTitle"}>
                {dictionary["job-description-responsibilities"]}
              </Text>
            </Col>
          </Row>

          <Row style={{ width: "95%", marginTop: 10 }}>
            <List
              bordered={false}
              dataSource={responsibilities}
              renderItem={(item: { index: number; value: string }) => (
                <List.Item key={item.index * 3}>
                  <Row key={item.index * 57} style={{ width: "100%" }}>
                    <Col key={item.index + 55}>
                      <Text
                        style={{
                          marginRight: 16,
                          fontSize: '30px', // Aumenta el tamaño de la fuente para hacer el bullet más grande
                          color: '#6051A3', // Cambia el color para hacerlo más atractivo (elige el color que prefieras)
                          fontWeight: 'bold', // Opcional: Hace el bullet más bold/destacado
                        }}

                      >
                        ·
                      </Text>
                      <Text key={item.index + 5} className={"textContents"}>
                        {item.value}
                      </Text>
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </Row>
        </Col>
      </Row>

      <Row style={{ width: "100%", marginTop: 10 }}>
        <Col
          xs={{ span: 23, offset: 1 }}
          sm={{ span: 23, offset: 1 }}
          md={{ span: 23, offset: 1 }}
          lg={{ span: 23, offset: 1 }}
          xl={{ span: 23, offset: 1 }}
          xxl={{ span: 23, offset: 1 }}
        >
          <Row style={{ width: "100%" }}>
            <Col
              lg={{ span: 24 }}
              xl={{ span: 24 }}
              xxl={{ span: 24 }}
              style={{ alignItems: "center" }}
            >
              <Text className={"textTitle"}>
                {dictionary["job-description-requirements"]}
              </Text>
            </Col>
          </Row>

          <Row style={{ width: "95%", marginTop: 10 }}>
            <List
              bordered={false}
              dataSource={requirements}
              renderItem={(item: { value: string }, index) => (
                <List.Item key={index + "bgc"}>
                  <Row
                    key={index + "bgw"}
                    style={{ width: "100%", alignItems: "center" }}
                  >
                    <Col>
                      <Text
                        style={{
                          marginRight: 16,
                          fontSize: '30px', // Aumenta el tamaño de la fuente para hacer el bullet más grande
                          color: '#6051A3', // Cambia el color para hacerlo más atractivo (elige el color que prefieras)
                          fontWeight: 'bold', // Opcional: Hace el bullet más bold/destacado
                        }}

                      >
                        ·
                      </Text>
                      <Text key={index + "bg"} className={"textContents"}>
                        {item.value}
                      </Text>
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </Row>
        </Col>
      </Row>

      <Row style={{ width: "100%", marginTop: 10 }}>
        <Col
          xs={{ span: 23, offset: 1 }}
          sm={{ span: 23, offset: 1 }}
          md={{ span: 23, offset: 1 }}
          lg={{ span: 23, offset: 1 }}
          xl={{ span: 23, offset: 1 }}
          xxl={{ span: 23, offset: 1 }}
        >
          <Row style={{ width: "100%" }}>
            <Col
              xl={{ span: 24 }}
              xxl={{ span: 24 }}
              style={{ alignItems: "center" }}
            >
              <Text className={"textTitle"}>
                {dictionary["job-description-skills"]}
              </Text>
            </Col>
          </Row>

          <Row style={{ width: "100%", marginTop: 10 }}>
            {skills?.map((each: any, index: number) => (
              <Row
                key={index + "jobDescriptionCardSkillsRow"}
                style={{ width: "100%", marginTop: index > 0 ? 16 : 0 }}
              >
                <Col
                  xs={{ span: 14 }}
                  lg={{ span: 7 }}
                  xl={{ span: 5 }}
                  xxl={{ span: 5 }}
                  key={index + "jobDescriptionCardSkillsCol"}
                >
                  <Text
                    className={"textContents"}
                    key={index + "jobDescriptionCardSkillsText"}
                  >
                    {each.nameSkill}
                  </Text>
                </Col>

                <Col
                  xxl={{ span: 12 }}
                  key={index + "jobDescriptionCardSkillsColTwo"}
                >
                  <IconRatingSkill
                    key={index + "jobDescriptionCardSkillsIconRatingSkill"}
                    rating={each.rating}
                  />
                </Col>
              </Row>
            ))}
          </Row>
        </Col>
      </Row>

      <Row style={{ width: "100%", marginTop: 20, marginBottom: 30 }}>
        <Col
          xs={{ span: 23, offset: 1 }}
          sm={{ span: 23, offset: 1 }}
          md={{ span: 23, offset: 1 }}
          lg={{ span: 23, offset: 1 }}
          xl={{ span: 23, offset: 1 }}
          xxl={{ span: 23, offset: 1 }}
        >
          <Row style={{ width: "100%" }}>
            <Col
              xl={{ span: 24 }}
              xxl={{ span: 24 }}
              style={{ alignItems: "center" }}
            >
              <Text className={"textTitle"}>
                {dictionary["job-description-work-condition"]}
              </Text>
            </Col>
          </Row>

          <Row style={{ width: "100%", marginTop: 10 }}>
            {workCondition.map((item: any, index) => (
              <Row
                key={index + "jobDescriptionCardTypeRow"}
                style={{
                  paddingRight: 10,
                  width: "100%",
                  marginTop: index > 0 ? 10 : 8,
                }}
              >
                <Col
                  style={{
                    alignItems: "center",
                    marginRight: 10,
                    display: "flex",
                  }}
                  key={index + "jobDescriptionCardTypeCol"}
                >
                  <img
                    key={index + "jobDescriptionCardTypeImg"}
                    src={iconArrowRight}
                    style={{ height: 26 }}
                    alt={iconArrowRight}
                  />
                </Col>
                <Col
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                  key={index + "jobDescriptionCardTypeColTwo"}
                >
                  <Text
                    key={index + "jobDescriptionCardTypeText"}
                    className={"textContents"}
                  >
                    {" "}
                    {item}
                  </Text>
                </Col>
              </Row>
            ))}
          </Row>
        </Col>
      </Row>

      <CreateJobModal
        closeModal={closeModal}
        user={user}
        visibleModal={visibleModal}
        data={jobData}
      />
    </Content>
  );
};

// Export
export default JobDescriptionCard;
