//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React, { useEffect, useState } from 'react'
import { Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import './WorkCondition.css'
// Interface
interface Props {
    saveJobType: (arrayString: Array<string>) => void
    dataJobType: Array<string>
}

// Component
const JobTypeCheckBox: React.FC<Props> = ({ saveJobType, dataJobType }) => {

    const [fullTime, setFullTime] = useState<boolean>(false)
    const [partTime, setPartTime] = useState<boolean>(false)
    const [contract, setContract] = useState<boolean>(false)
    const [permanent, setPermanent] = useState<boolean>(false)
    const [training, setTraining] = useState<boolean>(false)
    const [seasonal, setSeasonal] = useState<boolean>(false)
    const [freelance, setFreelance] = useState<boolean>(false)
    const [volunteer, setVolunteer] = useState<boolean>(false)

    const { dictionary } = useSelector((state: any) => state.ui);

    useEffect(() => {
        const arrayString = []
        if (fullTime === true) {
            arrayString.push("Full time")
        }
        if (partTime === true) {
            arrayString.push("Part time")
        }
        if (contract === true) {
            arrayString.push("Contract")
        }
        if (permanent === true) {
            arrayString.push("Permanent")
        }
        if (training === true) {
            arrayString.push("Training")
        }
        if (seasonal === true) {
            arrayString.push("Seasonal")
        }
        if (freelance === true) {
            arrayString.push("Freelance")
        }
        if (volunteer === true) {
            arrayString.push("Volunteer")
        }

        saveJobType(arrayString)
    }, [fullTime, partTime, contract, permanent, training, seasonal, freelance, volunteer, saveJobType])


    useEffect(() => {
        if (dataJobType === undefined) {
            return;
        }
        dataJobType.forEach((element: string) => {
            if (element === "Full time") {
                setFullTime(true)
            }
            if (element === "Part time") {
                setPartTime(true)
            }
            if (element === "Contract") {
                setContract(true)
            }
            if (element === "Permanent") {
                setPermanent(true)
            }
            if (element === "Training") {
                setTraining(true)
            }
            if (element === "Seasonal") {
                setSeasonal(true)
            }
            if (element === "Freelance") {
                setFreelance(true)
            }
            if (element === "Volunteer") {
                setVolunteer(true)
            }

        });

    }, [dataJobType]);

    return (
        <>
            <Checkbox className='custom-checkbox label-bonus-section' checked={fullTime} onChange={() => setFullTime(!fullTime)}>{dictionary['creation-modal-page-one-checkbox-full-time']}</Checkbox>
            <Checkbox className='custom-checkbox label-bonus-section' checked={partTime} onChange={() => setPartTime(!partTime)}>{dictionary['creation-modal-page-one-checkbox-part-tim']}</Checkbox>
            <Checkbox className='custom-checkbox label-bonus-section' checked={contract} onChange={() => setContract(!contract)}>{dictionary['creation-modal-page-one-checkbox-contract']}</Checkbox>
            <Checkbox className='custom-checkbox label-bonus-section' checked={permanent} onChange={() => { setPermanent(!permanent) }}>{dictionary['creation-modal-page-one-checkbox-permanent']}</Checkbox>
            <Checkbox className='custom-checkbox label-bonus-section' checked={training} onChange={() => setTraining(!training)}>{dictionary['creation-modal-page-one-checkbox-training']}</Checkbox>
            <Checkbox className='custom-checkbox label-bonus-section' checked={seasonal} onChange={() => setSeasonal(!seasonal)}>{dictionary['creation-modal-page-one-checkbox-seasonal']}</Checkbox>
            <Checkbox className='custom-checkbox label-bonus-section' checked={freelance} onChange={() => setFreelance(!freelance)}>{dictionary['creation-modal-page-one-checkbox-freelance']}</Checkbox>
            <Checkbox className='custom-checkbox label-bonus-section' checked={volunteer} onChange={() => setVolunteer(!volunteer)}>{dictionary['creation-modal-page-one-checkbox-volunteer']}</Checkbox>
        </>
    )
}

//Export 
export default JobTypeCheckBox;
