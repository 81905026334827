import { Typography } from 'antd'
import React from 'react'
import './HeaderAddJobModal.css'
import { useSelector } from 'react-redux'
import { assets } from '../../../../assets/assets'
import CloseButton from '../../../buttons/closeButton/CloseButton'
import InitialInformativeButton from './InitialInformativeButton'
import FirstStepInformativeButton from './FirstStepInformativeButton'

const { Text } = Typography;

interface IHeaderAddJobModal {
    data: any;
    steps: "Initial" | "First Step" | "Second Step" | "Third Step" | "Final Step";
    closeModal: () => void;
    resetFields: () => void;
    handleOnCloseModal: () => void;
}
// Components
const HeaderAddJobModal: React.FC<IHeaderAddJobModal> = ({ data, steps, closeModal, handleOnCloseModal, resetFields }) => {

    // States
    const { dictionary } = useSelector((state: any) => state.ui);

    return (
        <div className='row-container-header-add-job-modal'>
            <div className='div-header-title'>
                <img
                    src={assets.iconLogoPopUp}
                    style={{ height: 30, width: 'auto' }}
                    alt='icon-dot'
                />

                <div className='div-title-add-job-modal'>
                    <Text className="text-title-add-job-modal">
                        {steps === 'Initial'
                            ? ''
                            : steps === 'First Step'
                                ? data !== undefined
                                    ? dictionary['editing-modal-title']
                                    : dictionary['creation-modal-page-one-header-title']
                                : steps === 'Second Step'
                                    ? data !== undefined
                                        ? dictionary['editing-modal-title']
                                        : dictionary['creation-modal-page-one-header-title']
                                    : steps === 'Third Step'
                                        ? data !== undefined
                                            ? dictionary['editing-modal-title']
                                            : dictionary['creation-modal-page-one-header-title']
                                        : dictionary['creation-modal-title-last-step']}
                    </Text>

                    {
                        steps === 'Initial'
                            ?
                            <InitialInformativeButton />
                            : steps === 'First Step'
                            &&
                            <FirstStepInformativeButton />

                    }
                </div>
            </div>

            <div className='div-close-button-add-job-modal'>
                <CloseButton onClick={() => {
                    if (steps === "Initial") {
                        closeModal();
                        resetFields();
                    } else {
                        handleOnCloseModal();
                    }
                }} />
            </div>
        </div>
    )
}

export default HeaderAddJobModal