//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Col, Input, Row, Typography } from "antd";
import React from "react";
import UploadAvatar from "../../../UploadAvatar";
import { useSelector } from "react-redux";
import './CompanyInformationSection.css'

// Interface
interface Props {
  avatarCompany: string;
  setAvatarCompany: (value: string) => void;
  companyName: string;
  setCompanyNameForm: (value: string) => void;
  industryForm: string;
  setIndustryForm: (value: string) => void;
  websiteCompany: string;
  setWebsiteCompany: (value: string) => void;
  companyInformationForm: string;
  setCompanyInformationForm: (value: string) => void;
}

// Component Ant Design
const { TextArea } = Input;
// Component Ant Design
const { Text } = Typography;
//Component
const CompanyInformationSection: React.FC<Props> = ({ avatarCompany, setAvatarCompany, companyName, setCompanyNameForm, industryForm, setIndustryForm, setWebsiteCompany, websiteCompany, companyInformationForm, setCompanyInformationForm, }) => {
  const { dictionary } = useSelector((state: any) => state.ui);

  return (
    <>
      <Row style={{ width: '100%', height: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Row className="row-container-inputs">
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }} lg={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 18, offset: 3 }}>
            <Row className="row-input-group-company">
              <Col xs={24} style={{ marginBottom: '10px' }}>
                <Text style={{ fontSize: 26, color: "#129bd4", fontFamily: "Mulish", fontWeight: 'bold' }}>
                  {dictionary["profile-title-company-information"]}
                </Text>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 0 }} xl={{ span: 7, offset: 0 }} xxl={{ span: 7, offset: 0 }} className="container-text-input">
                <Input
                  className="input-fields"
                  placeholder={dictionary["first-login-modal-placeholder-field-company-name"]}
                  value={companyName}
                  onChange={(e) => setCompanyNameForm(e.target.value)}
                />

                <Text className="text-field-name-input">
                  {dictionary["first-login-modal-view2-text-title2"]}*
                </Text>
              </Col>

              <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 1 }} md={{ span: 11, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 1 }} className="container-text-input">
                <Input
                  className="input-fields"
                  placeholder={dictionary["first-login-modal-placeholder-field-industry"]}
                  value={industryForm}
                  onChange={(e) => setIndustryForm(e.target.value)}
                />
                <Text className="text-field-name-input">
                  {dictionary["first-login-modal-view2-text-title3"]}
                </Text>
              </Col>

              <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 1 }} className="container-text-input">
                <Input
                  style={{ width: "100%", borderRadius: 16 }}
                  placeholder={dictionary["first-login-modal-placeholder-field-website"]}
                  value={websiteCompany}
                  onChange={(e) => setWebsiteCompany(e.target.value)}
                />

                <Text className="text-field-name-input">
                  {dictionary["first-login-modal-view2-text-title4"]}*
                </Text>
              </Col>

              <Col
                xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 7, offset: 0 }} xl={{ span: 15, offset: 0 }} xxl={{ span: 15, offset: 0 }}
                style={{ marginTop: 20 }}
              >
                <TextArea
                  style={{ width: "100%", borderRadius: 20, height: 182 }}
                  placeholder={
                    dictionary[
                    "first-login-modal-placeholder-field-company-description"
                    ]
                  }
                  value={companyInformationForm}
                  onChange={(e) => setCompanyInformationForm(e.target.value)}
                />

                <Text className="text-field-name-input">
                  {dictionary["first-login-modal-view2-text-title5"]}*
                </Text>
              </Col>

              <Col
                xs={{ span: 20, offset: 6 }} sm={{ span: 24, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 1 }}
                style={{ marginTop: 20, display: 'flex', flexDirection: 'column', gap: '2px' }}
              >
                <UploadAvatar
                  title={
                    dictionary["first-login-modal-placeholder-icon-company"]
                  }
                  avatar={avatarCompany}
                  setAvatar={setAvatarCompany}
                />
                <Text style={{ fontSize: 12, color: "#00000050" }}>
                  {dictionary["first-login-modal-view2-text-title1"]}
                </Text>
              </Col>
            </Row>

          </Col>
        </Row>


        <Row style={{ width: '100%' }}>
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }} lg={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 18, offset: 3 }}>
            <Text style={{ fontFamily: "Mulish", fontSize: 14 }}>{dictionary["register-modal-text-bottom-mandatory"]}</Text>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default CompanyInformationSection;
