//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import { Image, Typography } from "antd";
import React, { useEffect, useState } from "react";
import iconLogo from "../../../../image/ico-profile-color-3x.png";
import { useSelector } from "react-redux";
import { MessagesModified } from "../../../../types/message";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../firebase/config";

// Interfaces
interface Props {
  message: MessagesModified;
  justifyContent: string;
  backgroundColor: string;
  textAlign: any | undefined;
  color: string;
  isChatScreen: boolean;
}

// Component
const { Text } = Typography;
const ChatMessage: React.FC<Props> = ({
  color,
  message,
  justifyContent,
  backgroundColor,
  textAlign,
  isChatScreen,
}) => {
  // States
  const userChatSelected = useSelector((state: any) => state.chat.userChatSelected);
  const userSelected = useSelector((state: any) => state.jobList.userSelected);
  const [name, setName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")

  /*   useEffect(() => {
      if(userSelected.id){
        const unsubscribe = firebase.firestore()
          .collection("candidates").doc(userSelected.id)
          .onSnapshot(( candidateSnap ) => {
          const candidateDataFirebase = candidateSnap.data() as {
            id:string,
            name:string,
            lastName:string
          }
          
          if(candidateDataFirebase){
            candidateDataFirebase.name && setName(candidateDataFirebase.name);
            candidateDataFirebase.lastName && setLastName(candidateDataFirebase.lastName)
          }
        })
        return ()=>unsubscribe();
  
      }else if (userChatSelected.id){
        const unsubscribe = firebase.firestore()
          .collection("candidates").doc(userChatSelected.id)
          .onSnapshot((candidateSnap)=>{
            const candidateDataFirebase = candidateSnap.data() as {
              id:string,
              name:string,
              lastName:string
            }
          
            if(candidateDataFirebase){
              candidateDataFirebase.name && setName(candidateDataFirebase.name);
              candidateDataFirebase.lastName && setLastName(candidateDataFirebase.lastName)
            }
          })
        
        return ()=>unsubscribe();
        
      }
    }, [userChatSelected.id,userSelected.id]) */

  useEffect(() => {
    let unsubscribe: any;

    if (userSelected.id) {
      const candidateDocRef = doc(db, "candidates", userSelected.id);
      unsubscribe = onSnapshot(candidateDocRef, (candidateSnap) => {
        const candidateData = candidateSnap.data();
        if (candidateData) {
          candidateData.name && setName(candidateData.name);
          candidateData.lastName && setLastName(candidateData.lastName);
        }
      });
    } else if (userChatSelected.id) {
      const candidateChatDocRef = doc(db, "candidates", userChatSelected.id);
      unsubscribe = onSnapshot(candidateChatDocRef, (candidateSnap) => {
        const candidateData = candidateSnap.data();
        if (candidateData) {
          candidateData.name && setName(candidateData.name);
          candidateData.lastName && setLastName(candidateData.lastName);
        }
      });
    }

    return () => unsubscribe && unsubscribe();
  }, [userChatSelected.id, userSelected.id]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: justifyContent,
          alignItems: textAlign,
          flexDirection: "column",
        }}
      >
        {message.chatUser === "to" && message.showName && (
          <div
            style={{
              height: "60%",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,

            }}
          >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", position: 'relative', overflow: 'hidden' }}>
              <img src={iconLogo} style={{ width: "auto", height: 35 }} alt="Icon" />
              <div style={{ position: 'absolute', color: "white", fontSize: 16 }} className="font-project">
                {name.substring(0, 1).toUpperCase()}
                {lastName.substring(0, 1).toUpperCase()}
              </div>
            </div>

            <div style={{ width: "30%", height: "100%", marginLeft: 10 }}>
              <div
                style={{
                  height: "60%",
                  marginLeft: "0vh",
                  alignItems: "center",
                  marginTop: "1vh",
                }}
              >
                {userChatSelected.name ? (
                  <Text style={{ color: "#6153D3", fontSize: 14 }} className="font-project">
                    {userChatSelected?.name} {userChatSelected?.lastName}
                  </Text>
                ) : (
                  <Text className="font-project" style={{ color: "#6153D3", fontSize: 14 }}>
                    {userSelected?.name} {userSelected?.lastName}
                  </Text>
                )}
              </div>
            </div>
          </div>
        )}

        <div
          style={{
            backgroundColor: backgroundColor,
            borderRadius: 16,
            margin: 5,
            marginBottom: 2,
            textAlign: textAlign,
            minWidth: "5%",
            maxWidth: "80%",
            padding: 2,
            paddingLeft: 10,
            paddingBottom: 10,
            paddingTop: 10,
            paddingRight: 20,
            width: "fit-content",
            overflow: "hidden",
            marginLeft: 45,
          }}
        >
          {message.urlDocument !== null && message.text !== null ? (
            <div>
              <a
                className="font-project"
                style={{
                  fontSize: "bold",
                  color: textAlign === "end" ? "blue" : "blue",
                  textDecoration: "underline",

                }}
                href={message.urlDocument}
                target="_blank"
                rel="noreferrer"
              >
                {message.nameDocument}
              </a>

              <span className="font-project" style={{ color: color, fontSize: 12, lineHeight: '1.3' }}>
                {" "}
                {message.text}
              </span>
            </div>
          ) : message.urlDocument !== null && message.text === null ? (
            <a
              className="font-project"
              style={{
                fontSize: "bold",
                color: textAlign === "end" ? "blue" : "blue",
                textDecoration: "underline"
              }}
              href={message.urlDocument}
              target="_blank"
              rel="noreferrer"
            >
              {message.nameDocument}
            </a>
          ) : message.urlImage !== null && message.text !== null ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ color: color, fontSize: 12, lineHeight: '1.3' }}>
                {" "}
                {message.text}
              </span>
              <Image width={150} height={150} src={message.urlImage} />
            </div>
          ) : message.urlImage !== null && message.text === null ? (
            <Image width={150} height={150} src={message.urlImage} />
          ) : (
            <span style={{ color: color, fontSize: 16, lineHeight: '1.3' }}> {message.text}</span>
          )}
        </div>

        <div style={{ color: "#00000065", marginLeft: 50, marginRight: 12 }}>
          <span style={{ fontSize: 12 }} className="font-project">
            {new Date(message?.date?.seconds * 1000).toLocaleString().substr(0, 10)}{" "}
            {new Date(message?.date?.seconds * 1000).toLocaleString().substr(11, 5)}
          </span>
        </div>
      </div>
    </>
  );
};

export default ChatMessage;
