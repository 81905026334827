import { Input, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';
import './JobSummarySection.css'

interface IJobSummarySectionProps {
    description: string;
    setDescription: (description: string) => void;
}
const JobSummarySection: React.FC<IJobSummarySectionProps> = ({ description, setDescription }) => {
    const { dictionary } = useSelector((state: any) => state.ui);
    return (
        <div className='div-section-summary-job'>
            <Typography.Text className='label-summary'>
                {dictionary["creation-modal-page-two-field-job-summary"]}{' '}*
            </Typography.Text>

            <div className='div-input-summary'>
                <Input.TextArea
                    rows={8}
                    value={description}
                    showCount
                    placeholder={dictionary["creation-modal-page-two-placeholder-job-summary"]}
                    className='input-summary-section'
                    allowClear
                    variant='borderless'
                    onChange={(e) => setDescription(e.target.value)}
                    count={{
                        show: true,
                        max: 500,
                    }}
                />
            </div>
        </div>
    )
}

export default JobSummarySection