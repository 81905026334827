import { Button } from 'antd'
import React, { useState } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux';
import InformativeModal from '../informativePopup/InformativeModal';

const FirstStepInformativeButton = () => {

    const { dictionary } = useSelector((state: any) => state.ui);

    const [visible, setVisible] = useState<boolean>(false);

    return (
        <>
            <Button
                type='link'
                icon={<QuestionCircleOutlined style={{ fontSize: '20px' }} />}
                onClick={() => setVisible(true)}
                style={{ color: '#414193' }}
            />
            <InformativeModal
                title={''}
                description={dictionary['new-job-modal-informative-section-one-form-description-1']}
                setVisible={setVisible}
                visible={visible} />
        </>
    )
}

export default FirstStepInformativeButton