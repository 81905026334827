import { Button } from 'antd'
import { doc, updateDoc } from 'firebase/firestore';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { db } from '../../../../firebase/config';
import { getDictionary, getLanguage } from '../../../../store/ui';
import MensajeIngles from "../../../../lang/diccionary-en.json";
import MensajeFrance from "../../../../lang/diccionary-fr.json";
import { assets } from '../../../../assets/assets';
const ChangeLanguajeButton = () => {


    const { language } = useSelector((state: any) => state.ui);
    const user = useSelector((state: any) => state.auth.user);

    const dispatch: any = useDispatch();
    // Handlers
    const handleOnChangeLanguage = async () => {
        if (language === "English") {
            localStorage.setItem("lang", "Français");
            const candidateDocRef = doc(db, "hirings", user?.idHiring);
            await updateDoc(candidateDocRef, {
                language: "Français",
            });
            dispatch(getLanguage("Français"));
            dispatch(getDictionary(MensajeFrance));
        } else {
            localStorage.setItem("lang", "English");
            const candidateDocRef = doc(db, "hirings", user?.idHiring);
            await updateDoc(candidateDocRef, {
                language: "English",
            });
            dispatch(getLanguage("English"));
            dispatch(getDictionary(MensajeIngles));
        }
    };
    return (
        <Button
            onClick={handleOnChangeLanguage}
            style={{
                borderColor: "transparent",
                backgroundColor: "transparent",
            }}
        >
            {language === "English" ? (
                <img
                    src={assets.iconLanguageFr}
                    style={{ height: 25, width: "auto" }}
                    alt={assets.iconLanguageFr}
                />
            ) : (
                <img
                    src={assets.iconLanguageEn}
                    style={{ height: 25, width: "auto" }}
                    alt={assets.iconLanguageEn}
                />
            )}
        </Button>
    )
}

export default ChangeLanguajeButton