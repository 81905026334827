// Dependencies
import { useEffect, Dispatch, useCallback } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import JobManagerRoutes from "./router/JobManagerRoutes";
import { PublicRoute, PrivateRoute } from "./router";
import { getToken, login } from "./store/auth";
import { getDictionary, getLanguage } from "./store/ui";
import MensajeIngles from './lang/diccionary-en.json';
import MensajeFrance from './lang/diccionary-fr.json';
import { getHiring, updateHiringLanguage } from "./firebase/endpoints/hiringEndPoint";
import { getIdToken, onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import { logoutFirebase } from "./firebase/providers";
import { Layout } from "antd";
import { auth } from "./firebase/config";
import { StateGeneralRedux } from "./types/redux";
import User from "./types/user";

import RegisterPage from "./pages/registerPage/RegisterPage";
import AuthPages from "./pages/authPages/AuthPages";

//Component
const JobManager = () => {
  //States
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();
  const { language } = useSelector((state: StateGeneralRedux) => state.ui);
  const { user } = useSelector((state: StateGeneralRedux) => state.auth);

  const fetchHiring = useCallback(async (user: FirebaseUser) => {
    try {
      // Attempt to fetch hiring document based on the user's UID
      const hiringDoc: User | null = await getHiring(user.uid);
      if (hiringDoc) {
        // If the hiring document exists, store it in local storage
        localStorage.setItem('userData', JSON.stringify(hiringDoc));

        // Attempt to fetch the user's ID token
        const idToken = await getIdToken(user);
        // Dispatch the token to the store, ensuring it's not undefined or empty
        dispatch(getToken(idToken !== undefined && idToken ? idToken : ""));

        // Navigate to either the change password page or the dashboard based on the hiring document's requirement
        navigate(hiringDoc.forceChangePassword ? '/changePassword' : '/dash');
        // Dispatch the login action with the hiring document
        dispatch(login(hiringDoc));
      } else {
        alert('No se encontro el usuario')
      }
    } catch (error) {
      // If an error occurs, alert the user and return null
      alert(`Error in fetchHiring: ${error}`);
      return null;
    }
    // List the dependencies for useCallback to ensure the function is updated appropriately
  }, [dispatch, navigate]);

  const updateLanguageUser = useCallback(async (idHiring: string, language: string) => {
    const { msg } = await updateHiringLanguage(idHiring, language);
    if (msg === 'success') {
      dispatch(getLanguage(language));
    } else if (msg === 'failed') {
      console.log("Failed");
    }
  }, [dispatch],);

  //Effects
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchHiring(user);
      } else {
        logoutFirebase();
      }
    });

    return () => unsubscribe();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const lang = localStorage.getItem('lang');
    if (lang && user?.idHiring) {
      updateLanguageUser(user?.idHiring, lang)
    }

  }, [dispatch, user?.idHiring, updateLanguageUser]);

  useEffect(() => {
    if (language === 'English') {
      dispatch(getDictionary(MensajeIngles))
    } else {
      dispatch(getDictionary(MensajeFrance))
    }
  }, [language, dispatch])

  const location = useLocation();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const lang = pathSegments[1]; // El primer segmento después de '/'
    if (lang.toUpperCase() === 'FR') {

      getLanguage("Français")
      dispatch(getLanguage('Français'));
      localStorage.setItem('lang', 'Français');
      dispatch(getDictionary(MensajeFrance))
      // Puedes tomar acciones en función del idioma aquí si es necesario
    }
    if (lang.toUpperCase() === 'EN') {

      dispatch(getLanguage('English'))
      localStorage.setItem('lang', 'English');
      dispatch(getDictionary(MensajeFrance))
      // Puedes tomar acciones en función del idioma aquí si es necesario
    }
  }, [location.pathname, dispatch]);


  return (
    <Layout style={{ height: '100vh', width: '100vw', padding: 0, margin: 0, backgroundColor: 'white' }}>
      <Routes>
        <Route path="/login" element={

          <PublicRoute>
            <AuthPages />
          </PublicRoute>

        } />
        <Route path="/register" element={

          <PublicRoute>
            <RegisterPage />
          </PublicRoute>

        } />
        <Route path='/*' element={
          <PrivateRoute>
            <JobManagerRoutes />
          </PrivateRoute>
        } />
      </Routes>
    </Layout>
  );
};

export default JobManager;
