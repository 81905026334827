import { Button } from 'antd'
import React from 'react'
import { db } from '../../../../firebase/config';
import { doc, getDoc, runTransaction, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { assets } from '../../../../assets/assets';

const idAdmin = "huiCo2D8l6ZY7NuyNruK6NH6CCG3";
const SettingsButton = () => {
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.auth.user);

    const handleOnNavigateSupportScreen = async (idHiring: string, name: string, lastName: string, company: string) => {
        try {
            const candidateDocRef = doc(db, "admin", idAdmin,
                "messages",
                idHiring
            );
            const candidateDoc = await getDoc(candidateDocRef);

            if (!candidateDoc.exists()) {
                await runTransaction(db, async (transaction) => {
                    const fromMessagesRef = doc(db, "hirings", idHiring, "messages", idAdmin
                    );
                    const toMessagesRef = doc(
                        db,
                        "admin",
                        idAdmin,
                        "messages",
                        idHiring
                    );

                    const fromMessageData = {
                        id: idAdmin,
                        name: "Administrator",
                        lastName: "Support",
                        dateLastMessage: Timestamp.now(),
                        messagesPending: false,
                        notReaded: 0,
                    };

                    const toMessageData = {
                        idUser: idHiring,
                        name: name,
                        lastName: lastName,
                        company: company,
                        dateLastMessage: Timestamp.now(),
                        messagesPending: false,
                        notReaded: 0,
                        typeUser: "hiring",
                    };

                    // Establece los documentos en las subcolecciones correspondientes
                    transaction.set(fromMessagesRef, fromMessageData);
                    transaction.set(toMessagesRef, toMessageData);
                });
            }

            navigate("/support");
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <Button
            onClick={() =>
                handleOnNavigateSupportScreen(
                    user?.idHiring,
                    user?.name,
                    user?.lastName,
                    user?.company
                )
            }
            style={{
                borderColor: "transparent",
                backgroundColor: "transparent",
            }}
            icon={
                <img
                    src={assets.iconSettings}
                    style={{ height: 25, width: "auto" }}
                    alt={assets.iconSettings}
                />
            }
        />
    )
}

export default SettingsButton