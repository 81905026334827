import { Col, Row, Typography } from 'antd'
import React from 'react'
import { assets } from '../../../../assets/assets'
import { useSelector } from 'react-redux';
import './Jobtitle.css'
const Jobtitle = () => {
    const { dictionary } = useSelector((state: any) => state.ui);
    return (
        <Row
            className='row-title-job-sidebar'
        >

            <Col className='col-image-job-sidebar' span={6}>
                <img
                    src={assets.iconJob}
                    style={{ height: 20, width: 'auto' }}
                    alt={assets.iconJob}
                />
            </Col>

            <Col className='col-text-job-sidebar' span={18}>
                <Typography.Text className="font-project text-job-sidebar">
                    {dictionary['sidebar-title-jobs']}
                </Typography.Text>
            </Col>
        </Row>
    )
}

export default Jobtitle