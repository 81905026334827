
// Dependecies
import React from 'react'
import { Col, Row, Typography } from 'antd'
import { useSelector } from 'react-redux';
import './SourcingTitle.css'
import { assets } from '../../../../assets/assets';

const { Text } = Typography;

// Components
const SourcingTitle: React.FC = () => {

    const { dictionary } = useSelector((state: any) => state.ui);
    return (
        <Row className='row-title-sourcing-sidebar'>
            <Col className='col-image-sourcing-sidebar' span={6}>
                <img
                    src={assets.iconSourcing}
                    style={{ height: 25, width: 'auto' }}
                    alt={assets.iconSourcing}
                />
            </Col>

            <Col className='col-text-sourcing-sidebar' span={18}>
                <Text className="font-project text-sourcing-sidebar">
                    {dictionary['sidebar-title-sourcing']}
                </Text>
            </Col>
        </Row>
    )
}

// Exports
export default SourcingTitle