import {
    collection,
    onSnapshot,
    query,
    where,
} from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Tooltip, Typography } from "antd";
import { assets } from "../../../../assets/assets";
import "./JobButton.css";
import {
    clearFilterJob,
    clearMessageList,
    clearPositionList,
    clearPositionSelect,
    clearUserChatSelected,
} from "../../../../store/chat";
import {
    clearUserSelected,
    startGetFirstCandidate,
} from "../../../../store/job";
import { getPositionSideBarSelected } from "../../../../store/ui";
import { IPosition } from "../../../../models/hiring";

interface JobButtonProps {
    positionIndex: number;
    position: IPosition;
}

const { Text } = Typography;

const JobButton: React.FC<JobButtonProps> = ({
    positionIndex,
    position,
}) => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const user = useSelector((state: any) => state.auth.user);
    const positionJobSelected = useSelector((state: any) => state.ui.position);

    const [counterRecommendation, setCounterRecommendation] = useState(0);
    const [counterRecommendationPre, setCounterRecommendationPre] = useState(0);

    const total = counterRecommendation + counterRecommendationPre;

    const isSelected =
        location.pathname === "/jobList" &&
        position.idPosition === positionJobSelected?.idPosition;

    const startResetDataMessage = () => {
        dispatch(clearPositionList());
        dispatch(clearMessageList());
        dispatch(clearUserChatSelected());
        dispatch(clearPositionSelect());
        dispatch(clearFilterJob());
    };

    const startResetDataJobList = () => {
        dispatch(clearUserSelected());
    };

    const handleOnSelectPosition = () => {
        const path = location.pathname;

        if (path === "/messages") {
            startResetDataMessage();
        }

        if (path === "/jobList") {
            startResetDataJobList();
        } else {
            navigate("/jobList");
        }

        dispatch(getPositionSideBarSelected(position));
        dispatch(startGetFirstCandidate(user, position));
    };

    const fetchRecommendations = useCallback(() => {
        if (!user?.idHiring || !position?.idPosition) return;

        const baseRef = `hirings/${user.idHiring}/positions/${position.idPosition}`;
        const recRef = collection(db, baseRef, "recommendationCandidates");
        const preRef = collection(db, baseRef, "recommendationPreCandidates");

        const recQuery = query(recRef, where("status", "==", "interested"));
        const preQuery = query(preRef, where("candidateStatus", "==", "interested"));

        const unsubRec = onSnapshot(recQuery, (snap) =>
            setCounterRecommendation(snap.docs.length)
        );
        const unsubPre = onSnapshot(preQuery, (snap) =>
            setCounterRecommendationPre(snap.docs.length)
        );

        return () => {
            unsubRec();
            unsubPre();
        };
    }, [user?.idHiring, position?.idPosition]);

    useEffect(() => {
        const unsubscribe = fetchRecommendations();
        return () => unsubscribe?.();
    }, [fetchRecommendations]);

    return (
        <Row
            onClick={handleOnSelectPosition}
            className={`job-sidebar-button ${isSelected ? "selected-job" : "default-job"}`}
            key={positionIndex}
        >
            <Col span={3} offset={2} className="col-image-job">
                <img src={assets.iconDot} style={{ height: 13, width: 13 }} alt="icon-dot" />
            </Col>

            <Col span={14}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title={position.title}>
                        <Text
                            className={`font-project text-job-button ${isSelected ? "text-selected" : "text-default"
                                }`}
                            key={positionIndex + 5}
                        >
                            {position.title}
                        </Text>
                    </Tooltip>
                </div>
            </Col>

            <Col span={5} className="col-counter-job-button">
                {total > 0 && (
                    <div className="div-counter-job-button">
                        <Text className="font-project text-counter-job-button">{total}</Text>
                    </div>
                )}
            </Col>
        </Row>
    );
};

export default JobButton;
