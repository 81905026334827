import { Button } from 'antd'
import React, { useState } from 'react'
import iconBtnAccept from "../../image/sourcing/btn-accept.png";
import iconBtnAcceptFr from "../../image/btn-accept-fr.png";
import { PreCandidateInterface } from '../../models/preCandidate.interface';
import { useSelector } from 'react-redux';
import { Job, JobRecommendationPrecandidate } from '../../types/position';
import User from '../../types/user';
import NotificationInteractionSourcing from '../modals/notificationModal/NotificationInteractionSourcing';
import { fetchSendEmail } from '../../services/api.services';
import { EEmail, EEmailLanguage } from '../../models/emails';
import { Timestamp } from 'firebase/firestore';
import { createNotesPreCandidateDocument, createRecommendationDocument } from '../../firebase/services/precandidateCollect/precandidate';
import { updateRecommendationPrecandidateDocument } from '../../firebase/services/hiringsCollect/hirings';
import { uid } from 'uid';
import { EStatusNote, ETypeNote } from '../../firebase/services/precandidateCollect/type';

interface Props {
    position: Job;
    preCandidate: PreCandidateInterface;
    user: User;
    loading: boolean;
    setLoading: (value: boolean) => void;
    saveLocalPrecandidate: (idPrecandidate: string) => void;
}
const ButtonAccept: React.FC<Props> = ({ position, preCandidate, user, loading, setLoading, saveLocalPrecandidate }) => {

    // States
    const [visibleModalNotification, setVisibleModalNotification] = useState<boolean>(false);
    const [type, setType] = useState<"like" | "dislike">("like");
    const { language } = useSelector((state: any) => state.ui);
    const token = useSelector((state: any) => state.auth.token);

    const handleOnLikePreCandidate = async (position: Job, idPreCandidate: string, emailPreCandidate: string, namePreCandidate: string, lastNamePreCandidate: string, tokenApi: string) => {
        setLoading(true);
        try {
            const positionDataCandidate: JobRecommendationPrecandidate = {
                idHiring: user?.idHiring,
                idPosition: position.idPosition,
                title: position?.title ? position?.title : "Missing title",
                location: position?.location ? position?.location : "Missing location",
                description: position?.description
                    ? position?.description
                    : "Missing description",
                name: position?.name ? position?.name : "Missing name",
                lastName: position?.lastName ? position?.lastName : "Missing lastName",
                email: position?.email ? position?.email : "Missing email",
                company: user?.company ? user?.company : "Missing info company",
                salary: position.salary ? position?.salary : "Missing salary",
                companyInformation: user?.companyInformation
                    ? user?.companyInformation
                    : "Missing company information",
                responsibilities: position?.responsibilities
                    ? position.responsibilities
                    : [],
                technicalRequirements: position?.technicalRequirements
                    ? position?.technicalRequirements
                    : [],
                skills: position?.skills ? position?.skills : [],
                status: position?.status ? position?.status : "open",
                workplace: position?.workplace ? position?.workplace : [],
                like: null,
                hourForWeek:
                    position.hourForWeek === null || position.hourForWeek === undefined
                        ? 40
                        : position.hourForWeek,
                otherDetailsCompensation: position.otherDetailsCompensation === "" || position.otherDetailsCompensation === null || position.otherDetailsCompensation === undefined ? "" : position.otherDetailsCompensation,
                timestampLike: null,
                createAt: position?.createAt ? position?.createAt : Timestamp.now(),
                jobType: position?.jobType ? position?.jobType : [],
                type: "job",
                statusMatch: "waiting for reply",
                amount: position?.amount ? position?.amount : false,
                annual: position?.annual ? position?.annual : false,
                percentage: position?.percentage ? position?.percentage : false,
                hourly: position?.hourly ? position.hourly : false,
                totalSalary: position?.totalSalary ? position?.totalSalary : "",
                checkMax: position?.checkMax ? position?.checkMax : false,
                checkMiddle: position?.checkMiddle ? position?.checkMiddle : false,
                checkMin: position?.checkMin ? position?.checkMin : false,
                salaryMax: position?.salaryMax ? position?.salaryMax : "",
                checkBonnus: position?.checkBonnus ? position?.checkBonnus : false,
                language: position?.language ? position?.language : "English",
            }

            // 1. Crear documento de puesto de trabajo en coleccion recommendacion de pre-candidato.
            const createRecommendationDocumentResponse = await createRecommendationDocument(idPreCandidate, position.idPosition, positionDataCandidate);

            if (!createRecommendationDocumentResponse.success) {
                throw new Error(createRecommendationDocumentResponse.error);
            }

            // 2. Actualizar documento de pre-candidato en coleccion de hiring.

            const updateRecommendationPrecandidateDocumentResponse = await updateRecommendationPrecandidateDocument(user.idHiring, position.idPosition, preCandidate.idPreCandidate, {
                hiringLike: true,
                candidateStatus: 'waiting for reply',
            });

            if (!updateRecommendationPrecandidateDocumentResponse.success) {
                throw new Error(updateRecommendationPrecandidateDocumentResponse.error);
            }

            // 3. Crear una nota

            const createNotesPreCandidateDocumentResponse = await createNotesPreCandidateDocument(preCandidate.idPreCandidate, {
                date: Timestamp.now(),
                description: ``,
                idNote: uid(24),
                idPrecandidate: preCandidate.idPreCandidate,
                status: EStatusNote.OPEN,
                title: position.title,
                type: ETypeNote.LIKE
            });

            if (!createNotesPreCandidateDocumentResponse.success) {
                throw new Error(createNotesPreCandidateDocumentResponse.error);
            }

            // 4. Enviar correo electronico al pre-candidato.
            if (!emailPreCandidate) return setVisibleModalNotification(true); setType("like");

            const contextEmail = {
                fullName: `${namePreCandidate} ${lastNamePreCandidate}`,
                companyName: position.company,
            }
            await fetchSendEmail(emailPreCandidate, EEmail.likeHiring, position.language === "English" ? EEmailLanguage.English : EEmailLanguage.French, contextEmail, tokenApi);
            setVisibleModalNotification(true);
            setType("like");
            saveLocalPrecandidate(idPreCandidate);
        } catch (error) {
            console.log(error)
        }
        setLoading(false);
    }
    return (
        <>
            <Button
                disabled={loading}
                onClick={() => handleOnLikePreCandidate(position, preCandidate.idPreCandidate, preCandidate.email, preCandidate.name, preCandidate.lastName, token)}
                style={{
                    borderColor: "transparent",
                    height: 50,
                    width: 150,
                    opacity: loading ? 0.4 : 1,
                }}
                icon={
                    <img
                        src={language === "English" ? iconBtnAccept : iconBtnAcceptFr}
                        style={{ height: 80, width: 180, marginTop: -10 }}
                        alt={iconBtnAccept}
                    />
                }
            />

            <NotificationInteractionSourcing
                visible={visibleModalNotification}
                closeModal={() => {
                    setVisibleModalNotification(false);
                    setType("like");
                }}
                type={type}
            />
        </>
    )
}

export default ButtonAccept
