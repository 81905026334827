import { Col, Row, Typography } from 'antd'
import React, { useCallback } from 'react'
import WorkplaceCheckBox from './WorplaceCheckBox'
import { useSelector } from 'react-redux';
import './WorkplaceSection.css'

interface IWorkplaceSectionProps {
    setWorkplace: (workplace: Array<string>) => void;
    workplace: Array<string>;
}
const WorkplaceSection: React.FC<IWorkplaceSectionProps> = ({ setWorkplace, workplace }) => {

    // States
    const { dictionary } = useSelector((state: any) => state.ui);

    const saveWorkplace = useCallback((arrayString: Array<string>) => {
        setWorkplace(arrayString);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row className='row-section-workplace-job'>
            <Col
                className='col-label-section-workplace-job'
                xs={{ span: 23 }} md={8} lg={5} xl={4}
            >
                <Typography.Text className='title'>
                    {dictionary["creation-modal-page-one-field-workplace"]}{" "}*
                </Typography.Text>
            </Col>

            <Col xs={24} md={15} className='col-select'>
                <WorkplaceCheckBox
                    saveWorkplace={saveWorkplace}
                    dataWorkplace={workplace}
                />
            </Col>
        </Row>
    )
}

export default WorkplaceSection