import { Timestamp } from "firebase/firestore";
import { PreCandidateInterface } from "../../../models/preCandidate.interface";
import { JobRecommendationPrecandidate } from "../../../types/position";

export interface INotes {
    idNote: string;
    idPrecandidate: string;
    title: string;
    description: string;
    date: Timestamp;
    createBy?: IRegisterActivity
    updateBy?: IRegisterActivity
    type: ETypeNote;
    status: EStatusNote;

}
export interface IRegisterActivity {
    id: string;
    typeUser: string;
    date: Timestamp;
}

export enum EStatusNote {
    OPEN = "open",
    COMPLETED = "completed",
    CANCELLED = "cancelled",
}

export enum ETypeNote {
    CALL = "call",
    EMAIL = 'email',
    FOLLOWUP = 'follow-up',
    MEETING = 'meeting',
    LINKEDIN = "linkedin",
    LINKEDINCONNECTION = 'linkedin-connection',
    LINKEDINMESSAGE = 'linkedin-message',
    LINKEDINVOICEMESSAGE = 'linkedin-voice-message',
    VOICEEMAIL = "voice-mail",
    LIKE = "like-hiring",
    DISLIKE = "dislike-hiring",
}

interface ErrorResponse {
    success: false;
    error: string;
}

interface SuccessResponseCreateRecommendationDocument {
    success: true;
    data: JobRecommendationPrecandidate;
}

interface SuccessResponseGetPreCandidateDocument {
    success: true;
    data: PreCandidateInterface;
}

interface SuccessResponseCreateNotesPreCandidateDocument {
    success: true;
    data: INotes;
}

export type CreateRecommendationDocumentResponse = SuccessResponseCreateRecommendationDocument | ErrorResponse;

export type GetPreCandidateDocumentResponse = SuccessResponseGetPreCandidateDocument | ErrorResponse;

// NotesPrecandidate Collect
export type CreateNotesPreCandidateDocumentResponse = SuccessResponseCreateNotesPreCandidateDocument | ErrorResponse;