//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
import { Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import onSiteEnable from "../../../../../image/btn-onsite-0-3x-green.png";
import remoteEnable from "../../../../../image/btn-remote-0-3x-green.png";
import hybridEnable from "../../../../../image/btn-hybrid-0-3x-green.png";
import { formatNumber } from '../../../../../hooks/hooks';

interface Props {
    location: any;
    salary: string;
    workplace: Array<string>;
    description: string;
    responsibilities: Array<any>;
    hourlyCheck: boolean;
    annualCheck: boolean;
    checkMin: boolean;
    checkMiddle: boolean;
    checkMax: boolean;
    salaryMax: string;
    percentageCheck: boolean;
    amountCheck: boolean;
    bonus: string;
    valueHourForWeek: number;
    otherDetailsCompensation: string
}
const { Text } = Typography;

const FirstSectionCarousel: React.FC<Props> = ({ salaryMax, percentageCheck, otherDetailsCompensation, amountCheck, bonus, valueHourForWeek, checkMin, checkMiddle, checkMax, location = {}, salary = "", workplace = [], description = "", responsibilities = [], annualCheck, hourlyCheck }) => {

    // States
    const [remoteCheck, setRemoteCheck] = useState<boolean>(false);
    const [onSiteCheck, setonSiteCheck] = useState<boolean>(false);
    const [hybridCheck, setHybridCheck] = useState<boolean>(false);
    const [totalSalary, settotalSalary] = useState<any>("");
    // Component Design

    const user = useSelector((state: any) => state.auth.user);

    useEffect(() => {
        workplace.forEach((each: any) => {
            if (each === 'Remote') {
                setRemoteCheck(true);
            } else if (each === 'Hybrid') {
                setHybridCheck(true);
            } else if (each === 'On-Site') {
                setonSiteCheck(true);
            }
        })
    }, [workplace]);

    const { dictionary } = useSelector((state: any) => state.ui);





    useEffect(() => {
        const numMin = parseFloat(salary.replace(/,/g, ""))
        const numMax = parseFloat(salaryMax.replace(/,/g, ""))
        const numMid = (numMin + numMax) / 2

        let numero1: number = checkMin === true ? numMin : checkMax === true ? numMax : checkMiddle === true ? numMid : numMid
        let numero2: number = parseFloat(bonus.replace(/,/g, ""))



        var numeroResult: number = 0;

        if (hourlyCheck === true && amountCheck === false && percentageCheck === false) {
            numeroResult = (((numero1 * valueHourForWeek) * 50) + 0);
            settotalSalary(numeroResult);
        } else {
            if (hourlyCheck === true) {
                if (amountCheck === true) {
                    numeroResult = (((numero1 * valueHourForWeek) * 50) + numero2);
                    settotalSalary(numeroResult);
                } else {
                    if (percentageCheck === true) {
                        numeroResult = ((numero1 * valueHourForWeek) * 50);
                        numeroResult = ((numeroResult + ((numero2 * numeroResult) / 100)))
                        settotalSalary(numeroResult);
                    }
                }
            }
        }



        if (annualCheck === true && amountCheck === false && percentageCheck === false) {
            numeroResult = (numero1);
            settotalSalary(numeroResult);
        } else {
            if (annualCheck === true) {
                if (amountCheck === true) {
                    numeroResult = numero1 + numero2;
                    settotalSalary(numeroResult);
                } else {
                    if (percentageCheck === true) {
                        numeroResult = numero1
                        numeroResult = ((numero1 + ((numero2 * numeroResult) / 100)))

                        settotalSalary(numeroResult);
                    }
                }
            }
        }

    }, [hourlyCheck, annualCheck, percentageCheck, amountCheck, salary, bonus, valueHourForWeek, salaryMax, checkMin, checkMiddle, checkMax,])


    return (
        <div className="contenedor" style={{ height: '90%', width: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
            <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingLeft: 50, paddingRight: 50 }}>
                <Col >
                    <Text className="font-project" style={{ fontSize: 18 }}>{user?.company} | {location}</Text>
                </Col>
            </Row>

            <Row style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Text className="font-project" style={{ fontSize: 26, color: "#6153d3" }}>${formatNumber(totalSalary.toString())}<Text className="font-project" style={{ fontSize: 18, color: "#6153d3" }}>{"("}{checkMin === true ? dictionary["new-job-modal-check-title1"] : checkMax === true ? dictionary["new-job-modal-check-title2"] : checkMiddle === true ? dictionary["new-job-modal-check-title4"] : ""}{")"}</Text> </Text>

            </Row>

            <Row style={{ width: '100%', marginTop: 5, display: 'flex', justifyContent: 'center' }}>

                {
                    onSiteCheck &&
                    <Col>
                        <img
                            alt='on site enable'
                            src={onSiteEnable}
                            style={{ height: 45, width: 'auto', margin: 0, paddingTop: 4 }}
                        />

                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Text className="font-project" style={{ fontSize: 18 }}>{dictionary['creation-modal-preview-field-on-site']}</Text>
                        </Row>
                    </Col>
                }

                {
                    hybridCheck &&
                    <Col offset={1}>
                        <Row>
                            <img
                                alt='hybrid enable'
                                src={hybridEnable}
                                style={{ height: 45, width: 'auto', margin: 0, paddingTop: 4 }}
                            />
                        </Row>

                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Text className="font-project" style={{ fontSize: 18 }}>{dictionary['creation-modal-preview-field-hybrid']}</Text>
                        </Row>
                    </Col>
                }



                {
                    remoteCheck &&
                    <Col offset={1} >
                        <img
                            alt='remote enable'
                            src={remoteEnable}
                            style={{ height: 45, width: 'auto', margin: 0, paddingTop: 4, justifySelf: 'center' }}
                        />

                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Text className="font-project" style={{ fontSize: 18 }}>{dictionary['creation-modal-preview-field-remote']}</Text>
                        </Row>
                    </Col>
                }
            </Row>

            <Row style={{ marginTop: 20, marginLeft: 30, marginRight: 30 }}>
                <Text className="font-project" style={{ fontSize: 18 }}>{dictionary['creation-modal-preview-field-summary']}</Text>

                <Row style={{ width: '100%' }}>
                    <div style={{ width: '98%', borderBottomLeftRadius: 4, borderBottomRightRadius: 4, backgroundColor: '#E7FBFF', padding: '3%' }}>
                        <Text className="font-project" style={{ fontSize: 18, display: 'inline-block' }}>{description}</Text>
                    </div>

                </Row>
            </Row>

            <Row style={{ marginTop: 20, marginLeft: 30, marginRight: 30 }}>
                <Text className="font-project" style={{ fontSize: 18 }}>{dictionary['creation-modal-preview-field-responsibilities']}</Text>

                <Row style={{ width: '100%' }}>
                    {
                        responsibilities.map((each, index) => (
                            <div key={each.value} style={{ width: '98%', borderBottomLeftRadius: 4, borderBottomRightRadius: 4, backgroundColor: '#E7FBFF', padding: '3%', marginTop: 10 }}>
                                <Text key={index + 'responsibilitiesText'} className="font-project" style={{ fontSize: 18, display: 'inline-block' }}>{each.value}</Text>
                            </div>
                        ))
                    }

                </Row>
            </Row>
        </div>
    )
}

export default FirstSectionCarousel