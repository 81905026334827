import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './LoadingPage.css';

// Personaliza el ícono de carga
const antIcon = <LoadingOutlined style={{ fontSize: 48, color: '#1890ff' }} spin />;

const LoadingPage: React.FC = () => {
    return (
        <div className="loading-screen">
            <Spin indicator={antIcon} />
            <h2 className='font-project'>Loading...</h2>
        </div>
    );
};

export default LoadingPage;
