//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React, { Dispatch, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { uid } from "uid";
// Ant Design
import { Modal, notification } from "antd";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getPositionSideBarSelected } from "../../../store/ui";

// Notification Component
import NotificationCloseModal from "../notificationModal/NotificationCloseModal";
import NotificationUpdatePositionModal from "../notificationModal/NotificationUpdatePositionModal";
import NotificationCloseUpdatePositionModal from "../notificationModal/NotificationCloseUpdateModal";

// Typescript
import User from "../../../types/user";

// Firebase
import {
  Timestamp,
  collection,
  collectionGroup,
  doc,
  getDocs,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase/config";

// Components
import FooterAddJobModal from "./footer/FooterAddJobModal";
import HeaderAddJobModal from "./header/HeaderAddJobModal";

import InitialStepCreationJob from "./steps/initialStep/InitialStepCreationModal";
import FirstStepCreationJob from "./steps/firstStep/FirstStepCreationJob";
import SecondStep from "./steps/secondStep/SecondStep";
import ThridStep from "./steps/thridStep/ThridStep";

import FinalStepCreationJob from "./steps/finalStep/FinalStepCreationJob";
import "../styles.css";
import "./createJobModal.css";

// Interface
interface Props {
  visibleModal: boolean;
  closeModal: () => void;
  user: User;
  data?: any;
}

type NotificationType = "success" | "info" | "warning" | "error";

// Component
const notificationModal = (
  type: NotificationType,
  message: string,
  description: string
) => {
  notification[type]({
    message: message,
    description: description,
  });
};

const CreateJobModal: React.FC<Props> = ({ user, visibleModal, closeModal, data }) => {
  const navigate = useNavigate();
  // States
  const [steps, setSteps] = useState<"Initial" | "First Step" | "Second Step" | "Third Step" | "Final Step">("Initial");
  const [nextStep, setNextStep] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [visibleModalConfirm, setvisibleModalConfirm] = useState<boolean>(false);
  const [visibleUpdatePositionModal, setVisibleUpdatePositionModal] = useState(false);
  const [visibleCloseUpdatePositionModal, setVisibleCloseUpdatePositionModal] = useState(false);

  // States First Step
  const [titleInput, setTitleInput] = useState<string>("");
  const [jobType, setJobType] = useState<Array<string>>([]);
  const [location, setLocation] = useState<string>("");
  const [workplace, setWorkplace] = useState<Array<string>>([]);
  const [checkHourly, setCheckHourly] = useState<boolean>(true);
  const [checkAnnual, setCheckAnnual] = useState<boolean>(false);
  const [checkAmount, setCheckAmount] = useState<boolean>(false);
  const [checkMin, setCheckMin] = useState<boolean>(true);
  const [checkMax, setCheckMax] = useState<boolean>(false);
  const [checkMiddle, setCheckMiddle] = useState<boolean>(false);
  const [checkBonnus, setCheckBonnus] = useState<boolean>(false);
  const [checkPercentage, setCheckPercentage] = useState<boolean>(false);
  const [salary, setSalary] = useState<string>("");
  const [salaryMax, setSalaryMax] = useState<string>("");
  const [totalSalary, setTotalSalary] = useState<string>("");
  const [places, setPlaces] = useState<any>({});
  const [otherDetailsCompensation, setOtherDetailsCompensation] = useState<string>("");
  const [valueHourForWeek, setValueHourForWeek] = useState<number>(40);

  const { dictionary, language } = useSelector((state: any) => state.ui);

  // States Second Step
  const [description, setDescription] = useState<string>("");
  const [responsibilities, setResponsibilities] = useState<Array<{ index: number; value: string }>>([]);

  // States Thrid Step
  const [requirements, setRequirements] = useState<Array<any>>([]);
  const [skills, setSkills] = useState<Array<any>>([]);

  const dispatch: Dispatch<any> = useDispatch();
  //Handlers
  const resetFields = () => {
    setTitleInput("");
    setJobType([]);
    setLocation("");
    setWorkplace([]);
    setDescription("");
    setRequirements([]);
    setSkills([]);
    setSalary("");
    setSalaryMax("");
    setTotalSalary("");
    setCheckAmount(false);
    setCheckMin(true);
    setCheckMiddle(false);
    setCheckMax(false);
    setCheckAnnual(false);
    setCheckPercentage(false);
    setCheckHourly(true);
    setResponsibilities([]);
    setOtherDetailsCompensation("");
    setValueHourForWeek(40);
    setSteps("Initial");
  };

  const formatSalary = (salaryString: string): string => {
    return salaryString.replace(/,/g, '');
  };

  const handleOnSaveJob = async (status: string) => {
    const idPosition = uid(20);
    const formattedSalary = formatSalary(salary);
    const formattedSalaryMax = formatSalary(salaryMax);
    const formattedTotalSalary = formatSalary(totalSalary);
    const newJob = {
      idHiring: user.idHiring,
      idPosition: idPosition,
      name: user.name,
      titleHiring: user?.title ? user?.title : "",
      lastName: user.lastName,
      location: location,
      company: user.company,
      companyInformation: user.companyInformation,
      createAt: Timestamp.now(),
      status: status,
      email: user.email,
      title: titleInput,
      salary: formattedSalary,
      totalSalary: formattedTotalSalary,
      salaryMax: formattedSalaryMax,
      checkBonnus: checkBonnus,
      checkMax: checkMax,
      checkMiddle: checkMiddle,
      checkMin: checkMin,
      workplace,
      jobType,
      description,
      responsibilities,
      technicalRequirements: requirements,
      skills,
      places: places,
      annual: checkAnnual !== undefined ? checkAnnual : false,
      hourly: checkHourly !== undefined ? checkHourly : false,
      percentage: checkPercentage !== undefined ? checkPercentage : false,
      amount: checkAmount !== undefined ? checkAmount : false,
      language: language,
      otherDetailsCompensation: otherDetailsCompensation,
      hourForWeek: valueHourForWeek,
    };

    try {
      validateExistFieldInDatabase(titleInput);
      const positionRef = doc(db, "hirings", user.idHiring, "positions", idPosition);

      await setDoc(positionRef, newJob);
      closeModal();
      resetFields();
      notificationModal("success", dictionary["alertMessage-text-title2"], "");
    } catch (error) {
      console.log(error);
    }
  };

  const validateExistFieldInDatabase = async (value: string) => {
    try {
      const titleList: Array<string> = [];
      const recommendationsRef = collectionGroup(db, "jobTitle");
      const querySnapshot = await getDocs(recommendationsRef);

      querySnapshot.docs.forEach((each) => each.data().title.length > 0 && titleList.push(each.data().title));

      const exist = titleList.find((a) => a === value);

      if (!exist) {
        const idTitle = uid(30);
        const jobTitleRef = doc(db, "autofill", "jobTitle", "jobTitle", idTitle);
        await setDoc(jobTitleRef, {
          function: "",
          id: idTitle,
          level: "",
          sector: "",
          title: titleInput,
          verified: false,
          language: language,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Controller Button Modal
  const handleOnNextStep = (actualStep: "Initial" | "First Step" | "Second Step" | "Third Step" | "Final Step") => {
    if (actualStep === "Initial") {
      setSteps("First Step");
      setNextStep(false);
    } else if (actualStep === "First Step") {
      setSteps("Second Step");
      setNextStep(false);
    } else if (actualStep === "Second Step") {
      setSteps("Third Step");
    } else if (actualStep === "Third Step") {
      setSteps("Final Step");
    }
  };

  const handleOnBackStep = (actualStep: "Initial" | "First Step" | "Second Step" | "Third Step" | "Final Step") => {
    if (actualStep === "First Step") {
      setSteps("Initial");
      resetFields();
    } else if (actualStep === "Second Step") {
      setSteps("First Step");
    } else if (actualStep === "Third Step") {
      setSteps("Second Step");
    } else if (actualStep === "Final Step") {
      setSteps("Third Step");
    }
  };

  // Handlers on Update status position
  const updatePositionStatus = (status: "open" | "close") => status === "close" && setVisibleUpdatePositionModal(true);

  const handleOnChangeStatusPosition = async () => {
    try {
      const positionRef = doc(db, "hirings", user.idHiring, "positions", data?.idPosition);

      await updateDoc(positionRef, { status: "close" });
      resetFields();
      setVisibleUpdatePositionModal(false);
      closeModal();

      const positionsRef = collection(db, "hirings", user.idHiring, "positions");

      // Create a query against the collection with the condition
      const q = query(positionsRef, where("status", "==", "open"), limit(1));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length === 0) {
        navigate("/dash");
        return
      }

      querySnapshot.docs.forEach((doc) => dispatch(getPositionSideBarSelected(doc.data())));

      notificationModal("success", "Job close successfully", "The Position " + data.title + " has been successfully closed.");
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnCloseModal = () => {
    if (data !== undefined) {
      setVisibleCloseUpdatePositionModal(true);
    } else {
      setvisibleModalConfirm(true);
    }
  };

  // Effects
  useEffect(() => {
    if (steps === "First Step") {
      if (
        titleInput?.length > 0 &&
        jobType?.length > 0 &&
        location?.length > 0 &&
        workplace?.length > 0 &&
        salary?.length > 0 &&
        salaryMax?.length > 0 &&
        parseFloat(salaryMax.replace(/,/g, "")) >
        parseFloat(salary.replace(/,/g, ""))
      ) {
        if (totalSalary?.length > 0) {
          if (checkAmount === false && checkPercentage === false) {
            setNextStep(true);
          } else if (checkAmount === true || checkPercentage === true) {
            setNextStep(false);
          }
        } else setNextStep(false);

        /* setNextStep(false); */
      } else setNextStep(true);
    } else if (steps === "Second Step") {
      description.length > 0 && responsibilities.length > 0
        ? setNextStep(false)
        : setNextStep(true);
    } else if (steps === "Third Step") {
      requirements.length > 0 && skills.length === 5
        ? setNextStep(false)
        : setNextStep(true);
    }
  }, [
    steps,
    titleInput?.length,
    jobType?.length,
    location,
    workplace,
    description?.length,
    responsibilities?.length,
    requirements?.length,
    skills?.length,
    salary?.length,
    totalSalary?.length,
    checkAmount,
    checkPercentage,
    salaryMax?.length,
    salary,
    salaryMax,
  ]);

  const handleOnUpdatePositionAfterClose = async (idPosition: string) => {
    try {
      const updateJob = {
        location: location,
        title: titleInput,
        salary: salary,
        totalSalary: totalSalary,
        workplace,
        jobType,
        description,
        responsibilities,
        technicalRequirements: requirements,
        skills,
        annual: checkAnnual,
        hourly: checkHourly,
        percentage: checkPercentage,
        amount: checkAmount,
        salaryMax: salaryMax,
        checkBonnus: checkBonnus,
        checkMax: checkMax,
        checkMiddle: checkMiddle,
        checkMin: checkMin,
        otherDetailsCompensation: otherDetailsCompensation,
        hourForWeek: valueHourForWeek,
      };

      const positionRef = doc(
        db,
        "hirings",
        user?.idHiring,
        "positions",
        idPosition
      );

      await updateDoc(positionRef, updateJob);

      resetFields();
      setVisibleCloseUpdatePositionModal(false);
      closeModal();
      notificationModal("success", dictionary["alertMessage-text-title2"], "");
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnUpdatePositionData = async (idPosition: string) => {
    try {
      const updateJob = {
        title: titleInput,
        salary: salary,
        totalSalary: totalSalary,
        location,
        workplace,
        jobType,
        description,
        responsibilities,
        technicalRequirements: requirements,
        skills,
        annual: checkAnnual,
        hourly: checkHourly,
        salaryMax: salaryMax,
        checkBonnus: checkBonnus,
        checkMax: checkMax,
        checkMiddle: checkMiddle,
        checkMin: checkMin,
        percentage: checkPercentage,
        amount: checkAmount,
        otherDetailsCompensation: otherDetailsCompensation,
        hourForWeek: valueHourForWeek,
      };

      const positionRef = doc(db, "hirings", user?.idHiring, "positions", idPosition);

      await updateDoc(positionRef, updateJob);

      closeModal();
      resetFields();
      notificationModal("success", dictionary["alertMessage-text-title2"], "");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Actualizar informacion de un puesto de trabajo
    if (data !== undefined) {
      setSteps("First Step");
      setSalary(data?.salary ? data?.salary : "");
      setSalaryMax(data?.salaryMax ? data?.salaryMax : "");
      setCheckMin(data?.checkMin !== undefined ? data?.checkMin : false);
      setCheckMiddle(data?.checkMiddle !== undefined ? data?.checkMiddle : false);
      setCheckMax(data?.checkMax !== undefined ? data?.checkMax : false);
      setTitleInput(data?.title ? data?.title : "");
      setCheckAmount(data?.amount !== undefined ? data?.amount : false);
      setCheckAnnual(data?.annual !== undefined ? data?.annual : false);
      setCheckPercentage(data?.percentage !== undefined ? data?.percentage : false);
      setCheckHourly(data?.hourly !== undefined ? data?.hourly : false);
      setLocation(data?.location ? data?.location : "");
      setTotalSalary(data?.totalSalary ? data?.totalSalary : "");
      setJobType(data?.jobType ? data?.jobType : []);
      setWorkplace(data?.workplace ? data?.workplace : []);
      setDescription(data?.description ? data?.description : "");
      setResponsibilities(data?.responsibilities ? data?.responsibilities : []);
      setRequirements(data?.technicalRequirements ? data?.technicalRequirements : []);
      setSkills(data?.skills ? data?.skills : []);
      setUpdate(true);
      setOtherDetailsCompensation(data?.otherDetailsCompensation ? data?.otherDetailsCompensation : "");
      setValueHourForWeek(data?.hourForWeek ? data?.hourForWeek : 40);
    }
  }, [data]);

  return (
    <Modal
      open={visibleModal}
      afterClose={() => {
        setSteps("First Step");
        resetFields();
      }}
      centered
      width="80vw"
      closable={false}
      destroyOnClose={true}
      onCancel={handleOnCloseModal}
      footer={
        <FooterAddJobModal
          data={data}
          handleOnBackStep={handleOnBackStep}
          steps={steps}
          handleOnUpdatePositionData={handleOnUpdatePositionData}
          nextStep={nextStep}
          handleOnSaveJob={handleOnSaveJob}
          handleOnNextStep={handleOnNextStep}
        />
      }
    >
      <div className="div-container-add-job-modal">
        <HeaderAddJobModal
          closeModal={closeModal}
          data={data}
          handleOnCloseModal={handleOnCloseModal}
          resetFields={resetFields}
          steps={steps}
        />

        <div className="div-body-add-job-modal">
          {steps === "Initial" ? (
            <InitialStepCreationJob
              setTitle={(title: string) => setTitleInput(title)}
              setType={(typeList: Array<string>) => setJobType(typeList)}
              setLocation={(value: string) => {
                setLocation(value);
              }}
              setWorkplace={(workplaceData: Array<string>) =>
                setWorkplace(workplaceData)
              }
              setSalary={(salary: string) => setSalary(salary)}
              setSalaryMax={(salaryMax: string) => setSalaryMax(salaryMax)}
              setTotalSalary={setTotalSalary}
              setDescription={(value: string) => setDescription(value)}
              setResponsibilities={(value: Array<any>) =>
                setResponsibilities(value)
              }
              setRequirements={(value: Array<any>) => setRequirements(value)}
              setSkills={(value: Array<any>) => setSkills(value)}
              handleOnNextStep={handleOnNextStep}
              checkHourly={checkHourly}
              setCheckHourly={setCheckHourly}
              checkAmount={checkAmount}
              setCheckAmount={setCheckAmount}
              checkAnnual={checkAnnual}
              setCheckAnnual={setCheckAnnual}
              checkPercentage={checkPercentage}
              setCheckPercentage={setCheckPercentage}
              setOtherDetailsCompensation={setOtherDetailsCompensation}
              otherDetailsCompensation={otherDetailsCompensation}
              valueHourForWeek={valueHourForWeek}
              setValueHourForWeek={setValueHourForWeek}
            />
          ) : steps === "First Step" ? (
            <FirstStepCreationJob
              update={update}
              setPlaces={setPlaces}
              titleInput={titleInput}
              setTitle={(title: string) => setTitleInput(title)}
              type={jobType}
              setType={(typeList: Array<string>) => setJobType(typeList)}
              location={location}
              setLocation={(value: string) => {
                setLocation(value);
              }}
              workplace={workplace}
              setWorkplace={(workplaceData: Array<string>) =>
                setWorkplace(workplaceData)
              }
              setSalary={(salary: any) => setSalary(salary)}
              salary={salary}
              setSalaryMax={(salaryMax: any) => setSalaryMax(salaryMax)}
              salaryMax={salaryMax}
              checkHourly={checkHourly}
              setCheckHourly={setCheckHourly}
              checkMin={checkMin}
              setCheckMin={setCheckMin}
              checkMax={checkMax}
              setCheckMax={setCheckMax}
              checkMiddle={checkMiddle}
              setCheckMiddle={setCheckMiddle}
              checkBonnus={checkBonnus}
              setCheckBonnus={setCheckBonnus}
              checkAmount={checkAmount}
              setCheckAmount={setCheckAmount}
              checkAnnual={checkAnnual}
              setCheckAnnual={setCheckAnnual}
              checkPercentage={checkPercentage}
              setCheckPercentage={setCheckPercentage}
              totalSalary={totalSalary}
              setTotalSalary={setTotalSalary}
              updatePositionStatus={updatePositionStatus}
              otherDetailsCompensation={otherDetailsCompensation}
              setOtherDetailsCompensation={setOtherDetailsCompensation}
              setValueHourForWeek={setValueHourForWeek}
              valueHourForWeek={valueHourForWeek}
            />
          ) : steps === "Second Step" ? (
            <SecondStep
              description={description}
              setDescription={setDescription}
              responsibilities={responsibilities}
              setResponsibilities={setResponsibilities}
            />
          ) : steps === "Third Step" ? (
            <ThridStep
              requirements={requirements}
              setRequirements={(value: Array<any>) => setRequirements(value)}
              skills={skills}
              setSkills={(value: Array<any>) => setSkills(value)}
            />
          ) : (
            steps === "Final Step" && (
              <FinalStepCreationJob
                titleInput={titleInput}
                description={description}
                jobType={jobType}
                location={location}
                requirements={requirements}
                responsibilities={responsibilities}
                salary={salary}
                skills={skills}
                workplace={workplace}
                totalSalary={totalSalary}
                annualOrHourly={checkAnnual}
                amountCheck={checkAmount}
                annualCheck={checkAnnual}
                hourlyCheck={checkHourly}
                checkMin={checkMin}
                checkMax={checkMax}
                checkMiddle={checkMiddle}
                salaryMax={salaryMax}
                percentageCheck={checkPercentage}
                otherDetailsCompensation={otherDetailsCompensation}
                valueHourForWeek={valueHourForWeek}
              />
            )
          )}

          <NotificationCloseModal
            visible={visibleModalConfirm}
            closeModal={() => setvisibleModalConfirm(false)}
            closeCreateJobModal={() => closeModal()}
          />

          <NotificationUpdatePositionModal
            visible={visibleUpdatePositionModal}
            updateJobPositionStatus={() => handleOnChangeStatusPosition()}
            closeModal={async () => {
              await setVisibleUpdatePositionModal(false);
              closeModal();
              resetFields();
            }}
          />

          <NotificationCloseUpdatePositionModal
            onCancel={async () => {
              setVisibleCloseUpdatePositionModal(false);
            }}
            onClickDontSaveButton={async () => {
              await setVisibleCloseUpdatePositionModal(false);
              closeModal();
              resetFields();
            }}
            visible={visibleCloseUpdatePositionModal}
            onClickSaveButton={() =>
              handleOnUpdatePositionAfterClose(data?.idPosition)
            }
          />
        </div>
      </div>
    </Modal>
  );
};

// Exports
export default CreateJobModal;
