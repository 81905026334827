import { Input, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import './RequirementSection.css'

interface IRequirementSectionProps {
    requirementText: string;
    setRequirementText: (value: string) => void;
    onBlur: () => void;
}

const RequirementSection: React.FC<IRequirementSectionProps> = ({ requirementText, setRequirementText, onBlur }) => {
    const { dictionary } = useSelector((state: any) => state.ui);
    return (
        <div className='div-section-requirement-job'>
            <Typography.Text className='label-requirement'>
                {dictionary["creation-modal-page-three-field-requirements"]}{' '}*
            </Typography.Text>

            <div className='div-input-requirement'>
                <Input.TextArea
                    rows={8}
                    value={requirementText}
                    onChange={(e) => setRequirementText(e.target.value)}
                    showCount
                    onBlur={onBlur}
                    placeholder={dictionary["creation-modal-page-two-placeholder-job-summary"]}
                    className='input-requirement-section'
                    allowClear
                    variant='borderless'
                    count={{
                        show: true,
                        max: 2500,
                    }}
                />
            </div>
        </div>
    );
};

export default RequirementSection;
