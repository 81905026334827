import React from "react";
import { Button, Typography } from "antd";
import { Timestamp } from "firebase/firestore";
import { IPosition } from "../../../../../models/hiring";
import { useSelector } from "react-redux";
import { getTranslation } from "../../../../../hooks/hooks";
import PreviewJobButton from "./PreviewJobButton";
import './FloatingPositionCard.css';

interface IFloatingPositionCardProps {
    position: IPosition;
    handleOnCloneJob: (value: any) => void;
    valueHourForWeek: number;
}

const FloatingPositionCard: React.FC<IFloatingPositionCardProps> = ({ position, handleOnCloneJob, valueHourForWeek }) => {


    const { dictionary } = useSelector((state: any) => state.ui);
    const formatDate = (timestamp: Timestamp) => {
        if (!timestamp) return null;
        const date = new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    };

    return (
        <div className="floating-box-bottom-right">
            <div className="floating-box-content">
                <Typography.Text className="text-title-floating-position-card">{dictionary["creation-modal-initial-page-title-job-selected"]}:
                    <Typography.Text className="text-title-description-floating-position-card">
                        {position?.title}
                    </Typography.Text>
                </Typography.Text>

                <Typography.Text className="text-title-floating-position-card">{dictionary["creation-modal-initial-page-create-at-job-selected"]}:
                    <Typography.Text className="text-title-description-floating-position-card">
                        {position?.createAt && (formatDate(position.createAt))}
                    </Typography.Text>
                </Typography.Text>

                <hr className="floating-box-divider" />

                <div className="floating-box-actions">
                    <PreviewJobButton handleOnCloneJob={handleOnCloneJob} positionSelected={position} valueHourForWeek={valueHourForWeek} />
                    <Button
                        className="button-orange-v2"
                        onClick={() => handleOnCloneJob(position)}
                    >
                        {getTranslation('creation-modal-initial-page-button-use-template', dictionary)}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default FloatingPositionCard;
