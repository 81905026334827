import React, { useCallback, useEffect, useState } from 'react'

// Icons

import iconRight from "../../image/ico-next.png";
import iconLeft from "../../image/ico-prev.png";
import { Button, Col, Row, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import ButtonAccept from './ButtonAccept';
import { Job } from '../../types/position';
import { PreCandidateInterface } from '../../models/preCandidate.interface';
import User from '../../types/user';
import ButtonDecline from './ButtonDecline';
import SkillSectionCandidateCard from '../candidateCard/SkillSectionCandidateCard';
import { Timestamp } from 'firebase/firestore';
import EducationSectionCandidateCard from '../candidateCard/EducationSectionCandidateCard';
import ExperienceSectionCandidateCard from './ExperienceSectionCandidateCard';
import './PreCandidateCard.css'
import { useSelector } from 'react-redux';
import { getPreCandidateDocument } from '../../firebase/services/precandidateCollect/precandidate';
import LoadingCard from '../../components/recommendation/LoadingCard';

// Interface
interface Props {
    prev: () => void;
    next: () => void;
    position: Job;
    user: User
    idPrecandidate: string;
    saveLocalPrecandidate: (idPrecandidate: string) => void;
    currentIndex: number;
    total: number;
}
const PreCandidateCard: React.FC<Props> = ({ prev, next, position, user, idPrecandidate, saveLocalPrecandidate, currentIndex, total }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [loadingOnClickButton, setLoadingOnClickButton] = useState<boolean>(false);
    // States
    const [experience, setExperience] = useState<Array<any>>([]);
    const [education, setEducation] = useState<Array<any>>([]);
    const [skill, setSkill] = useState<Array<any>>([]);
    const [precandidateCardData, setPrecandidateCardData] = useState<PreCandidateInterface | null>(null)

    const setValues = useCallback(async (idPrecandidate) => {
        try {
            setLoading(true);
            const getPreCandidateDocumentResponse = await getPreCandidateDocument(idPrecandidate);

            if (!getPreCandidateDocumentResponse.success) {
                throw new Error(getPreCandidateDocumentResponse.error);
            }

            const precandidate = getPreCandidateDocumentResponse.data as PreCandidateInterface;
            setPrecandidateCardData(precandidate);
            precandidate.skills && setSkill(precandidate.skills);

            const experiences: Array<any> = [];
            const education: Array<any> = [];

            precandidate.experience?.forEach((experienceData: any) => {
                const startFullYear = new Timestamp(
                    experienceData.dateStart.seconds,
                    experienceData.dateStart.nanoseconds
                )
                    .toDate()
                    .getFullYear();

                if (experienceData.actualPosition) {
                    experiences.push({
                        ...experienceData,
                        dateStart: startFullYear,
                        dateEnd: Timestamp.now().toDate().getFullYear(),
                        dateEndComplete: Timestamp.now().seconds,
                    });
                } else {
                    const endFullYear = new Timestamp(
                        experienceData.dateEnd.seconds,
                        experienceData.dateEnd.nanoseconds
                    )
                        .toDate()
                        .getFullYear();

                    experiences.push({
                        ...experienceData,
                        dateStart: startFullYear,
                        dateEnd: endFullYear,
                        dateEndComplete: experienceData.dateEnd.seconds,
                    });
                }
            });

            precandidate.education?.forEach((educationData: any) => {
                if (educationData.graduationDate) {
                    const graduationDate = new Timestamp(educationData.graduationDate.seconds, educationData.graduationDate.nanoseconds).toDate().getFullYear();

                    education.push({
                        ...educationData,
                        dateGraduationDateOk: graduationDate,
                    });
                } else {
                    education.push({
                        ...educationData,
                        dateGraduationDateOk: new Date(),
                    });
                }
            });

            setExperience(
                experiences.sort((a, b) => b.dateEndComplete - a.dateEndComplete)
            );
            setEducation(
                education.sort(
                    (a, b) => b.dateGraduationDateOk - a.dateGraduationDateOk
                )
            );
            setLoading(false);


        } catch (error) {
            console.log(error)
        }
    }, [],);


    // Effects
    useEffect(() => {
        setValues(idPrecandidate)
        return () => {

        }
    }, [setValues, idPrecandidate])

    const auth = useSelector((state: any) => state.auth.user);

    return (
        <>
            {
                loading ? (
                    <LoadingCard />
                ) : (
                    <Content className='content-pre-candidate-card'>
                        {(auth.idHiring === 'SLUJ8Q9u4Sg0ms501LxmkrIBVQs2' || auth.idHiring === 'Zc0XfBuSlPTg0baD9AUpD7IwOZd2' || auth.idHiring === 'B4gnmnLbzONBJ8YF4fXJu6CzJUX2') && (
                            <div
                                style={{
                                    display: 'flex',
                                    position: 'absolute',
                                    top: 10,
                                    left: 10,
                                    zIndex: 1,
                                    background: 'linear-gradient(16deg, rgb(96, 82, 163), rgb(50, 45, 90))',
                                    boxSizing: 'border-box',
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    borderRadius: '20px'
                                }}
                            >
                                <Typography.Text style={{ color: 'white', fontSize: 20, fontWeight: 'bold' }}>
                                    Pre Candidate
                                </Typography.Text>
                            </div>
                        )}

                        <Row className='row-experience-pre-candidate-card'>
                            <Col className='col-experience-pre-candidate-card' xs={24} sm={24} md={24} lg={24} xl={{ span: 12 }} xxl={13}>
                                <ExperienceSectionCandidateCard experience={experience} />
                            </Col>
                            <Col className='col-education-pre-candidate-card' xs={24} sm={24} md={24} lg={24} xl={{ span: 12 }} xxl={11}>
                                <EducationSectionCandidateCard education={education} />
                            </Col>
                        </Row>

                        <Row className='row-skills-pre-candidate-card'>
                            <Col xs={24}>
                                <SkillSectionCandidateCard skill={skill} />
                            </Col>
                        </Row>

                        <Row className='row-buttons'>
                            <Col xs={24} sm={12} md={6} lg={6} xxl={6}
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    paddingLeft: 50,
                                }}
                            >
                                {total > 1 && currentIndex > 0 && (
                                    <Button
                                        disabled={loadingOnClickButton}
                                        onClick={prev}
                                        style={{
                                            borderColor: "transparent",
                                            height: 40,
                                            width: 40,
                                            opacity: loadingOnClickButton ? 0.4 : 1,
                                        }}
                                        icon={
                                            <img
                                                src={iconLeft}
                                                style={{ height: 40, width: 40, opacity: 0.5 }}
                                                alt={iconLeft}
                                            />
                                        }
                                    />

                                )}
                            </Col>

                            <Col
                                xs={24}
                                sm={12}
                                md={6}
                                lg={6}
                                xxl={6}
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {
                                    precandidateCardData &&
                                    <ButtonAccept saveLocalPrecandidate={saveLocalPrecandidate} loading={loadingOnClickButton} position={position} preCandidate={precandidateCardData} setLoading={setLoadingOnClickButton} user={user} />
                                }
                            </Col>

                            <Col
                                xs={24}
                                sm={12}
                                md={6}
                                lg={6}
                                xxl={6}
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {precandidateCardData &&
                                    <ButtonDecline saveLocalPrecandidate={saveLocalPrecandidate} loading={loadingOnClickButton} position={position} preCandidate={precandidateCardData} setLoading={setLoadingOnClickButton} user={user} />
                                }
                            </Col>

                            <Col
                                xs={24}
                                sm={12}
                                md={6}
                                lg={6}
                                xxl={6}
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    paddingRight: 50,
                                }}
                            >
                                {
                                    total > 1 && currentIndex < total - 1 && (
                                        <Button
                                            disabled={loadingOnClickButton}
                                            onClick={next}
                                            style={{
                                                borderColor: "transparent",
                                                height: 40,
                                                width: 40,
                                                opacity: loadingOnClickButton ? 0.4 : 1,
                                            }}
                                            icon={
                                                <img
                                                    src={iconRight}
                                                    style={{ height: 40, width: 40, opacity: 0.7 }}
                                                    alt={iconRight}
                                                />
                                            }
                                        />
                                    )
                                }
                            </Col>
                        </Row>
                    </Content>
                )
            }
        </>
    )
}

export default PreCandidateCard
