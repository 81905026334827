import { Checkbox, Col, Radio, Row, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';
import './SalaryDisplaySection.css'

interface ISalaryDisplaySectionProps {
    checkMin: boolean;
    setCheckMin: (checkMin: boolean) => void;
    checkMiddle: boolean;
    setCheckMiddle: (checkMiddle: boolean) => void;
    checkMax: boolean;
    setCheckMax: (checkMax: boolean) => void;
    checkBonnus: boolean;
    setCheckBonnus: (checkBonnus: boolean) => void;

}
const SalaryDisplaySection: React.FC<ISalaryDisplaySectionProps> = ({ checkMax, checkMiddle, checkMin, setCheckMax, setCheckMiddle, setCheckMin, checkBonnus, setCheckBonnus }) => {
    const { dictionary } = useSelector((state: any) => state.ui);

    const onChangeMin = () => {
        if (checkMin !== true) {
            setCheckMin(true);
            setCheckMiddle(false);
            setCheckMax(false);
        }
    };
    const onChangeMiddle = () => {
        if (checkMiddle !== true) {
            setCheckMin(false);
            setCheckMiddle(true);
            setCheckMax(false);
        }
    };
    const onChangeMax = () => {
        if (checkMax !== true) {
            setCheckMin(false);
            setCheckMiddle(false);
            setCheckMax(true);
        }
    };

    const onChangeBonnus = () => setCheckBonnus(!checkBonnus);
    return (
        <Row className='row-section-display-job'>
            <Col className='col-label-section-display-job'
                xs={{ span: 24 }} sm={24} md={24} lg={6} xl={5}
            >
                <Typography.Text className='label-display-section'>
                    {dictionary["new-job-modal-text-title3"]}{" "}*
                </Typography.Text>
            </Col>

            <Col
                className='col-checkbox'
                xs={{ span: 11, offset: 1 }}
                sm={{ span: 4, offset: 1 }}
                md={{ span: 4, offset: 1 }}
                lg={{ span: 4, offset: 1 }}
                xl={{ span: 2, offset: 0 }}
            >
                <Radio
                    className="custom-radio-purple label-display-section"
                    checked={checkMin}
                    onChange={onChangeMin}
                >
                    {dictionary["new-job-modal-check-title1"]}
                </Radio>
            </Col>

            <Col
                className='col-checkbox'
                xs={{ span: 11, offset: 1 }}
                sm={{ span: 4, offset: 1 }}
                md={{ span: 4, offset: 1 }}
                lg={{ span: 3, offset: 0 }}
                xl={{ span: 2, offset: 0 }}
            >
                <Radio
                    className="custom-radio-purple label-display-section"
                    checked={checkMiddle}
                    onChange={onChangeMiddle}
                >
                    {dictionary["new-job-modal-check-title4"]}
                </Radio>
            </Col>

            <Col
                className='col-checkbox'
                xs={{ span: 11, offset: 1 }}
                sm={{ span: 4, offset: 1 }}
                md={{ span: 4, offset: 1 }}
                lg={{ span: 3, offset: 0 }}
                xl={{ span: 3, offset: 0 }}
            >
                <Radio
                    className="custom-radio-purple label-display-section"
                    checked={checkMax}
                    onChange={onChangeMax}
                >
                    {dictionary["new-job-modal-check-title2"]}
                </Radio>
            </Col>

            <Col
                className='col-checkbox'
                xs={{ span: 11, offset: 1 }}
                sm={{ span: 4, offset: 1 }}
                md={{ span: 4, offset: 1 }}
                lg={{ span: 3, offset: 0 }}
                xl={{ span: 2, offset: 0 }}
            >
                <Checkbox
                    className="custom-checkbox label-display-section"
                    checked={checkBonnus}
                    onChange={onChangeBonnus}
                >
                    {dictionary["new-job-modal-check-title3"]}
                </Checkbox>
            </Col>

        </Row>
    )
}

export default SalaryDisplaySection