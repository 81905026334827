import { Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';
import './SkillSection.css'
import SkillList from './SkillList';
interface ISkillSectionProps {
    skills: Array<any>;
    setSkills: (value: Array<any>) => void;
}
const SkillSection: React.FC<ISkillSectionProps> = ({ setSkills, skills }) => {

    const { dictionary } = useSelector((state: any) => state.ui);

    return (
        <div className='div-section-skill-job'>
            <Typography.Text className='label-skill'>
                {dictionary["creation-modal-page-three-field-skill"]}{' '}*
            </Typography.Text>

            <div className='div-input-skill'>
                <SkillList setSkill={setSkills} skill={skills} />
            </div>
        </div>
    )
}

export default SkillSection