import { Button, Row } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';

interface IFooterPreviewJobModalProps {
    handleOnCloneJob: () => void;
}
const FooterPreviewJobModal: React.FC<IFooterPreviewJobModalProps> = ({ handleOnCloneJob }) => {
    const { dictionary } = useSelector((state: any) => state.ui);
    return (
        <Row
            style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                backgroundColor: 'var(--color-light-blue)',
            }}
        >
            <Button
                className='button-orange-v1'
                onClick={handleOnCloneJob}
            >
                {dictionary["creation-modal-initial-page-button-go"]}
            </Button>
        </Row>
    )
}

export default FooterPreviewJobModal