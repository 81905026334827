import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { clearUserSelected } from '../../../../store/job';
import { clearFilterJob, clearMessageList, clearPositionSelect, clearUserChatSelected, getFilterJob, startGetMessageInboxList, startGetMessageUnreadCounter, startGetPositionListChat } from '../../../../store/chat';
import { assets } from '../../../../assets/assets';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../../../firebase/config';

// Component
const MessageButton = () => {

    // Store Tools
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    // Store Redux
    const userChatSelected = useSelector((state: any) => state.chat.userChatSelected);
    const { token } = useSelector((state: any) => state.auth);
    const user = useSelector((state: any) => state.auth.user);


    // States
    const [messagesPending, setMessagesPending] = useState<boolean>(false);

    const goToMessageScreen = async () => {
        location.pathname === "/jobList" && dispatch(clearUserSelected());

        if (location.pathname === "/messages") {
            //Clear chat selected
            dispatch(clearUserChatSelected());
            //Clear filters
            dispatch(clearFilterJob());
            // Clear position message selected
            dispatch(clearPositionSelect());
            dispatch(clearMessageList());
        } else {
            dispatch(startGetMessageUnreadCounter(user));

            dispatch(startGetMessageInboxList(user, token));
            dispatch(startGetPositionListChat(user));


            dispatch(getFilterJob("Inbox"));

            navigate("/messages");
        }
    };

    useEffect(() => {
        const messagesPending = [];

        const messagesCollectionRef = collection(db, "hirings", user?.idHiring, "messages");
        const messagesQuery = query(messagesCollectionRef, where("messagesPending", "==", true))

        const unsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
            querySnapshot.forEach((messageDoc) => {
                const messageData = messageDoc.data();
                if (messageData.id !== 'huiCo2D8l6ZY7NuyNruK6NH6CCG3') {
                    messagesPending.push(messageData);
                }
            });

            if (messagesPending.length > 0) {
                setMessagesPending(true);
            } else {
                setMessagesPending(false);
            }
        });

        return () => unsubscribe();
    }, [userChatSelected, user?.idHiring]);
    return (
        <div style={{ position: 'relative', display: 'flex', height: '100%', flexDirection: 'row', alignItems: 'center' }}>
            <Button
                onClick={goToMessageScreen}
                style={{
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                }}
                icon={
                    <img
                        src={assets.iconMessage}
                        style={{ height: 25, width: "auto" }}
                        alt={assets.iconMessage}
                    />
                }
            />
            {
                messagesPending && (
                    <div style={{ height: 15, width: 15, backgroundColor: '#D12525', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 10, position: "absolute", top: 17, right: -3 }}></div>
                )
            }

        </div>
    )
}

export default MessageButton