import { Col, Typography } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import CreateJobModal from '../../../modals/createJobModal/CreateJobModal';
import { assets } from '../../../../assets/assets';
import './AddJobButton.css'

const { Text } = Typography;
const AddJobButton = () => {
    const { dictionary } = useSelector((state: any) => state.ui);
    const { user } = useSelector((state: any) => state.auth);

    const [visible, setVisible] = useState<boolean>(false);
    return (
        <>
            <div
                className='div-button-add-job'
                onClick={() => setVisible(true)}
            >
                <Col style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: "center" }} xs={6} sm={6} md={6} lg={6} xxl={6}>
                    <img
                        src={assets.iconAddJob}
                        style={{ height: 25, width: 'auto' }}
                        alt={assets.iconAddJob}
                    />
                </Col>
                <Col style={{ height: "100%", display: 'flex', alignItems: 'center', paddingLeft: 3 }} xs={18} sm={18} md={18} lg={18} xxl={18}>
                    <Text style={{ color: "white", fontSize: 14, fontWeight: "bold" }} className="font-project">
                        {dictionary['sidebar-button-title-add-new-job']}
                    </Text>
                </Col>
            </div>

            <CreateJobModal closeModal={() => setVisible(false)} user={user} visibleModal={visible} />

        </>
    )
}

export default AddJobButton