import React from 'react'
import './TitleSection.css'
import { Typography } from 'antd';
interface ITitleSectionProps {
    title: string;
}
const TitleSection: React.FC<ITitleSectionProps> = ({ title }) => {
    return (
        <div className='div-title-section'>
            <Typography.Text className='label-title-section'>
                {title}
            </Typography.Text>
        </div>
    )
}

export default TitleSection