//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Button, Col, Modal, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useSelector } from "react-redux";
import './index.css'
// Interface
interface Props {
  visibleModal: boolean;
  setVisibleModal: (value: boolean) => void;
  setVisibleCreateJobModal: (value: boolean) => void;
}
// Component Ant Design
const { Text } = Typography;
// Component
const WelcomeModal: React.FC<Props> = ({
  visibleModal,
  setVisibleModal,
  setVisibleCreateJobModal,
}) => {
  // States
  const { dictionary } = useSelector((state: any) => state.ui);

  return (
    <Modal
      width={"80%"}
      open={visibleModal}
      centered
      onCancel={() => setVisibleModal(false)}
      closable={false}
      footer={<Row style={{ width: "100%" }}></Row>}
    >
      <Content
        className="contenedor"
        style={{ height: "80vh", width: "100%", overflow: "auto" }}
      >
        <Row style={{ width: "100%" }}>
          <Col xxl={{ span: 16, offset: 4 }}>
            <Row style={{ width: "100%", padding: 50 }}>
              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 1,
                }}
              >
                <Text
                  style={{
                    fontSize: 26,
                    fontFamily: "Mulish",
                    textAlign: "center",
                  }}
                >
                  {dictionary["modal-welcome-title1"]}
                </Text>
              </Row>

              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 26,
                    fontFamily: "Mulish",
                    textAlign: "center",
                  }}
                >
                  {dictionary["modal-welcome-title2"]}
                </Text>
              </Row>
            </Row>

            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: 50,
              }}
            >
              <Button
                className="button-create-new-job"
                onClick={() => {
                  setVisibleCreateJobModal(true);
                  setVisibleModal(false);
                }}
              >
                {dictionary["modal-welcome-button-create-job"]}
              </Button>
            </Row>

            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: 10,
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <Text
                style={{
                  fontSize: 26,
                  fontFamily: "Mulish",
                  textAlign: "center",
                  width: "70%",
                }}
              >
                {dictionary["modal-welcome-title3"]}
              </Text>
            </Row>
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: 10,
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <Text
                style={{
                  fontSize: 26,
                  fontFamily: "Mulish",
                  textAlign: "center",
                  width: "70%",
                }}
              >
                {dictionary["modal-welcome-title4"]}
              </Text>
            </Row>
          </Col>
        </Row>
      </Content>
    </Modal>
  );
};

export default WelcomeModal;
