import { Input, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';
import './OtherDetailCompensationSection.css'

interface IOtherDetailCompensationSectionProps {
    otherDetailsCompensation: string;
    setOtherDetailsCompensation: (value: string) => void;
}
const OtherDetailCompensationSection: React.FC<IOtherDetailCompensationSectionProps> = ({ otherDetailsCompensation, setOtherDetailsCompensation }) => {

    const { dictionary } = useSelector((state: any) => state.ui);
    return (
        <div className='div-section-other-job'>
            <Typography.Text className='label-other-details-compensation'>
                {
                    dictionary[
                    "creation-modal-page-one-field-other-details-compensation"
                    ]
                }
            </Typography.Text>
            <div>
                <Input.TextArea
                    rows={8}
                    value={otherDetailsCompensation}
                    placeholder={
                        dictionary[
                        "creation-modal-page-one-placeholder-other-details-compensation"
                        ]
                    }
                    className='input-other-section'
                    allowClear
                    variant='borderless'
                    onChange={(e) => setOtherDetailsCompensation(e.target.value)}
                />
            </div>
        </div>
    )
}

export default OtherDetailCompensationSection