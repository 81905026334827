import { Button } from 'antd'
import React from 'react'
import { assets } from '../../../../assets/assets'
import { logoutFirebase } from '../../../../firebase/providers';
import { logout } from '../../../../store/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {

    const dispatch: any = useDispatch();
    const navigate = useNavigate();


    const handleOnLogout = () => {
        logoutFirebase();
        dispatch(logout());
        navigate("/login");
    }
    return (
        <Button
            onClick={handleOnLogout}
            style={{
                borderColor: "transparent",
                backgroundColor: "transparent",
            }}
            icon={<img src={assets.iconLogout} style={{ height: 25, width: "auto" }} alt={assets.iconLogout} />}
        />
    )
}

export default LogoutButton