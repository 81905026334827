import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { clearFilterJob, clearMessageList, clearPositionList, clearPositionSelect, clearUserChatSelected } from '../../../../store/chat';
import { clearUserSelected } from '../../../../store/job';
import { Col, Row, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { assets } from '../../../../assets/assets';
import './DashboardSidebarButton.css'

const { Text } = Typography;
const DashboardSidebarButton = () => {

    // Store
    const dispatch: Dispatch<any> = useDispatch();
    const { dictionary } = useSelector((state: any) => state.ui);

    const location = useLocation();
    const navigate = useNavigate();
    //Handlers
    const handleOnNavigate = () => {
        location.pathname === "/messages" && startResetDataMessage();
        location.pathname === "/jobList" && startResetDataJobList();
        navigate("/dash");
    }

    const startResetDataMessage = () => {
        dispatch(clearPositionList());
        dispatch(clearMessageList());
        dispatch(clearUserChatSelected());
        dispatch(clearPositionSelect());
        dispatch(clearFilterJob());
    }


    const startResetDataJobList = () => {
        dispatch(clearUserSelected());
    }
    const isDashboard = location.pathname === "/dash";
    return (
        <Row
            className={`row-dashboard-sidebar-button ${isDashboard ? 'sidebar-selected' : 'sidebar-default'}`}
            onClick={handleOnNavigate}
        >
            <Col className='col-image-dashboard-button' span={6}>
                <img
                    src={assets.iconDashboard}
                    style={{ height: 25, width: 'auto' }}
                    alt={assets.iconDashboard}
                />
            </Col>

            <Col className='col-text-dashboard-button' span={18}>
                <Text className="font-project text-dashboard-button" >
                    {dictionary['sidebar-button-title-dashboard']}
                </Text>
            </Col>
        </Row>
    )
}

export default DashboardSidebarButton