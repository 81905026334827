import React, { useState } from 'react';
import './PreviewCarousel.css';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import SectionOneCarousel from './sectionOneCarousel/SectionOneCarousel';
import { IPosition } from '../../../models/hiring';

interface IPreviewCarouselrops {
    position: IPosition;
}
const PreviewCarousel: React.FC<IPreviewCarouselrops> = ({ position }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const sections = [
        <SectionOneCarousel position={position} />,
        <div className="carousel-section">Sección 2: Requisitos</div>,
        <div className="carousel-section">Sección 3: Habilidades</div>,
    ];

    const goTo = (index: number) => setCurrentIndex(index);
    const next = () => setCurrentIndex((prev) => (prev + 1) % sections.length);
    const prev = () => setCurrentIndex((prev) => (prev - 1 + sections.length) % sections.length);

    return (
        <div className="carousel-container">
            {/* Dots arriba */}
            <div className="carousel-dots top">
                {sections.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${currentIndex === index ? 'active' : ''}`}
                        onClick={() => goTo(index)}
                    />
                ))}
            </div>

            {/* Flechas */}
            <button className="carousel-arrow left" onClick={prev}>
                <LeftOutlined />
            </button>

            <div className="carousel-content">
                {sections[currentIndex]}
            </div>

            <button className="carousel-arrow right" onClick={next}>
                <RightOutlined />
            </button>
        </div>
    );
};

export default PreviewCarousel;
