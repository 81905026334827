import { Col, Row, Typography } from 'antd';
import React from 'react'
import { useSelector } from 'react-redux';
import LocationAutoComplete from './LocationAutoComplete';
import './LocationSection.css'
interface ILocationSectionProps {
    location: string;
    setLocation: (location: string) => void;
    setPlaces: (place: any) => void;
}
const LocationSection: React.FC<ILocationSectionProps> = ({ location, setLocation, setPlaces }) => {
    const { dictionary } = useSelector((state: any) => state.ui);
    return (
        <Row className='row-section-location-job'>
            <Col
                className='col-label-section-location-job'
                xs={24}
                sm={24}
                md={6}
                lg={4}
            >
                <Typography.Text className='title'>
                    {dictionary["creation-modal-page-one-field-location"]}{" "}*
                </Typography.Text>
            </Col>

            <Col xs={24} sm={24} md={17} lg={20} className='col-select'>
                <LocationAutoComplete
                    location={location}
                    setLocation={setLocation}
                    setPlaces={(value: any) => setPlaces(value)}
                />
            </Col>
        </Row>
    )
}

export default LocationSection